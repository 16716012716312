// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function ListItemIcon(theme) {
	return {
		MuiListItemIcon: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
