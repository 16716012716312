//@flow
import Box from "@mui/material/Box";
import { DataGridPremium } from '@mui/x-data-grid-premium'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPagination } from '../../../../components/mui/grids/dataGridCustom/customPagination'
import DataGridWrapper from '../../../../components/mui/grids/dataGridWrapper/dataGridWrapper'
import { muiGridLocales } from '../../../../helpers/muiGridLocales'
import { useMuiGridServerSide } from '../../../../hooks/muiGrid/useMuiGridServerSide'

const PasswordRestorationGrid = ({id}) => {
    const {t} = useTranslation('userProperties')
    const [updateGridSource, setUpdateGridSource] = useState(false)

    const {
        isLoading,
        data,
        total,
        page,
        pageSize,
        onPaginationModelChange,
        onSortModelChange,
        onFilterModelChange,
        refreshGrid
    } = useMuiGridServerSide({
        url: 'user/password/restoration/' + id,
        initialPageSize: 50,
        initialSortModel: [{name: 'id', direction: 'descending'}]
    })

    const columns = useMemo(() => [
        {
            field: 'RestorationDt',
            headerName: t('RestorationDt'),
            maxWidth: 320,
            flex: 1,
        },
    ], [t])

    return (
        <Box className={'issues'}>
            <Box className="index_grid">
                <DataGridWrapper sx={{height: 500}}>
                    <DataGridPremium
                        columns={columns}
                        rows={data}
                        pagination
                        paginationModel={{page, pageSize}}
                        paginationMode="server"
                        onPaginationModelChange={onPaginationModelChange}
                        rowCount={Number(total)}
                        pageSizeOptions={[5, 10, 15, 20, 50, 100]}
                        sortingMode="server"
                        onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
                        filterMode="server"
                        onFilterModelChange={onFilterModelChange}
                        loading={isLoading}
                        localeText={muiGridLocales(window.LANG)}
                        initialState={{
                            density: 'compact',
                        }}
                        slots={{
                            pagination: CustomPagination,
                        }}
                    />
                </DataGridWrapper>
            </Box>
        </Box>
    )
}

export default PasswordRestorationGrid