export const DRAWER_WIDTH = '300px';
export const HORIZONTAL_MAX_ITEM = 6;

export let ThemeMode;

(function (ThemeMode) {
	ThemeMode['LIGHT'] = 'light';
	ThemeMode['DARK'] = 'dark';
})(ThemeMode || (ThemeMode = {}));

export let MenuOrientation;

(function (MenuOrientation) {
	MenuOrientation['VERTICAL'] = 'vertical';
	MenuOrientation['HORIZONTAL'] = 'horizontal';
})(MenuOrientation || (MenuOrientation = {}));

export let ThemeDirection;

(function (ThemeDirection) {
	ThemeDirection['LTR'] = 'ltr';
	ThemeDirection['RTL'] = 'rtl';
})(ThemeDirection || (ThemeDirection = {}));


// ==============================|| THEME CONFIG ||============================== //

const config = {
	fontFamily: `Roboto`,
	mode: 'light',
	miniDrawer: false,
	container: true,
	presetColor: 'default',
	themeDirection: ThemeDirection.LTR
}

export default config;
