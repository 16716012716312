import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.NAVIGATION]: {
		el: {
			'Home': 'Αρχική',
			'Contact': 'Επικοινωνία',
			'My Profile': 'Το Προφίλ μου',
			'Privacy policy': 'Πολιτική Απορρήτου',
			'Logout': 'Αποσύνδεση',
			'Routes' : 'Δρομολόγια',
			'Users' : 'Χρήστες',
			'Contractors' : 'Ανάδοχοι',
			'Rights' : 'Δικαιώματα',
			'Roles' : 'Ρόλοι',
			'Announcements' : 'Ανακοινώσεις',
			'News' : 'Ενημερώσεις',
		},
		en: {
			'order_not_in_progress': 'Order already submitted!',
			'No Order Found': 'Order completed or deleted from an other user',
			'order_not_found': 'Order completed or deleted from an other user',
			'order_item_not_found': 'Product already removed from your cart'
		},
	},
	[Namespaces.FILES]: {
		el: {
			'Description': 'Περιγραφή',
			'Cancel': 'Ακύρωση',
			'Uploading': 'Μεταμόρφωση',
			'No description yet': 'Χωρίς περιγραφή',
			'Download': 'Λήψη',
			'Edit': 'Επεξεργασία',
			'Delete': 'Διαγραφή',
			'Upload File': 'Ανέβασμα Αρχείου',
			'Drag \'n\' drop some files here, or click to select files': 'Σύρετε αρχεία εδώ, ή πατήστε για να επιλέξετε.',
			'Uploaded File(s)': 'Ανεβασμένα Αρχεία'
		},
		en: {},
	},
	[Namespaces.CONFIRMATION]: {
		el: {
			'NO': 'ΟΧΙ',
			'YES': 'ΝΑΙ'
		},
		en: {},
	},
	[Namespaces.TABLE_COMPONENT]: {
		el: {
			Show: "Εμφάνιση",
			Showing: "Εμφάνιση",
			results: 'αποτελέσματα',
			Loading: "Φόρτωση",
			'per page': "ανα σελίδα",
			'Go to page': 'Πήγαινε στην σελίδα',
			'Page': 'Σελίδα',
			'of': 'από',
			'no_results': 'Δεν υπάρχουν αποτελέσματα',
			'All': 'Όλα',
			'search': 'Αναζήτηση σε {{count}} εγγραφές',
			'min': 'Ελάχιστη τιμή {{min}}',
			'max': 'Μέγιστη τιμή {{max}}',
			'to': 'έως',
			'Load More': 'Περισσότερα'
		},
		en: {
			'min': 'Minimum value {{min}}',
			'max': 'Maximum value {{max}}',
			'no_results': 'No Results',
			'search': 'Search {{count}} records',
		}
	},
	[Namespaces.AXIOS_ERROR_MESSAGE]: {
		el: {
			'More': 'Περισσότερα..',
			'Less': 'Λιγότερα',
			'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
			'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
			'Duplicate Entry': 'Υπάρχει ήδη αυτή η εγγραφή',
			'Not Found': 'Δεν βρέθηκε',
			errorMessage: 'Μήνυμα',
			'Message': 'Μήνυμα',
			moreMessage: '',
			'Code': 'Κωδικός',
			'Status': 'Status',
		},
		en: {
			'More': 'More..',
		}
	},
	[Namespaces.COPY_TO_CLIPBOARD]: {
		el: {
			'Copy': 'Αντιγραφή',
			'Copied': 'Αντιγράφηκε',
		},
		en: {}
	},
	[Namespaces.MUI_GRID]: {
		el: {
			'Add': 'Προσθήκη',
			'Actions': 'Ενέργειες',
			'Press Yes to confirm entry deletion.': 'Πατήστε ΝΑΙ για να επιβεβαιώσετε τη διαγραφή της εγγραφής.',
			'Delete Entry Confirmation.': 'Επιβεβαίωση Διαγραφής.',
			'no': 'Όχι',
			'yes': 'Ναι'
		},
		en: {
			'no': 'No',
			'yes': 'Yes'
		},
	},
	[Namespaces.GRID_STATE]: {
		el: {
			'Custom view': 'Προσαρμοσμένη όψη',
			'Cancel': 'Ακύρωση',
			'Create view': 'Νέα Όψη',
			'Save current view': 'Αποθήκευση όψης',
			'Custom view label': 'Ετικέτα όψης',
			'New custom view': 'Νέα προσαρμοσμένη όψη'
		},
		en: {}
	},
	[Namespaces.EDITABLE_MUI_GRID]: {
		el: {
			'Add': 'Προσθήκη',
			'Actions': 'Ενέργειες',
			'Press Yes to confirm entry deletion.': 'Πατήστε ΝΑΙ για να επιβεβαιώσετε τη διαγραφή της εγγραφής.',
			'Delete Entry Confirmation.': 'Επιβεβαίωση Διαγραφής.',
			'Confirm': 'Επιβεβαίωση',
			'Cancel': 'Ακύρωση',
			'Save': 'Αποθήκευση',
			'Edit': 'Επεξεργασία',
			'Delete': 'Διαγραφή'
		},
		en: {
			'no': 'No',
			'yes': 'Yes',
		},
	},
	
}

export default locales
