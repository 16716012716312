// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function InputBase (theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderBottom: theme.palette.inputPalette.borderBottom,
                    '&.MuiInput-root.Mui-disabled': {
                        ':before': {
                            borderBottomStyle: 'solid'
                        }
                    }
                }
            }
        }
    }
}
