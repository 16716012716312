// ==============================|| OVERRIDES - FORM HELPER TEXT ||============================== //

export default function FormHelperText (theme) {
	return {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 0,
					color: theme.palette.error.main,
					fontSize: 11,
					'&.Mui-error': {
						color: theme.palette.error.main
					}
				}
			}
		}
	}
}
