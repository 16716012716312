// material-ui
import { createTheme } from '@mui/material/styles'
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey, common } from '@mui/material/colors';

// project import
import ThemeOption from './theme'

// ==============================|| DEFAULT THEME - PALETTE ||============================== //

export default function Palette (mode, presetColor) {
	const colors = {
		red,
		pink,
		purple,
		deepPurple,
		indigo,
		blue,
		lightBlue,
		cyan,
		teal,
		green,
		lightGreen,
		lime,
		yellow,
		amber,
		orange,
		deepOrange,
		brown,
		grey,
		blueGrey,
		common
	}
	
	const paletteColor = ThemeOption(colors, presetColor, mode)
	
	return createTheme({
		palette: {
			mode,
			...paletteColor,
		}
	})
}
