// @flow
import { Box, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import React from 'react'

type Props = {
	title?: string,
	divider?: Boolean
};

const FormBoxTitle = (props: Props) => {
	const { title, divider = true } = props
	const theme = useTheme()
	const color = theme.palette.formBox.titleColor
	const backgroundColor = theme.palette.formBox.titleBackground
	return (
		<Box className={'formBoxTitle'}>
			<Box display={'flex'}
				 flexDirection={'row'}
				 alignItems={'center'}
				 justifyContent={'space-between'}
				 sx={{ backgroundColor: backgroundColor }}
			>
				{title && (
					<Typography
						variant={'subtitle2'}
						component={'div'}
						color={color}
						p={0.4}
					>
						{title}
					</Typography>
				)}
				{props.children}
			</Box>
			{divider && <Divider sx={{ borderColor: theme.palette.formBox.dividerColor, mb: 1 }}/>}
		</Box>
	)
}

export default FormBoxTitle