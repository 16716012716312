// @flow
import React, {Fragment} from 'react';
import {GridActionsCellItem} from '@mui/x-data-grid-premium'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { EDITABLE_MUI_GRID } from "../../../../../locales/components/namespaces";

type Props = {
    id: string,
    hideEditButton: boolean,
    handleEditRow?: Function,
    handleDeleteRow: Function
};

const GridRowToolBar = (props: Props) => {
    const {handleEditRow, handleDeleteRow, id, hideEditButton} = props
    const {t} = useTranslation(EDITABLE_MUI_GRID)
    return (
        <Fragment>
            {!hideEditButton && (
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label={t("Edit")}
                    onClick={() => handleEditRow(id)}
                    color={'info'}
                />
            )}
            <GridActionsCellItem
                icon={<DeleteIcon/>}
                label={t("Delete")}
                onClick={() => handleDeleteRow(id)}
                color={"error"}
            />
        </Fragment>
    )
};

export default GridRowToolBar