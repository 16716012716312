import { AnnouncementsModule } from "./modules/announcements";
import { FaqsModule } from "./modules/faqs";
import { RightsModule } from "./modules/rights";
import { SecurityRolesModule } from "./modules/usersSecurityRoles";
import history from './store/history'
import { ConnectedRouter } from "connected-react-router";

import { loadModules } from "./store/loadModule";
import { PermissionsModule } from "./modules/permission";
import { ParentsModule } from "./modules/parents";
import { UsersModule } from "./modules/users";
import { ContractorsModule } from "./modules/contractors";

import { CssBaseline } from "@mui/material";
import ThemeCustomization from "./themes";
import SnackbarProviderExtended from "./components/mui/messages/snackbarProviderExtended/snackbarProviderExtended";

import Main from "./pages/main/main";
import './App.css';

export const ENABLED_MODULES = loadModules([PermissionsModule, ParentsModule, UsersModule, RightsModule, SecurityRolesModule, AnnouncementsModule, ContractorsModule, FaqsModule])
export const ROUTES = ENABLED_MODULES.loadRoutes()

function App() {
	return (
		<ThemeCustomization>
			<SnackbarProviderExtended>
					<ConnectedRouter history={history}>
						<CssBaseline/>
						<Main/>
					</ConnectedRouter>
			</SnackbarProviderExtended>
		</ThemeCustomization>
	);
}

export default App;
