
import { routeCreator } from "../../../helpers/routing/routeCreator";
import RightsGrid from "../components/RightsGrid";


export const ROUTE_PAGE_RIGHTS_INDEX = routeCreator(RightsGrid, '/rights', 'Rights', 'page.rights', {exact: true})


const router = {
    ROUTE_PAGE_RIGHTS_INDEX,
    
}

export default router