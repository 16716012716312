//@flow
import React from 'react'
import {Dialog, Box} from "@mui/material";

type Props = {
    show: boolean,
    style?: Object,
    children?: React.Node,
    onBackgroundClick?: Function
}

function Modal (props: Props) {
    const {
        show,
        autoClose = false,
        children,
        onBackgroundClick,
        sx = {},
        maxWidth = false, // xs, sm, md, lg, xl, false, string
        otherDialogProps = {} // More dialog props https://mui.com/material-ui/api/dialog/
    } = props

    const onClose = (evt, reason) => {
        if (!autoClose && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return
        onBackgroundClick(evt, reason)
    }

    return (
        <Dialog
            open={show}
            onClose={onClose}
            sx={sx}
            maxWidth={maxWidth}
            scroll={'body'}
            PaperProps={{
                sx: {
                    overflowY: 'initial',
                    minWidth: '340px'
                }
            }}
            {...otherDialogProps}
        >
            <Box p={1.5}>
                {children}
            </Box>
        </Dialog>
    )
}

export default Modal
