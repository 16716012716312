// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //

export default function ToggleButton(theme) {
	return {
		MuiToggleButton: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
