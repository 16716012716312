// @flow
import { Alert, Typography } from "@mui/material";
import React, { useContext } from 'react';
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { view_admin_dashboard, view_contractor_dashboard, view_parent_dashboard } from "../../helpers/rights";
import useUserData from "../../hooks/user/useUserData";
import { ANNOUNCEMENTS } from "../../modules/announcements/locales/namespaces";
import Index from "../../modules/contractors/pages/index";
import ParentDashboard from "../../modules/parents/pages/parentDashboard/parentDashboard";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import { TitleContext } from "../main/content";

type Props = {};

const Home = (props: Props) => {
	const { username } = useUserData();
	const {count} = useContext(TitleContext)
	const {t} = useTranslation(ANNOUNCEMENTS)
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap'
			}}
			width={'100%'}
		>
			<PermissionHOC requiredPermissions={[view_parent_dashboard]}>
				{count > 0 &&
					<Alert variant="outlined" severity="info" sx={{ width: '100%',justifyContent: 'center' }}>
						{t('You have')} {count} {t('announcements!')}
					</Alert>
				}
				<ParentDashboard/>
			</PermissionHOC>
			<PermissionHOC requiredPermissions={[view_contractor_dashboard]}>
				{count.length > 0 &&
					<Alert variant="outlined" severity="info" sx={{ width: '100%',justifyContent: 'center' }}>
						{t('You have')} {count} {t('announcements!')}
					</Alert>
				}
				<Index/>
			</PermissionHOC>
			<PermissionHOC requiredPermissions={[view_admin_dashboard]}>
				{/*<>Admin Dashboard</>*/}
				<Box sx={{ textAlign: 'center', backgroundColor: 'lightblue', width: '100%' }}>
					<Typography sx={{ fontSize: '20px' }}>{username}</Typography>
				</Box>
			</PermissionHOC>
		</Box>
	);
};

export default Home