import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import MainCard from "../../components/mui/general/mainCard/mainCard";
import {useTranslation} from "react-i18next";
import {PAGE_CONTACT} from "../../locales/pages/namespaces";
import './contact.css'

const Contact = () => {
    const {t} = useTranslation(PAGE_CONTACT)
    return (
        <Box p={1}>
            <Typography
                pb={1}
                variant={'h2'}
                component={'div'}
                sx={{
                    textAlign: 'center'
                }}
            >
                {t('Contact')}
            </Typography>
            <Box sx={{display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                <Box width={'100%'} m={1}>
                    <MainCard sx={{
                        mb: 2,
                        borderColor: '#075c90',
                        textAlign: 'center',
                        height: '100%'
                    }}>
                        <Typography fontWeight={"bold"} variant={'h5'}>{t('An address')}</Typography>
                    </MainCard>
                </Box>
            </Box>
        </Box>
    )
}

export default Contact;