// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
	return {
		MuiButton: {
			defaultProps: {
				// disableElevation: true
			},
			styleOverrides: {
				// root: {},
				// contained: {},
				// outlined: {
				// 	'&.Mui-disabled': {}
				// },
				// text: {
				// 	boxShadow: 'none',
				// 	'&:hover': {},
				// 	'&.Mui-disabled': {}
				// },
				// // custom variant 'dashed'
				// dashed: {},
				// // custom variant 'shadow'
				// shadow: {},
			}
		}
	};
}
