import MuiPagination from '@mui/material/Pagination';
import {GridPagination} from '@mui/x-data-grid-premium'

function Pagination({page, onPageChange, className, ...other}) {
	return (
		<MuiPagination
			size={'small'}
			color="secondary"
			className={className}
			count={Math.ceil(other.count / other.rowsPerPage)}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
			showFirstButton
			showLastButton
			boundaryCount={2}
		/>
	);
}

export const CustomPagination = (props) => {
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}