import ClearIcon from '@mui/icons-material/Clear'
import { Box, Button, IconButton } from '@mui/material'
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './QuickDetails.css'
import { Link } from 'react-router-dom'
import PageMenu from '../../../../components/custom/app/pageMenu/pageMenu'
import BoolImage from '../../../../components/custom/general/boolImage/boolImage'
import PageContentWrapper from '../../../../components/mui/app/pageContentWrapper/pageContentWrapper'
import QuickViewInfoWrap from '../../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap'
import SkeletonLoaderWrapper from "../../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { API } from "../../../../helpers/constants";
import { handleAxiosErrors } from "../../../../helpers/errorHandling";


const QuickDetailsUser = (props) => {
	const { userId, onClose } = props
	const { t } = useTranslation('userProperties')
	const [user, setUser] = useState(null)
	
	useEffect(() => {
		if (userId) {
			axios.get(`${API}/en/user/quickview/` + userId).then((res) => {
				const data = res.data
				if (data && data.user.length > 0) {
					setUser(data.user[0])
				}
			}).catch(error => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
			})
		}
	}, [userId, t])
	
	return (
		<SkeletonLoaderWrapper loading={!Boolean(user)}>
			<Box
				sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
			>
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
					<PageMenu>
						<Button
							component={Link}
							to={`/user/details/${userId}`}
						>
							{t('actionButtons:Details')}
						</Button>
						<Button
							component={Link}
							to={`/user/edit/${userId}`}
						>
							{t('actionButtons:Edit')}
						</Button>
					</PageMenu>
					<IconButton
						onClick={onClose}
						color={'error'}
						size={'small'}
					>
						<ClearIcon/>
					</IconButton>
				</Box>
				<PageContentWrapper>
					<Box>
						<QuickViewInfoWrap label={t('Username')}>{user?.username}</QuickViewInfoWrap>
						{/*<QuickViewInfoWrap label={t('Origin')}> {user?.OriginDescription} </QuickViewInfoWrap>*/}
						<QuickViewInfoWrap label={t('E-mail')}> {user?.email} </QuickViewInfoWrap>
						<QuickViewInfoWrap label={t('Active')}>
							<BoolImage
								isChecked={user?.isActive === '1'}
								alt={t('Active')}
							/>
						</QuickViewInfoWrap>
						{/*<QuickViewInfoWrap label={t('Internal')}>*/}
						{/*	<BoolImage*/}
						{/*		isChecked={user?.isInternal === '1'}*/}
						{/*		alt={t('Internal')}*/}
						{/*	/>*/}
						{/*</QuickViewInfoWrap>*/}
						{/*<QuickViewInfoWrap label={t('Actor')}>*/}
						{/*	<BoolImage*/}
						{/*		isChecked={user?.isActor === '1'}*/}
						{/*		alt={t('Actor')}*/}
						{/*	/>*/}
						{/*</QuickViewInfoWrap>*/}
						{/*<QuickViewInfoWrap*/}
						{/*	label={t('Internal Cost')}> {user?.InternalCost ? user.InternalCost : '-'} </QuickViewInfoWrap>*/}
						{/*<QuickViewInfoWrap*/}
						{/*	label={t('Rate')}> {user?.CostRate ? user.CostRate : '-'} </QuickViewInfoWrap>*/}
					</Box>
				</PageContentWrapper>
			</Box>
		</SkeletonLoaderWrapper>
	)
}

export default QuickDetailsUser