import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/custom/general/spinner/Spinner";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import SkeletonLoaderWrapper from "../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import DataGridWrapper from "../../../components/mui/grids/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../components/mui/grids/editableMUIDataGrid/editableMUIDataGrid";
import { access_roles } from "../../../helpers/rights";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import useUsersSecurityRoles from "../hooks/useUsersSecurityRoles";
import { Link } from 'react-router-dom';
import { SECURITYROLES } from "../locales/namespaces";
import { ROUTE_PAGE_SECURITY_ROLES_DETAILS, ROUTE_PAGE_SECURITY_ROLES_INDEX } from "../routes/routes";

const UsersSecurityRoles = (props) => {
	const { t } = useTranslation(SECURITYROLES);
	const {
		data,
		isLoading,
		refreshGrid,
		store,
		remove,
		update,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
	} = useUsersSecurityRoles()
	
	const newRowModel = {
		Description: '',
		isActive: false,
	}
	
	const columns = useMemo(() => [
		
		{
			field: 'Description',
			headerName: t('Description'),
			width: 150, maxWidth: 300, flex: 1,
			editable: true,
		},
		{
			field: 'IsActive',
			headerName: t('Active'),
			width: 90, maxWidth: 180, flex: 1,
			type: 'boolean',
			editable: true,
			valueGetter: (value, row, column, apiRef) => value === '1'
		},
		{
			// headerName: t('Connect Rights'),
			headerName: '',
			field: 'id',
			minWidth: 90, flex: 1,
			renderCell: ({ row }) => (
				<Button
					variant={'outlined'}
					color={'secondary'}
					component={Link}
					// to={`/parameters/users/securityroles/details/${row.id}`}
					to={getRouteUrl(ROUTE_PAGE_SECURITY_ROLES_DETAILS, {
						id: row.id
					})}
					sx={{padding:'0px'}}
				>
					<Box pl={0.2} pr={0.2}>
						{t('Add Role Rights')}
					</Box>
				</Button>
			)
		}
	
	], [t])
	
	return (
		<Box className="index_grid">
			<PageContentWrapper> {/* Wrapper component που δημιουργεί συγκεκριμένο style γύρω από τα grid και τον τίτλο στην index. */}
				<PageTitle
					title={t('Security Roles')}/> {/* Custom mui component για τον τίτλο σελίδων. Χρησιμοποιεί styles από συγκεκριμένα properties της παλέτας. Με το Property withDivider, μπορεί να εμφανίσει και divider κάτω από τον τίτλο, το οποίο επίσης παίρνει styles από την παλέτα. */}
				<DataGridWrapper sx={{ height: 600, maxWidth: '700px' }}>
					<EditableMUIDataGrid
						data={data}
						columns={columns}
						loading={isLoading}
						newRowModel={newRowModel}
						store={store}
						remove={remove}
						update={update}
						// hideEditButton={true}
						dataGridProOtherProps={{
							pagination: true,
							paginationModel: { page, pageSize },
							paginationMode: "server",
							onPaginationModelChange: onPaginationModelChange,
							rowCount: Number(total),
							pageSizeOptions: [5, 10, 15, 20, 50, 100],
							sortingMode: "server",
							onSortModelChange: (sortModel) => onSortModelChange(sortModel),
							filterMode: "server",
							onFilterModelChange: onFilterModelChange,
						}}
					/>
				</DataGridWrapper>
			</PageContentWrapper>
		</Box>
	)
};

// export default UsersSecurityRoles

export default withPermissionHOC(UsersSecurityRoles, [access_roles])
