import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { List, ListItemText, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import ContentLoader from "../../../components/mui/general/contentLoader/contentLoader";
import ModalHeader from "../../../components/mui/general/modal/partials/modalHeader";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { RIGHTS } from "../../rights/locales/namespaces";





const RolesAndUsersOfRight = (props) => {
	const {rightId,rightDescription,onClose} = props;
	const {t} = useTranslation(RIGHTS)
	const [tabValue, setTabValue] = useState('1');
	const [loading,setLoading] = useState(true);
	const [roles,setRoles] = useState([]);
	const [users,setUsers] = useState([]);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		axios.post(`${API}/en/parameters/security/rolesandusers/` + rightId).then(response => {
			setRoles(response.data.roles);
			setUsers(response.data.users);
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update right!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [rightId, t]);
	return !loading ? (
		<Box>
			<PageContentWrapper>
				<ModalHeader
					title={t('Roles And Users of ') + rightDescription}
					onClose={onClose}
				/>
				<DialogContent>
					<Grid
						xs={12}
						sm={12}
						md={12}
					>
						<TabContext value={tabValue}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleChange} aria-label="tabs">
									<Tab label={t('Roles')} value="1"/>
									<Tab label={t('Users')} value="2"/>
								</TabList>
							</Box>
							<TabPanel value="1">
								<List>
									{roles?.map((role) => (
										<Fragment key={role.id}>
										<ListItemText
											primary={
												<Typography variant="subtitle2" component="div">
													{role.Description}
												</Typography>
											}
										/>
										</Fragment>
									))}
									{roles.length == 0 && <Typography>{t('There are not roles')}</Typography>}
								</List>
							</TabPanel>
							<TabPanel value="2">
								<List>
									{users?.map((user) => (
										<Fragment key={user.id}>
											<ListItemText
												primary={
													<Typography variant="subtitle2" component="div">
														{user.LoginName}
													</Typography>
												}
											/>
										</Fragment>
									))}
									{users.length === 0 && <Typography>{t('There are not users')}</Typography>}
								</List>
							</TabPanel>
						</TabContext>
					</Grid>
				</DialogContent>
			</PageContentWrapper>
		</Box>
	):
		<ContentLoader loading={loading}/>
	
};

export default RolesAndUsersOfRight