import { routeCreator } from "../helpers/routing/routeCreator";
import Login from "../pages/login/login";
import Home from "../pages/home/home";
import Profile from "../pages/profile/profile";
import Privacy from "../pages/privacy/privacy";
import Contact from "../pages/contact/contact";

export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/', 'login', 'Login', 'page.login')
export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'home', 'page.home', { exact: true })
export const ROUTE_PAGE_CONTACT = routeCreator(Contact, '/contact', 'Contact', 'page.contact', { exact: true })
export const ROUTE_PAGE_PRIVACY = routeCreator(Privacy, '/privacy-policy', 'Privacy', 'page.privacy.policy', { exact: true })
export const ROUTE_PAGE_PROFILE = routeCreator(Profile, '/my-profile', 'Profile', 'page.profile', { exact: true })



const router = {
	ROUTE_PAGE_HOME,
	ROUTE_PAGE_CONTACT,
	ROUTE_PAGE_PRIVACY,
	ROUTE_PAGE_PROFILE
}

export default router;