// ==============================|| OVERRIDES - DRAWER ||============================== //

export default function Drawer(theme) {
    return {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: theme.palette.drawerBackgroundColor
                },
            }
        }
    };
}
