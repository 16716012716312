// @flow
import { Close } from '@mui/icons-material'
import { Box, ButtonGroup, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import Grid from "@mui/material/Grid2";
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import React from 'react'

type Props = {

};

const ModalHeader = (props: Props) => {
    const {
        title,
        onClose,
        withDivider = true
    } = props
    const theme = useTheme()
    const color = theme.palette.pageTitle.textColor
    return (
        <Box>
            <Grid container spacing={1} alignItems={'end'}>
                <Grid size={{xs: 11}}>
                    <Typography
                        variant={'h5'}
                        component={'div'}
                        color={color}
                        mt={1}
                        p={0.5}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid size={{xs: 1}}>
                    <ButtonGroup
                        variant="outlined"
                        size={'small'}
                        aria-label="Basic button group"
                    >
                        <IconButton
                            color={'error'}
                            onClick={onClose}
                        >
                            <Close/>
                        </IconButton>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {withDivider && <Divider/>}
        </Box>
    )
}

export default ModalHeader