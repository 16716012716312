//@flow
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { normalizeGreek } from "../../../../../helpers/calculations/normalizeGreek";
import { EDITABLE_MUI_GRID } from "../../../../../locales/components/namespaces";

type Props = {
    deleteDialogOpen: Object<{
        open: boolean,
        id: string
    }>,
    handleDeleteDialogOpen: Function,
    handleDeleteConfirmation: Function
}

const RowConfirmationDialog = (props: Props) => {
    const {
        deleteDialogOpen,
        handleDeleteConfirmation,
        handleDeleteDialogOpen
    } = props
    const {t} = useTranslation(EDITABLE_MUI_GRID)

    return (
        <Dialog
            maxWidth={"md"}
            open={deleteDialogOpen.open}
            keepMounted
            onClose={() => {
                handleDeleteDialogOpen({
                    open: false,
                    id: null
                })
            }}
        >
            <DialogTitle>{t('Delete Entry Confirmation.')}</DialogTitle>
            <DialogContent dividers>
                {t(`Press Yes to confirm entry deletion.`)}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDeleteDialogOpen({
                            open: false,
                            id: null
                        })
                    }}
                    sx={{
                        marginRight: 'auto'
                    }}
                >
                    {normalizeGreek(t('Cancel'))}
                </Button>
                <Button
                    onClick={() => {
                        const rowId = deleteDialogOpen.id
                        handleDeleteDialogOpen({
                            open: false,
                            id: null
                        })
                        handleDeleteConfirmation(rowId)
                    }}
                    variant="contained"
                    color={'error'}
                >
                    {normalizeGreek(t('Confirm'))}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RowConfirmationDialog