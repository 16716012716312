import LOGO from './logo/logo.svg'
import PROMO from './login/promo.svg'
import IMAGE_NOT_FOUND from './imageNotFound.jpg'
import ENGLISH_FLAG from './flags/gb.png'
import GREEK_FLAG from './flags/gr.png'

const Images = {
	logo: LOGO,
	promo: PROMO,
	imageNotFound: IMAGE_NOT_FOUND,
	englishFlag: ENGLISH_FLAG,
	greekFlag: GREEK_FLAG
}

export default Images