import { Equalizer } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import {
	DataGridPremium,
	GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
	GridActionsCellItem,
	GridToolbar
} from '@mui/x-data-grid-premium'
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './Index.css'
import PageMenu from "../../../../../components/custom/app/pageMenu/pageMenu";
import PageContentWrapper from "../../../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../../../components/mui/app/pageTitle/pageTitle";
import QuickView from '../../../../../components/mui/general/quickView/quickView'
import { CustomPagination } from '../../../../../components/mui/grids/dataGridCustom/customPagination'
import DataGridWrapper from '../../../../../components/mui/grids/dataGridWrapper/dataGridWrapper'
import { access_user } from "../../../../../helpers/rights";
import { getRouteUrl } from '../../../../../helpers/routing/getRouteUrl'
import { muiGridLocales } from '../../../../../helpers/muiGridLocales'
import { useMuiGridServerSide } from '../../../../../hooks/muiGrid/useMuiGridServerSide'
import { withPermissionHOC } from "../../../../permission/components/permissionHOC/permissionHOC";
import { useHasPermission } from "../../../../permission/hooks/useHasPermission";
import { USERS } from "../../../locales/namespaces";
import { ROUTE_PAGE_USERS_DETAILS, ROUTE_PAGE_USERS_EDIT } from '../../../routes/routes'
import QuickDetailsUser from '../../QuickDetails/QuickDetailsUser.js'


function Index() {
	const { t } = useTranslation(USERS);
	const [userQuickView, setUserQuickView] = useState({
		open: false,
		userId: null
	})
	const editUser = useHasPermission([], { shouldView: true })
	useEffect(() => {
		document.title = t('Users List')
	}, [])
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange
	} = useMuiGridServerSide({
		url: 'user/indexTable/data',
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	const closeQuickViewUser = () => {
		setUserQuickView({
			open: false,
			userId: null
		})
	}
	
	const columns = useMemo(() => {
		return [
			// ...(editUser ? [
			// 	{
			// 		headerName: t('Actions'),
			// 		field: 'actions',
			// 		type: 'actions',
			// 		getActions: (params) => {
			// 			if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
			// 				return []
			// 			}
			// 			return [
			// 				<GridActionsCellItem
			// 					component={Link}
			// 					to={getRouteUrl(ROUTE_PAGE_USERS_EDIT, { id: params.id })}
			// 					icon={
			// 						<Tooltip title={t('Edit')}>
			// 							<IconButton>
			// 								<EditIcon/>
			// 							</IconButton>
			// 						</Tooltip>
			// 					}
			// 					color={'info'}
			// 					label={t('Edit')}>
			// 				</GridActionsCellItem>
			// 			]
			// 		}
			// 	}
			// ] : []),
			{
				headerName: t('Actions'),
				field: 'actions',
				type: 'actions',
				getActions: (params) => {
					if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
						return []
					}
					return [
						<GridActionsCellItem
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_USERS_EDIT, { id: params.id })}
							icon={
								<Tooltip title={t('Edit')}>
									<IconButton>
										<EditIcon/>
									</IconButton>
								</Tooltip>
							}
							color={'info'}
							label={t('Edit')}>
						</GridActionsCellItem>
					]
				}
			},
			{
				field: 'LoginName',
				headerName: t('Name'),
				minWidth: 400,
				flex: 1,
				renderCell: ({ row }) => (
					<Link to={getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {
						id: row.id
					})}>
						{row.LoginName}
					</Link>
				)
			},
			{
				field: 'username',
				headerName: t('Username'),
				minWidth: 180,
				flex: 1,
				renderCell: (params) => {
					if (params.rowNode.type === 'group') {
						return params.value
					}
					return (
						<Button
							variant="link"
							onClick={() => setUserQuickView({
								open: true,
								userId: params.row.id
							})}
						>
							{params.row.username}
						</Button>
					)
				},
			},
			{
				field: 'email',
				headerName: t('Email'),
				minWidth: 180, flex: 1,
			},
			{
				field: 'Roles',
				headerName: t('Role'),
				minWidth: 180, flex: 1,
			},
			{
				field: 'last_login',
				headerName: t('Last Login'),
				minWidth: 180, flex: 1,
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format('DD/MM/YYYY') : null)
				}
			},
			{
				field: 'isActive',
				headerName: t('Active'),
				minWidth: 50, flex: 1,
				type: 'boolean',
				valueGetter: (value, row, column, apiRef) => value === '1'
			}
		]
	}, [t])
	
	return (
		<Box className={'users_index'} sx={{marginTop:'10px'}}> {/* Χρήση Custom Wrapper Class που χαρακτηρίζει τη φόρμα. */}
			{/*TODO::Προσθήκη code='κωδικός μενού' όταν φτιαχτεί δυναμικό μενού χρηστών*/}
			{/*<SidePanelLinks/>*/}
			<PageMenu> {/* Top μενού σελίδας. Χρησιμοποιεί Button Group με variant 'contained' και size small. Τα buttons που περιέχει κληρονομούν αυτομάτως το αντίστοιχο variant και size. Δε δηλώνουμε άλλο variant, size ή χρώμα στα buttons εκτός αν θέλουμε για κάποιο συγκεκριμένο να το ξεχωρίσουμε. (πχ button διαγραφής) */}
				<Button
					component={Link}
					to={'/user/create'}
					startIcon={<AddCircleOutlineIcon/>}
				>
					{t('Add')}
				</Button>
				{/*<Button*/}
				{/*	component={Link}*/}
				{/*	to={`/users/statistics/${new Date().getFullYear()}`}*/}
				{/*	startIcon={<Equalizer/>}*/}
				{/*>*/}
				{/*	{t('actionButtons:Statistics')}*/}
				{/*</Button>*/}
			</PageMenu>
			<PageContentWrapper>
				<PageTitle
					title={t('Users')}/> {/* Custom mui component για τον τίτλο σελίδων. Χρησιμοποιεί styles από συγκεκριμένα properties της παλέτας. Με το Property withDivider, μπορεί να εμφανίσει και divider κάτω από τον τίτλο, το οποίο επίσης παίρνει styles από την παλέτα. */}
				<DataGridWrapper sx={{ height: 700 }}>
					<DataGridPremium
						columns={columns}
						density={'compact'}
						rows={data}
						pagination
						paginationModel={{ page, pageSize }}
						paginationMode="server"
						onPaginationModelChange={onPaginationModelChange}
						rowCount={Number(total)}
						pageSizeOptions={[5, 10, 15, 20, 50, 100]}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode="server"
						onFilterModelChange={onFilterModelChange}
						loading={isLoading}
						localeText={muiGridLocales('el')}
						slots={{
							pagination: CustomPagination,
							toolbar: GridToolbar,
						}}
					/>
				</DataGridWrapper>
			</PageContentWrapper>
			<QuickView
				autoClose={true}
				show={userQuickView.open}
				onBackgroundClick={closeQuickViewUser}
			>
				<QuickDetailsUser
					userId={userQuickView.userId}
					onClose={closeQuickViewUser}
				/>
			</QuickView>
		</Box>
	)
}


export default withPermissionHOC(Index, [access_user])
// export default Index