import React from 'react'
import Box from '@mui/material/Box'

const DataGridWrapper = ({ sx, width, height, autoHeight, children }) => {
	return (
		<Box
			sx={{
				height: height ?? autoHeight ?? 400,
				width: width ?? '100%',
				minWidth: 200,
				...sx
			}}
		>
			{children}
		</Box>
	)
}

export default DataGridWrapper