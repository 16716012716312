import { routeCreator } from "../../../helpers/routing/routeCreator";
import Faqs from "../pages/faqs";


export const ROUTE_FAQS_INDEX = routeCreator(Faqs, '/faqs', 'Faqs', 'page.parents', {exact: true})

const router = {
    ROUTE_FAQS_INDEX
}

export default router