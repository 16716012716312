// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

export default function CardContent(theme) {
	return {
		MuiCardContent: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
