const INITIAL_STATE = {
	loading: false,
}

const parents = {
	initialState: INITIAL_STATE,
	handlers: {}
}

export default parents
