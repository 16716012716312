export default function Select (theme) {
	return {
		MuiSelect: {
			styleOverrides: {
				root: {
					margin: 0,
				},
				select: {
					minHeight: '1.3rem'
				}
			}
		}
	}
}
