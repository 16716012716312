import axios from 'axios'
import { useFormik } from 'formik'
import moment from 'moment'
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import { RIGHTS } from "../locales/namespaces";


const useRightForm = ({ rightId, rightData, onClose, moduleId }) => {
	const { t } = useTranslation(RIGHTS)
	const [modules,setModules] = useState()
	const [editions,setEditions] = useState()
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const {token} = useUserData();
	
	useEffect(() => {
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/parameters/rights/create/getFilteredModules`
		}).then(response => {
			setModules(response.data)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
		
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/parameters/rights/create/getFilteredEditions`
		}).then(response => {
			setEditions(response.data)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})

	}, [t]);
	
	const onSubmit = (values) => {
		console.log('submit',values)
		setLoadingSubmit(true)
		axios({
			method: 'post',
			data: {
				Description: values.Description,
				ModId: values.ModId,
				EdtnId: values.EdtnId,
				Attribute: values.Attribute,
				isAction: values.isAction
			},
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: !rightId ? `${API}/en/parameters/rights/create/store` : `${API}/en/parameters/rights/create/update/${rightId}`
		}).then(response => {
			enqueueSnackbar(t(`Right ${!rightId ? 'created' : 'updated'} successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			onClose()
			setLoadingSubmit(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
			setLoadingSubmit(false)
		})
	}
	
	const validationSchema = yup.object().shape({
		Description: yup.string().required(t('Required')),
		ModId: yup.string().required(t('Required')),
		EdtnId: yup.string().required(t('Required')),
	})
	
	const formik = useFormik({
		initialValues: {
			Description: rightId ? rightData?.Description : '',
			ModId: rightId ? rightData?.ModId : moduleId,
			EdtnId: rightId ? rightData?.EdtnId : '',
			Attribute: rightId ? rightData?.Attribute :  window.crypto.randomUUID(),
			isAction: rightData?.isAction === "1"
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	return {
		formik,
		loadingSubmit,
		modules,
		editions
	}
}

export default useRightForm
