// @flow
import { Box } from '@mui/material'
import React from 'react'
import './pageContentWrapper.css'

type Props = {};

const PageContentWrapper = (props: Props) => {
    return (
        <Box className={'pageContentWrapper'}>
            {props.children}
        </Box>
    )
}

export default PageContentWrapper