import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.USERS]: {
		el: {
			'Actions':'Ενέργειες',
			'Add' : 'Προσθήκη',
			'Name' : 'Επωνυμία',
			'Username' : 'Όνομα χρήστη',
			'Email' : 'Email',
			'Origin' : 'Προέλευση',
			'Active' : 'Ενεργός',
			'Users' : 'Χρήστες',
			'Basics' : 'Βασικά',
			'Password' : 'Κωδικός',
			'Confirm Password' : 'Επαλήθευση Κωδικού',
			'Localization' : 'Προσαρμογή',
			'Language' : 'Γλώσσα',
			'User Create' : 'Δημιουργία Χρήστη',
			'User Edit' : 'Επεξεργασία Χρήστη',
			'User Details' : 'Λεπτομέρειες Χρήστη',
			'Return' : 'Επιστροφή',
			'Save' : 'Αποθήκευση',
			'Delete' : 'Διαγραφή',
			'Edit' : 'Επεξεργασία',
			'Details' : 'Λεπτομέρειες',
			'Tabs' : 'Καρτέλες',
			'Roles' : 'Ρόλοι',
			'Rights' : 'Δικαιώματα',
			'PasswordRestoration' : 'Ανάκτηση Κωδικού',
			'Users List' : 'Λίστα Χρηστών',
			'Right' : 'Δικαίωμα',
			'View Access' : 'Πρόσβαση Προβολής',
			'Edit Access' : 'Πρόσβαση Επεξεργασίας',
			'User created successfully!' : 'Ο χρήστης δημιουργήθηκε με επιτυχία!',
			'User updated successfully!' : 'Ο χρήστης ενημερώθηκε με επιτυχία!',
			'An error occurred!' : 'Παρουσιάστηκε σφάλμα!',
			'Please wait...' : 'Παρακαλώ περιμένετε...',
			'msg.login_notfound' : 'Λανθασμένα στοιχεία πρόσβασης',
			'Cancel' : 'Ακύρωση',
			'Confirm' : 'Επιβεβαίωση',
			'Last Login' : 'Τελευταία Σύνδεση',
			'Role' : 'Ρόλος',
			
		},
		en: {
			'msg.login_notfound' : 'Incorrect Login Credentials',
		}
	},
}

export default locales