import { Delete, NavigateBefore } from "@mui/icons-material";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import React from 'react';
import { useTranslation } from "react-i18next";

const Upload = (props) => {
    // Global app variables

    const {t} = useTranslation();

    const {urlParameter, handleImageFormState, handleUploadModal} = props
    const uploadPath = {saveUrl: `en/${urlParameter}/upload`};

    const onUploadSuccess = args => {
        handleImageFormState(JSON.parse(args.e.srcElement.response).filename)
        handleUploadModal(false);
    }

    const onDelete = () => {
        handleImageFormState('')
        handleUploadModal(false)
    }

    return (
        <>
            <UploaderComponent
                id="uploader"
                autoUpload={true}
                multiple={false}
                //maxFileSize={1048576} //1 MB
                asyncSettings={uploadPath}
                allowedExtensions='.jpg, .png, .gif'
                success={onUploadSuccess}
                locale={'el'}
            />
            <div className="actionbtns">
                <div className="actionbtn">
                    <button className="buttonIcon" onClick={() => handleUploadModal(false)}>
                        {/*<img src={RETURN_ICON} title="Return" alt="return icon"/>*/}
                        <NavigateBefore color={"primary"} alt="Return"/>
                        <span className="iconButtonText">{t('actionButtons:Return')}</span>
                    </button>
                </div>
                {urlParameter ?
                    <div className="actionbtn">
                        <button className="buttonIcon" onClick={onDelete}>
                            {/*<img style={{maxWidth: '24px', maxHeight: '24px'}} src={DELETE_ICON} title="Delete"*/}
                            {/*      alt="delete icon"/>*/}
                            <Delete color={"red"} alt="Delete"/>
                            <span className="iconButtonText">{t('actionButtons:Delete Image')}</span>
                        </button>
                    </div> : null}
            </div>
        </>
    );
};

export default Upload;