// ==============================|| OVERRIDES - TABS ||============================== //

export default function Tabs (theme) {
	return {
		MuiTabs: {
			styleOverrides: {
				vertical: {
					overflow: 'visible',
					'& .Mui-selected': {
						color: theme.palette.secondary.light,
					},
				},
				indicator: {
					backgroundColor: theme.palette.secondary.light,
					height: '1px'
				},
				root: {
					borderBottom: '1px solid',
					borderColor: theme.palette.secondary[200],
					'& .Mui-selected': {
						color: theme.palette.secondary.main,
					},
					
				}
			}
		}
	}
}
