// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function Input (theme) {
	return {
		MuiInput: {
			defaultProps: {
				disableUnderline: theme.palette.inputPalette.disableUnderline
			},
			styleOverrides: {
				input: {
					fontSize: '0.85rem',
					color: theme.palette.inputPalette.inputColor,
					backgroundColor: theme.palette.inputPalette.inputBackground,
					height: '1.3rem',
					padding: 0,
					'&.Mui-disabled': {
						WebkitTextFillColor: theme.palette.primary.dark,
						backgroundColor: theme.palette.primary.lighter
					}
				}
			}
		}
	}
}
