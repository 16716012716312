// @flow 
import { Divider, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, { Fragment } from 'react';

type Props = {
	data: Array<{
		id: string,
		Description: string
	}>,
	selected: string, // state to keep selection id,
	onListItemClick: Function,
};

const ListWithSelectionHighlight = (props: Props) => {
	const {highLight, data, selected, onListItemClick, sx = {}} = props
	return (
		<List
			sx={{
				miHeight: {
					xs: '200px',
					sm: '200px',
					md: '100%'
				},
				overflow: 'auto',
				paddingTop: 0.4,
				...sx
			}}
		>
			{data.map(i => (
				<Fragment key={i.id}>
					<ListItem
						component={"div"}
						disableGutters
						sx={{padding: 0}}
					>
						<ListItemButton
							sx={{padding: 0}}
							onClick={() => onListItemClick(i.id)}
							selected={i.id === selected}
						>
							<ListItemText
								primary={
									<Typography variant="subtitle2" component="div">
										{i.Description}
									</Typography>
								}
							/>
						</ListItemButton>
					</ListItem>
					{highLight && <Divider/>}
				</Fragment>
			))}
		</List>
	);
};

export default ListWithSelectionHighlight