// third-party
import { merge } from 'lodash'

// project import
import Accordion from './accordion'
import AccordionDetails from './accordionDetails'
import AccordionSummary from './accordionSummary'
import Alert from './alert'
import AlertTitle from './alertTitle'
import Autocomplete from './autocomplete'
import Badge from './badge'
import Button from './button'
import ButtonBase from './buttonBase'
import ButtonGroup from './buttonGroup'
import CardContent from './cardContent'
import Checkbox from './checkbox'
import Chip from './chip'
import DataGrid from './dataGrid'
import DateCalendar from './dateCalendar'
import Dialog from './dialog'
import DialogContentText from './dialogContentText'
import DialogTitle from './dialogTitle'
import Drawer from './drawer'
import Fab from './fab'
import FormControlLabel from './formControlLabel'
import FormHelperText from './formHelperText'
import FormLabel from './formLabel'
import IconButton from './iconButton'
import Input from './input'
import InputBase from './inputBase'
import InputLabel from './inputLabel'
import LinearProgress from './linearProgress'
import Link from './link'
import ListItemButton from './listItemButton'
import ListItemIcon from './listItemIcon'
import LoadingButton from './loadingButton'
import MenuItem from './menuItem'
import NativeSelect from './nativeSelect'
import OutlinedInput from './outlinedInput'
import Pagination from './pagination'
import PaginationItem from './paginationItem'
import Paper from './paper'
import PickersCalendarHeader from './pickersCalendarHeader'
import PickersMonth from './pickersYear'
import PickersYear from './pickersYear'
import Popover from './popover'
import Radio from './radio'
import Select from './select'
import Slider from './slider'
import SvgIcon from './svgIcon'
import Switch from './switch'
import Tab from './tab'
import Table from './table'
import TableBody from './tableBody'
import TableCell from './tableCell'
import TableContainer from './tableContainer'
import TableFooter from './tableFooter'
import TableHead from './tableHead'
import TablePagination from './tablePagination'
import TableRow from './tableRow'
import TabPanel from "./tabPanel";
import Tabs from './tabs'
import TextField from './textField'
import ToggleButton from './toggleButton'
import Toolbar from './toolbar'
import Tooltip from './tooltip'
import TreeItem from './treeItem'
import Typography from './typography'
import List from "./list";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides (theme) {
    return merge(
        Accordion(theme),
        AccordionDetails(theme),
        AccordionSummary(theme),
        Alert(theme),
        AlertTitle(theme),
        Autocomplete(theme),
        Badge(theme),
        Button(theme),
        ButtonBase(theme),
        ButtonGroup(theme),
        CardContent(theme),
        Checkbox(theme),
        Chip(theme),
        DataGrid(theme),
        DateCalendar(theme),
        Dialog(theme),
        DialogContentText(theme),
        DialogTitle(theme),
        Drawer(theme),
        Fab(theme),
        FormControlLabel(theme),
        FormHelperText(theme),
        FormLabel(theme),
        IconButton(theme),
        Input(theme),
        InputBase(theme),
        InputLabel(theme),
        LinearProgress(theme),
        Link(theme),
        List(theme),
        ListItemButton(theme),
        ListItemIcon(theme),
        LoadingButton(theme),
        MenuItem(theme),
        NativeSelect(theme),
        OutlinedInput(theme),
        Pagination(theme),
        PaginationItem(theme),
        Paper(theme),
        PickersCalendarHeader(theme),
        PickersMonth(theme),
        PickersYear(theme),
        Popover(theme),
        Radio(theme),
        Select(theme),
        Slider(theme),
        SvgIcon(theme),
        Switch(theme),
        Tab(theme),
        Table(theme),
        TableBody(theme),
        TableCell(theme),
        TableContainer(theme),
        TableFooter(theme),
        TableHead(theme),
        TablePagination(theme),
        TableRow(theme),
        Tabs(theme),
        TabPanel(theme),
        TextField(theme),
        ToggleButton(theme),
        Toolbar(theme),
        Tooltip(theme),
        TreeItem(theme),
        Typography(theme)
    )
}
