// ==============================|| OVERRIDES - TABLE ||============================== //

export default function Table(theme) {
	return {
		MuiTable: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
