// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme) {
	return {
		MuiInputLabel: {
			styleOverrides: {
				root: {},
				standard: {},
				outlined: {},
				filled: {},
				
			}
		}
	}
}
