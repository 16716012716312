// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography(theme) {
	return {
		MuiTypography: {
			// defaultProps: {
			//     gutterBottom: true
			// },
			styleOverrides: {
				// gutterBottom: {
				//     marginBottom: 12
				// },
				root: {
					// color: theme.palette.primary.dark,
				}
			},
		}
	}
}
