// assets
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme) {
	const { palette, spacing } = theme
	
	return {
		MuiAccordionSummary: {
			defaultProps: {
				expandIcon: <ArrowForwardIosOutlinedIcon sx={{ fontSize: '0.75rem' }}/>
			},
			styleOverrides: {
				root: {
					'&.Mui-expanded': {
						borderBottom: `1px solid ${palette.formBox.dividerColor}`,
					},
					flexDirection: 'row-reverse',
					'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
						transform: 'rotate(90deg)',
					},
					'& .MuiAccordionSummary-content': {
						marginLeft: theme.spacing(1),
					}
				},
				expandIconWrapper: {
					// '&.Mui-expanded': {
					//     transform: 'rotate(90deg)'
					// }
				},
				content: {
					fontWeight: 600
				}
			}
		}
	}
}
