// ==============================|| OVERRIDES - ACCORDION ||============================== //

export default function Accordion(theme) {
	return {
		MuiAccordion: {
			defaultProps: {},
			styleOverrides: {
				root: {}
			}
		}
	}
}
