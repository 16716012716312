import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Create.css'
import { useParams } from 'react-router-dom'
import Delete from "../../../../../components/app/delete/delete";
import ContentLoader from "../../../../../components/mui/general/contentLoader/contentLoader";
import Modal from '../../../../../components/mui/general/modal/modal'
import { API } from "../../../../../helpers/constants";
import { access_user } from "../../../../../helpers/rights";
import { withPermissionHOC } from "../../../../permission/components/permissionHOC/permissionHOC";
import useUser from '../../../hooks/useUser'
import { USERS } from "../../../locales/namespaces";
import Form from '../../Form/Form.js'


function Create(props) {
	
	useEffect(() => {
		document.title = !id ? t('User Create') : t('User Edit')
	}, [])
	
	const { id } = useParams()
	const { t } = useTranslation(USERS)
	const { loading, user, staticData } = useUser({ id })
	const [isDeletionVisible, setIsDeletionVisible] = useState(false)
	
	const handleDeletionModal = (visible) => {
		setIsDeletionVisible(visible)
	}
	
	return (
		<Fragment>
			{Object.keys(user).length > 0 && (
				<Fragment>
					{/*TODO::Προσθήκη code='κωδικός μενού' όταν φτιαχτεί δυναμικό μενού χρηστών*!/}
            		{/*<SidePanelLinks/> */}
					<Form
						id={id}
						user={user}
						staticData={staticData}
						onDelete={() => handleDeletionModal(true)}
					/>
					<Modal show={isDeletionVisible}>
						<Delete
							objectId={id}
							objectLabel={t('Username')}
							objectLabelValue={user.username}
							deletionUrl={`${API}/en/user/remove`}
							redirectUrl={`/users`}
							warningMessage={t('All data related to this user will be deleted!')}
							successMessage={t('User deleted successfully!')}
							failureMessage={t('Unable to delete user!')}
							handleModal={handleDeletionModal}
							onClose={() => setIsDeletionVisible(false)}
						/>
					</Modal>
				</Fragment>
			)}
			<ContentLoader loading={loading} withBackDrop useCircularLoader message={t('Please wait...')}/>
		</Fragment>
	)
}

// export default Create;
export default withPermissionHOC(Create, [access_user])