// ==============================|| OVERRIDES - TREE ITEM ||============================== //

export default function TreeItem(theme) {
	return {
		MuiTreeItem: {
			styleOverrides: {
				content: {},
				iconContainer: {}
			}
		}
	}
}
