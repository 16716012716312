import { TextField } from '@mui/material'

export const textFieldDefaults = {
    fullWidth: true,
    variant: 'standard'
}

export const textFieldSelectDefaults = {
    ...textFieldDefaults,
    select: true
}

export const numericFormatInputDefaults = {
    customInput: TextField,
    inputProps: {
        min: 0,
        style: { textAlign: 'right' }
    }
}

export const numericFormatDigitDefaults = {
    thousandSeparator: window.FORMAT_DIGIT,
    decimalSeparator: window.FORMAT_DECIMAL,
    decimalScale: 2
}

export const numericFormatDefaults = {
    ...textFieldDefaults,
    ...numericFormatDigitDefaults,
    ...numericFormatInputDefaults
}

export const formControlDefaults = {
    sx: { width: '100%' }
}