const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'
export const configuration = window.GLOBAL_CONFIGURATION[mode]
export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;
export const PortalToken = window.GLOBAL_CONFIGURATION.portalToken
export const MUI_LICENSE_KEY = '165e31ea195f48fc18e36a0a5d5b4310Tz05MTU1NyxFPTE3NDg2MTg3MzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'

export const Languages = {
	EL: 'el',
	EN: 'en',
}

export const Permissions = {
	AccessAdmin: "ACCESS_ADMIN"
}

export const TimeUnitsIds = {
	Days: 1,
	Min: 2,
	Hours: 3
};