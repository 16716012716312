// project import
import Default from './default'

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

export default function Theme (colors, presetColor, mode) {
    switch (presetColor) {
        default:
            return Default(colors)
    }
}
