import React, { Fragment, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentLoader from "../../../../components/mui/general/contentLoader/contentLoader";
import useAnnouncement from "../../hooks/useAnnouncement";
import { ANNOUNCEMENTS } from "../../locales/namespaces";
import Form from "../Form";

const Create = () => {
	
	let { id } = useParams();
	const { t } = useTranslation(ANNOUNCEMENTS)
	const { loading, announcement, staticData } = useAnnouncement({ id })
	// console.log('announcement = ',announcement,'id = ',id)
	// debugger
	useEffect(() => {
		document.title = !id ? t('Announcement Create') : t('Announcement Edit')
	}, [])
	
	return (
		<Fragment>
			{staticData !== undefined && (
				<Fragment>
					<Form
						id={id}
						announcement={announcement}
						staticData={staticData}
					/>
				</Fragment>
			)
			}
			<ContentLoader loading={loading} withBackDrop useCircularLoader message={t('Please wait...')}/>
		</Fragment>
	);
};

export default Create