// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function Tooltip(theme) {
	return {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {}
			}
		}
	}
}
