// ==============================|| OVERRIDES - SVG ICON ||============================== //

export default function SvgIcon(theme) {
	return {
		MuiTab: {
			styleOverrides: {
				root: {
					// fontSize: 'large'
				}
			}
		}
	}
}
