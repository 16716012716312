// @flow 
import React, { Fragment } from 'react';
import ContentLoader from "../contentLoader/contentLoader";

type Props = {};

const SkeletonLoaderWrapper = (props: Props) => {
	const { loading } = props
	return !loading ? (
		<Fragment>{props.children}</Fragment>
	) : (
		<ContentLoader loading={loading}/>
	)
};

export default SkeletonLoaderWrapper