const INITIAL_STATE = {
	loading: false,
}

const users = {
	initialState: INITIAL_STATE,
	handlers: {}
}

export default users
