// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme) {
	return {
		MuiChip: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
