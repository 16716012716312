export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_MESSAGE = 'SET_USER_MESSAGE';
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD';

export const CHECK_API_CONNECTIVITY = 'CHECK_API_CONNECTIVITY';
export const CHECK_API_CONNECTIVITY_SUCCEEDED = 'CHECK_API_CONNECTIVITY_SUCCEEDED';
export const CHECK_API_CONNECTIVITY_FAILED = 'CHECK_API_CONNECTIVITY_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const REQUEST_RESET_PASSWORD_SUCCEEDED = 'REQUEST_RESET_PASSWORD_SUCCEEDED'
export const REQUEST_RESET_PASSWORD_FAILED = 'REQUEST_RESET_PASSWORD_FAILED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const GET_USER_STORES = 'GET_USER_STORES';
export const GET_USER_STORES_SUCCEEDED = 'GET_USER_STORES_SUCCEEDED';
export const GET_USER_STORES_FAILED = 'GET_USER_STORES_FAILED';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCEEDED = 'GET_USER_DETAILS_SUCCEEDED';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const LOGIN_TO_ITYE_API = 'LOGIN_TO_ITYE_API'
export const LOGIN_TO_ITYE_API_SUCCEEDED = 'LOGIN_TO_ITYE_API_SUCCEEDED'
export const LOGIN_TO_ITYE_API_FAILED = 'LOGIN_TO_ITYE_API_FAILED'