// ==============================|| PRESET THEME - TEAL THEME6 ||============================== //

export default function Default (colors, mode) {
	const { grey } = colors
	
	const contrastText = '#fff'
	
	let primaryColors = ['#e5e5e5', '#cfd0d2', '#bbbdc0', '#a8abae', '#9fa2a5', '#7f8184', '#5f6163', '#3f4042', '#1f2021', '#000000']
	let secondaryColors = ['#cfdae4', '#a0b5c9', '#597ea1', '#406589', '#2a5986', '#113f6c', '#0f3861', '#0d3256', '#0b2c4b', '#030c15']
	let errorColors = ['#FDE8E7', '#F25E52', '#F04134', '#EE3B2F', '#E92A21']
	let warningColors = ['#FFF7E0', '#FFC926', '#FFBF00', '#FFB900', '#FFA900']
	let infoColors = ['#E0F4F5', '#26B0BA', '#00A2AE', '#009AA7', '#008694']
	let successColors = ['#E0F5EA', '#32a361', '#008d3a', '#007e34', '#00702e']
	let text = ['#000000', '#020700', '#616161']
	return {
		...colors,
		primary: {
			lighter: primaryColors[0],
			100: primaryColors[1],
			200: primaryColors[2],
			light: primaryColors[3],
			400: primaryColors[4],
			main: primaryColors[5],
			dark: primaryColors[6],
			700: primaryColors[7],
			darker: primaryColors[8],
			900: primaryColors[9],
			contrastText
		},
		secondary: {
			lighter: secondaryColors[0],
			100: secondaryColors[1],
			200: secondaryColors[2],
			light: secondaryColors[3],
			400: secondaryColors[4],
			main: secondaryColors[5],
			dark: secondaryColors[6],
			700: secondaryColors[7],
			darker: secondaryColors[8],
			900: secondaryColors[9],
			contrastText
		},
		error: {
			lighter: errorColors[0],
			light: errorColors[1],
			main: errorColors[2],
			dark: errorColors[3],
			darker: errorColors[4],
			contrastText
		},
		warning: {
			lighter: warningColors[0],
			light: warningColors[1],
			main: warningColors[2],
			dark: warningColors[3],
			darker: warningColors[4],
			contrastText
		},
		info: {
			lighter: infoColors[0],
			light: infoColors[1],
			main: infoColors[2],
			dark: infoColors[3],
			darker: infoColors[4],
			contrastText
		},
		success: {
			lighter: successColors[0],
			light: successColors[1],
			main: successColors[2],
			dark: successColors[3],
			darker: successColors[4],
			contrastText
		},
		background: {
			default: '#f6f6f6'
		},
		common: {
			black: '#000000',
			white: '#ffffff'
		},
		text: {
			primary: text[0],
			secondary: text[1],
			hint: text[2],
			contrastText
		},
		action: {
			disabled: grey[500]
		},
		divider: grey[400],
		gridColors: {
			borders: '#e0e0e0',
			headerBackground: 'transparent',
			headerFont: '#020700',
			headerSvg: '#616161'
		},
		buttonColors: {
			containedHoverBackground: secondaryColors[5],
			containedHoverColor: contrastText,
			containedColor: secondaryColors[5],
			containedBackground: '#FFFFFF'
		},
		pageTitle: {
			textColor: '#3474a4',
			backgroundColor: 'transparent',
			dividerColor: 'rgb(230, 233, 239)'
		},
		drawerBackgroundColor: '#f9f9f9',
		inputPalette: {
			labelColor: '#3474a4',
			labelBackground: 'transparent',
			inputColor: text[0],
			inputBackground: 'transparent',
			disableUnderline: false,
			borderBottom: `inherit`
		},
		formBox: {
			titleColor: text[0],
			titleBackground: 'transparent',
			dividerColor: '#5cacd4'
		},
		linkStyles: {
			hoverColor: infoColors[4],
			color: '#2a5986'
		}
	}
}
