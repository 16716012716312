// @flow
import { NavigateBefore, Warning } from "@mui/icons-material";
import { Divider, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import axios from "axios";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import BoolImage from "../../../components/custom/general/boolImage/boolImage";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import QuickViewInfoWrap from "../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import SkeletonLoaderWrapper from "../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { access_announcements } from "../../../helpers/rights";
import useUserData from "../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { ANNOUNCEMENTS } from "../locales/namespaces";

type Props = {};

const AnnouncementsDetails = (props: Props) => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [announcement, setAnnouncement] = useState();
	const { t } = useTranslation(ANNOUNCEMENTS)
	const { token } = useUserData()
	useEffect(() => {
		if (id) {
			setLoading(true);
			axios({
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
				url: `${API}/en/messages/details/` + id
			}).then(response => {
				setAnnouncement(response.data.data.message)
				console.log(response.data.data.message)
				setLoading(false)
			}).catch((error) => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
				setLoading(false)
			})
		}
	}, []);
	return (
		<SkeletonLoaderWrapper loading={loading}>
			<Box className={'announcements_details'} sx={{ marginTop: '10px' }}>
				<PageMenu>
					<Button
						component={Link}
						to={'/announcements'}
						startIcon={<NavigateBefore/>}
					>
						{t('Return')}
					</Button>
					<Button
						component={Link}
						to={`/announcement/edit/${id}`}
					>
						{t('Edit')}
					</Button>
				</PageMenu>
				<PageContentWrapper>
					<PageTitle
						withDivider
						title={t('Announcement Details')}
					/>
					<Grid container spacing={1} sx={{ marginLeft: '0px' }}>
						<Grid size={6}>
							<Grid size={12}>
								<QuickViewInfoWrap label={t('Important')}>
									<BoolImage
										isChecked={announcement?.isImportant === '1'}
										alt={t('Important')}
									/>
								</QuickViewInfoWrap>
							</Grid>
							<Grid size={12}>
								<QuickViewInfoWrap label={t('Send e-mail')}>
									<BoolImage
										isChecked={announcement?.SendEmail === '1'}
										alt={t('Send e-mail')}
									/>
								</QuickViewInfoWrap>
							</Grid>
							<Grid size={12}>
								<QuickViewInfoWrap label={t('Type')}> {announcement?.Type} </QuickViewInfoWrap>
							</Grid>
							<Grid size={12}>
								<QuickViewInfoWrap
									label={t('Date From')}> {announcement?.DtFrom ? dayjs(announcement?.DtFrom).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')} </QuickViewInfoWrap>
							</Grid>
							<Grid size={12}>
								<QuickViewInfoWrap
									label={t('expirationDate')}> {announcement?.SendDt ? dayjs(announcement?.SendDt).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')} </QuickViewInfoWrap>
							</Grid>
						</Grid>
						<Grid size={6}>
						
						</Grid>
						<Grid size={6}>
							<Typography variant="h6" component={'div'} style={{ color: '#103f91' }}>
								{announcement?.Title}
							</Typography>
						</Grid>
						<Grid size={12}>
							<Box dangerouslySetInnerHTML={{ __html: announcement?.body }}/>
						</Grid>
					
					</Grid>
				</PageContentWrapper>
			</Box>
		</SkeletonLoaderWrapper>
	);
};

// export default AnnouncementsDetails
export default withPermissionHOC(AnnouncementsDetails, [access_announcements])