//@flow
import { Drawer } from '@mui/material'
import Box from '@mui/material/Box'
import * as React from 'react'

type Props = {
    show: boolean,
    autoClose?: boolean, //automatically close on background click or escape button
    children?: React.Node,
    onClose?: Function
}

const QuickView = (props: Props) => {
    const { show, autoClose = false, children, onClose = () => {}, sx = {} } = props
    const handleClose = (evt, details) => {
        if (!autoClose && (details === "backdropClick" || details === "escapeKeyDown")) return
        onClose(evt, details)
    }
    return (
        <Drawer
            anchor={'right'}
            open={show}
            onClose={handleClose}
        >
            <Box
                sx={{
                    minWidth: '280px',
                    ...sx
                }}
                p={1.5}
            >
                {children}
            </Box>
        </Drawer>
    )
}

export default QuickView
