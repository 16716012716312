import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.RIGHTS]: {
		el: {
			'Actions':'Ενέργειες',
			'Add' : 'Προσθήκη',
			'Description' : 'Περιγραφή',
			'Action' : 'Ενέργεια',
			'Return' : 'Επιστροφή',
			'Save' : 'Αποθήκευση',
			'Delete' : 'Διαγραφή',
			'Edit' : 'Επεξεργασία',
			'Edition' : 'Έκδοση',
			'Module' : 'Υποσύστημα',
			'Attribute' : 'Χαρακτηριστικό',
			'Rights Creation' : 'Δημιουργία Δικαιωμάτων',
			'Required' : 'Απαιτείται',
			'Right created successfully!' : 'Το δικαίωμα δημιουργήθηκε με επιτυχία!',
			'Right deleted successfully!' : 'Το δικαίωμα διαγράφηκε με επιτυχία',
			'Right updated successfully!' : 'Το δικαίωμα ενημερώθηκε με επιτυχία',
			'All data related to this right will be deleted!' : 'Όλα τα δεδομένα που σχετίζονται με αυτό το δικαίωμα θα διαγραφούν!',
			'Unable to delete right!' : 'Αδυναμία διαγραφής δικαιώματος!',
			'An error occurred!' : 'Παρουσιάστηκε σφάλμα!',
			'Failed to update right!' : 'Αποτυχία ενημέρωσης δικαιώματος',
			'Roles And Users of ' : 'Ρόλοι και χρήστες του ',
			'There are not users' : 'Δεν υπάρχουν χρήστες',
			'There are not roles' : 'Δεν υπάρχουν ρόλοι',
			'Roles' : 'Ρόλοι',
			'Users' : 'Χρήστες',
			
			
			
			
		},
		en: {
		
		}
	},
}

export default locales