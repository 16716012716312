// ==============================|| OVERRIDES - ALERT ||============================== //

export default function Alert (theme) {

    return {
        MuiAlert: {
            styleOverrides: {}
        }
    }
}
