// ==============================|| OVERRIDES - BUTTON BASE ||============================== //

export default function ButtonBase(theme) {
	return {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {}
			}
		}
	}
}