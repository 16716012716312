// @flow
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import React from 'react'

type Props = {
    title: string,
    withDivider?: boolean
};

const PageTitle = (props: Props) => {
    const {title, withDivider} = props
    const theme = useTheme()
    const color = theme.palette.pageTitle.textColor
    const backgroundColor = theme.palette.pageTitle.backgroundColor
    return (
        <Box mb={3}>
            <Typography
                variant={'h5'}
                component={'div'}
                color={color}
                sx={{backgroundColor: backgroundColor}}
                mt={1}
                p={0.5}
            >
                {title}
            </Typography>
            {withDivider && (
                <Divider sx={{ borderColor: theme.palette.pageTitle.dividerColor, mb: 1, borderBottomWidth: 'medium' }}/>
            )}
        </Box>
    )
}

export default PageTitle