// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function DialogContentText(theme) {
	return {
		MuiDialogContentText: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
