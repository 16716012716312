// @flow
import React, { Fragment } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { EDITABLE_MUI_GRID } from "../../../../../locales/components/namespaces";

type Props = {
	id: string,
	handleSaveRow: Function,
	handleCancelEditRow: Function,
	hideSaveButton?: boolean,
};

const GridRowEditToolBar = (props: Props) => {
	const { handleSaveRow, handleCancelEditRow, id, hideSaveButton = false } = props
	const {t} = useTranslation(EDITABLE_MUI_GRID)
	return (
		<Fragment>
			{!hideSaveButton && (
				<GridActionsCellItem
					icon={<SaveIcon/>}
					label={t("Save")}
					color={'success'}
					onClick={() => handleSaveRow(id)}
				/>
			)}
			<GridActionsCellItem
				icon={<CancelIcon/>}
				label={t("Cancel")}
				onClick={() => handleCancelEditRow(id)}
				color={'error'}
			/>
		</Fragment>
	)
};

export default GridRowEditToolBar

