// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function ButtonGroup (theme) {
	return {
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {},
				grouped: {
					borderColor: theme.palette.divider,
				},
			}
		}
	}
}
