import { routeCreator } from "../../../helpers/routing/routeCreator";
import ParentDashboard from "../pages/parentDashboard/parentDashboard";


export const ROUTE_PARENT_DASHBOARD = routeCreator(ParentDashboard, '/parent', 'Parents', 'page.parents', {exact: true})

const router = {
    ROUTE_PARENT_DASHBOARD
}

export default router