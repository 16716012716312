import { NavigateBefore } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	MenuItem,
	TextField
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import axios from 'axios'
import { ErrorMessage, FormikProvider } from 'formik'
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Form.css'
import { Link } from 'react-router-dom'
import PageMenu from "../../../../components/custom/app/pageMenu/pageMenu";
import FormBoxTitle from "../../../../components/mui/app/formBoxTitle/formBoxTitle";
import PageContentWrapper from "../../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../../components/mui/app/pageTitle/pageTitle";
import CustomAutocomplete from "../../../../components/mui/form/autocomplete/autocomplete";
import Modal from "../../../../components/mui/general/modal/modal";
import { API } from "../../../../helpers/constants";
import { handleAxiosErrors } from "../../../../helpers/errorHandling";
import {
	formControlDefaults,
	textFieldDefaults,
	textFieldSelectDefaults
} from "../../../../helpers/theme/defaultProps";
import useUserData from "../../../../hooks/user/useUserData";
import useUserForm from '../../hooks/useUserForm'
import { USERS } from "../../locales/namespaces";
import Absence from '../absence/absence'
import Replacers from '../replacers/replacers'
import Upload from "../Upload/Upload";

// type Props = {
// 	id?: string,
// 	onDelete?: Function,
// 	user?: Object,
// 	staticData: Array,
// }

function Form(props) {
	const { token } = useUserData()
	const { id, user, staticData, onDelete } = props
	const { t } = useTranslation(USERS)
	const { formik, loadingSubmit } = useUserForm(({ id, user, staticData }))
	const [skills, setSkills] = useState(staticData.skills)
	const [isUploaderVisible, setIsUploaderVisible] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirm, setShowConfirm] = useState(false)
	
	// const handleCultureChange = (evt) => {
	//     handleInputChange(evt)
	//     const culture = cultures.find(obj => obj.id === evt.target.value)
	//     handleValues({ 'CountryId': culture.CountryId })
	//     handleValues({ 'CurrencyId': culture.CurrencyId })
	//     handleValues({ 'LangId': culture.LanguageId })
	// }
	
	const handleSearch = (evt, name) => {
		let url
		let setter
		switch (name) {
			case 'userSkills':
				url = `en/user/skills`
				setter = setSkills
				break
			default:
				break
		}
		axios({
			method: 'post',
			data: { 'query': evt.target.value },
			url: url,
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			if (response.data.data.length > 0) {
				setter(response.data.data)
			}
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const handleUploadModal = (visible) => {
		setIsUploaderVisible(visible)
	}
	const handleImageFormState = (newValue) => {
		formik.setFieldValue('UserImage', newValue)
	}
	//tabs
	const [tabValue, setTabValue] = useState('1');
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};
	return (
		<FormikProvider value={formik}>
			<form
				onSubmit={formik.handleSubmit}
				autoComplete="off"
				id="userForm"
				encType="multipart/form-data"
				style={{ marginTop: '10px' }}
			>
				<Box display={'flex'}
					 flexWrap={'wrap'}
					 justifyContent={'space-between'}
					 gap={1}>
					<PageMenu>
						<Button
							component={Link}
							startIcon={<NavigateBefore/>}
							to={`/users`}
						>
							{t('Return')}
						</Button>
						{id && (<Button
							component={Link}
							to={`/user/details/${id}`}
						>
							{t('Details')}
						</Button>)}
						<LoadingButton
							loading={loadingSubmit}
							type={'Submit'}
							// onClick={formik.submitForm}
							variant="contained"
							startIcon={<SaveIcon/>}
						>
							{t('Save')}
						</LoadingButton>
					</PageMenu>
					{id && (
						// <PermissionHOC requiredPermissions={['view_all_users']}>
						<Button
							size={'small'}
							variant={'contained'}
							color={'error'}
							onClick={onDelete}
						>
							{t('Delete')}
						</Button>
						// </PermissionHOC>
					)}
				</Box>
				<PageContentWrapper>
					<PageTitle
						withDivider
						title={!id ? t('User Create') : t('User Edit')}
					/>
					<Grid
						container
						spacing={2}
						sx={{ marginLeft: '0px' }}
					>
						
						<Grid size={{ xs: 4, sm: 6, md: 5 }}>
							{/*<FormBoxTitle title={t('Basics')}/>*/}
							
							{/*<Grid size={{ xs: 12 }}>*/}
							{/*	<img*/}
							{/*		id="userImage"*/}
							{/*		src={formik.values.UserImage ? `${API}/uploads/users/${formik.values.UserImage}` : `${API}/images/Add_user_icon.png`}*/}
							{/*		onClick={() => handleUploadModal(true)}*/}
							{/*		alt="icon"*/}
							{/*		width="70"*/}
							{/*		height="70"/>*/}
							{/*</Grid>*/}
							<Grid size={{ xs: 12, sm: 12, md: 12 }}>
								<Box>
									<Grid container spacing={2}>
										<Grid size={{ md: 10 }}>
											<Grid>
												<FormControl
													{...formControlDefaults}
												>
													<FormLabel required>{t('Name')}</FormLabel>
													<TextField
														{...textFieldDefaults}
														name={'LoginName'}
														value={formik.values.LoginName}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
													<FormHelperText>
														<ErrorMessage name={'LoginName'}/>
													</FormHelperText>
												</FormControl>
											</Grid>
											<Grid>
												<FormControl
													{...formControlDefaults}
												>
													<FormLabel>{t('Username')}</FormLabel>
													<TextField
														{...textFieldDefaults}
														name={'username'}
														value={formik.values.username}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
													<FormHelperText>
														<ErrorMessage name={'username'}/>
													</FormHelperText>
												</FormControl>
											</Grid>
											<Grid>
												<FormControl
													{...formControlDefaults}
												>
													<FormLabel required={!id && true}>{t('Password')}</FormLabel>
													<TextField
														{...textFieldDefaults}
														name={'password'}
														type={showPassword ? "text" : "password"}
														value={formik.values.password}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														slotProps={{ //
															input: {// <-- This is where the toggle button is added.
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={() => setShowPassword((prev) => !prev)}
																		>
																			{showPassword ? <VisibilityIcon/> :
																				<VisibilityOffIcon/>}
																		</IconButton>
																	</InputAdornment>
																)
															}
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'password'}/>
													</FormHelperText>
												</FormControl>
											</Grid>
											<Grid>
												<FormControl
													{...formControlDefaults}
												>
													<FormLabel
														required={!id && true}>{t('Confirm Password')}</FormLabel>
													<TextField
														{...textFieldDefaults}
														name={'passwordConfirmation'}
														type={showConfirm ? "text" : "password"}
														value={formik.values.passwordConfirmation}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														slotProps={{
															input: {// <-- This is where the toggle button is added.
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={() => setShowConfirm((prev) => !prev)}
																		>
																			{showConfirm ? <VisibilityIcon/> :
																				<VisibilityOffIcon/>}
																		</IconButton>
																	</InputAdornment>
																)
															}
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'passwordConfirmation'}/>
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>
										<Grid size={{ md: 2 }} sx={{ paddingLeft: '3%', marginTop: '25px' }}>
											<Grid>
												<FormControl
													{...formControlDefaults}
												>
													<FormControlLabel
														control={
															<Checkbox
																checked={formik.values.isActive}
																onChange={formik.handleChange}
																name="isActive"
																onBlur={formik.handleBlur}
															/>
														}
														label={<FormLabel>{t('Active')}</FormLabel>}
													/>
												</FormControl>
											</Grid>
											{/*<Grid>*/}
											{/*	<FormControl*/}
											{/*		{...formControlDefaults}*/}
											{/*	>*/}
											{/*		<FormControlLabel*/}
											{/*			control={*/}
											{/*				<Checkbox*/}
											{/*					checked={formik.values.isInternal}*/}
											{/*					onChange={formik.handleChange}*/}
											{/*					name="isInternal"*/}
											{/*					onBlur={formik.handleBlur}*/}
											{/*					disabled*/}
											{/*				/>*/}
											{/*			}*/}
											{/*			label={<FormLabel>{t('Internal')}</FormLabel>}*/}
											{/*		/>*/}
											{/*	</FormControl>*/}
											{/*</Grid>*/}
											{/*<Grid>*/}
											{/*	<FormControl*/}
											{/*		{...formControlDefaults}*/}
											{/*	>*/}
											{/*		<FormControlLabel*/}
											{/*			control={*/}
											{/*				<Checkbox*/}
											{/*					checked={formik.values.isActor}*/}
											{/*					onChange={formik.handleChange}*/}
											{/*					name="isActor"*/}
											{/*					onBlur={formik.handleBlur}*/}
											{/*				/>*/}
											{/*			}*/}
											{/*			label={<FormLabel>{t('Actor')}</FormLabel>}*/}
											{/*		/>*/}
											{/*	</FormControl>*/}
											{/*</Grid>*/}
											{/*<Grid>*/}
											{/*	<FormControl*/}
											{/*		{...formControlDefaults}*/}
											{/*	>*/}
											{/*		<FormControlLabel*/}
											{/*			control={*/}
											{/*				<Checkbox*/}
											{/*					checked={formik.values.isAppUser}*/}
											{/*					onChange={formik.handleChange}*/}
											{/*					name="isAppUser"*/}
											{/*					onBlur={formik.handleBlur}*/}
											{/*				/>*/}
											{/*			}*/}
											{/*			label={<FormLabel>{t('isAppUser')}</FormLabel>}*/}
											{/*		/>*/}
											{/*	</FormControl>*/}
											{/*</Grid>*/}
											{/*<Grid>*/}
											{/*	<FormControl*/}
											{/*		{...formControlDefaults}*/}
											{/*	>*/}
											{/*		<FormControlLabel*/}
											{/*			control={*/}
											{/*				<Checkbox*/}
											{/*					checked={formik.values.hasMFA}*/}
											{/*					onChange={formik.handleChange}*/}
											{/*					name="hasMFA"*/}
											{/*					onBlur={formik.handleBlur}*/}
											{/*				/>*/}
											{/*			}*/}
											{/*			label={<FormLabel>{t('hasMFA')}</FormLabel>}*/}
											{/*		/>*/}
											{/*	</FormControl>*/}
											{/*</Grid>*/}
										</Grid>
									</Grid>
								</Box>
							</Grid>
						{/*</Grid>*/}
						{/*<Grid size={{ xs: 12, sm: 6, md: 4 }}>*/}
						{/*	<FormBoxTitle title={t('Email')}/>*/}
							<Grid>
								<FormControl
									{...formControlDefaults}
								>
									<FormLabel>{t('Email')}</FormLabel>
									<TextField
										{...textFieldDefaults}
										name={'email'}
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									<FormHelperText>
										<ErrorMessage name={'email'}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							{/*<Grid>*/}
							{/*	<FormControl*/}
							{/*		{...formControlDefaults}*/}
							{/*	>*/}
							{/*		<FormLabel>{t('Mail Server')}</FormLabel>*/}
							{/*		<TextField*/}
							{/*			{...textFieldSelectDefaults}*/}
							{/*			onChange={formik.handleChange}*/}
							{/*			onBlur={formik.handleBlur}*/}
							{/*			value={formik.values.emailServerId}*/}
							{/*			name={'emailServerId'}*/}
							{/*		>*/}
							{/*			<MenuItem key="null" value="0">*/}
							{/*				{t('Select an option')}*/}
							{/*			</MenuItem>*/}
							{/*			{staticData.mailServers?.map((mailServer) => (*/}
							{/*				<MenuItem*/}
							{/*					key={mailServer.id}*/}
							{/*					value={mailServer.id}*/}
							{/*				>*/}
							{/*					{mailServer.Description}*/}
							{/*				</MenuItem>*/}
							{/*			))}*/}
							{/*		</TextField>*/}
							{/*	</FormControl>*/}
							{/*</Grid>*/}
							{/*<Grid>*/}
							{/*	<FormControl*/}
							{/*		{...formControlDefaults}*/}
							{/*	>*/}
							{/*		<FormLabel>{t('Email Password')}</FormLabel>*/}
							{/*		<TextField*/}
							{/*			{...textFieldDefaults}*/}
							{/*			name="emailPassword"*/}
							{/*			type="password"*/}
							{/*			value={formik.values.emailPassword}*/}
							{/*			onChange={formik.handleChange}*/}
							{/*			disabled={!formik.values.emailServerId}*/}
							{/*			autoComplete="new-password"*/}
							{/*			onBlur={formik.handleBlur}*/}
							{/*		/>*/}
							{/*		<FormHelperText>*/}
							{/*			<ErrorMessage name={'emailPassword'}/>*/}
							{/*		</FormHelperText>*/}
							{/*	</FormControl>*/}
							{/*</Grid>*/}
							{/*<Grid>*/}
							{/*	<FormControl*/}
							{/*		{...formControlDefaults}*/}
							{/*	>*/}
							{/*		<FormLabel>{t('Confirm Email Password')}</FormLabel>*/}
							{/*		<TextField*/}
							{/*			{...textFieldDefaults}*/}
							{/*			type="password"*/}
							{/*			name="emailPasswordConfirmation"*/}
							{/*			value={formik.values.emailPasswordConfirmation}*/}
							{/*			onChange={formik.handleChange}*/}
							{/*			disabled={!formik.values.emailServerId}*/}
							{/*			onBlur={formik.handleBlur}*/}
							{/*		/>*/}
							{/*		<FormHelperText>*/}
							{/*			<ErrorMessage name={'emailPasswordConfirmation'}/>*/}
							{/*		</FormHelperText>*/}
							{/*	</FormControl>*/}
							{/*</Grid>*/}
						{/*</Grid>*/}
						{/*<Grid size={{ xs: 12, sm: 6, md: 3 }}>*/}
						{/*	<FormBoxTitle title={t('Localization')}/>*/}
							{/*<Grid>*/}
							{/*	<FormControl*/}
							{/*		{...formControlDefaults}*/}
							{/*	>*/}
							{/*		<FormLabel required>{t('Culture')}</FormLabel>*/}
							{/*		<TextField*/}
							{/*			{...textFieldSelectDefaults}*/}
							{/*			onChange={formik.handleChange}*/}
							{/*			onBlur={formik.handleBlur}*/}
							{/*			value={formik.values.CultureId}*/}
							{/*			name={'CultureId'}*/}
							{/*		>*/}
							{/*			{staticData.cultures?.map((culture) => (*/}
							{/*				<MenuItem*/}
							{/*					key={culture.id}*/}
							{/*					value={culture.id}*/}
							{/*				>*/}
							{/*					{culture.Description}*/}
							{/*				</MenuItem>*/}
							{/*			))}*/}
							{/*		</TextField>*/}
							{/*		<FormHelperText>*/}
							{/*			<ErrorMessage name={'CultureId'}/>*/}
							{/*		</FormHelperText>*/}
							{/*	</FormControl>*/}
							{/*</Grid>*/}
							{/*<Grid>*/}
							{/*	<FormControl*/}
							{/*		{...formControlDefaults}*/}
							{/*	>*/}
							{/*		<FormLabel required>{t('Currency')}</FormLabel>*/}
							{/*		<TextField*/}
							{/*			{...textFieldSelectDefaults}*/}
							{/*			onChange={formik.handleChange}*/}
							{/*			onBlur={formik.handleBlur}*/}
							{/*			value={formik.values.CurrencyId}*/}
							{/*			name={'CurrencyId'}*/}
							{/*		>*/}
							{/*			{staticData.currencies?.map((currency) => (*/}
							{/*				<MenuItem*/}
							{/*					key={currency.id}*/}
							{/*					value={currency.id}*/}
							{/*				>*/}
							{/*					{currency.Description}*/}
							{/*				</MenuItem>*/}
							{/*			))}*/}
							{/*		</TextField>*/}
							{/*		<FormHelperText>*/}
							{/*			<ErrorMessage name={'CurrencyId'}/>*/}
							{/*		</FormHelperText>*/}
							{/*	</FormControl>*/}
							{/*</Grid>*/}
							<Grid>
								<FormControl
									{...formControlDefaults}
								>
									<FormLabel required>{t('Language')}</FormLabel>
									<TextField
										{...textFieldSelectDefaults}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.LangId}
										name={'LangId'}
									>
										{staticData.languages?.map((language) => (
											<MenuItem
												key={language.id}
												value={language.id}
											>
												{language.Description}
											</MenuItem>
										))}
									</TextField>
									<FormHelperText>
										<ErrorMessage name={'LangId'}/>
									</FormHelperText>
								</FormControl>
							</Grid>
							{/*<Grid>*/}
							{/*	<FormControl*/}
							{/*		{...formControlDefaults}*/}
							{/*	>*/}
							{/*		<FormLabel required>{t('Country')}</FormLabel>*/}
							{/*		<TextField*/}
							{/*			{...textFieldSelectDefaults}*/}
							{/*			onChange={formik.handleChange}*/}
							{/*			onBlur={formik.handleBlur}*/}
							{/*			value={formik.values.CountryId}*/}
							{/*			name={'CountryId'}*/}
							{/*		>*/}
							{/*			{staticData.countries?.map((country) => (*/}
							{/*				<MenuItem*/}
							{/*					key={country.id}*/}
							{/*					value={country.id}*/}
							{/*				>*/}
							{/*					{country.Description}*/}
							{/*				</MenuItem>*/}
							{/*			))}*/}
							{/*		</TextField>*/}
							{/*		<FormHelperText>*/}
							{/*			<ErrorMessage name={'CountryId'}/>*/}
							{/*		</FormHelperText>*/}
							{/*	</FormControl>*/}
							{/*</Grid>*/}
						</Grid>
					</Grid>
					{/*<Grid*/}
					{/*	container*/}
					{/*	spacing={2}*/}
					{/*	sx={{ marginLeft: '0px' }}*/}
					{/*>*/}
					{/*	<Grid size={{ xs: 12, sm: 6, md: 4 }}>*/}
					{/*		<FormBoxTitle title={t('Cost')}/>*/}
					{/*		<Grid>*/}
					{/*			<FormControl*/}
					{/*				{...formControlDefaults}*/}
					{/*			>*/}
					{/*				<FormLabel>{t('Internal Cost')}</FormLabel>*/}
					{/*				<TextField*/}
					{/*					{...textFieldSelectDefaults}*/}
					{/*					onChange={formik.handleChange}*/}
					{/*					onBlur={formik.handleBlur}*/}
					{/*					value={formik.values.InternalCostRateId}*/}
					{/*					name={'InternalCostRateId'}*/}
					{/*				>*/}
					{/*					<MenuItem key="null" value="0">*/}
					{/*						{t('Select an option')}*/}
					{/*					</MenuItem>*/}
					{/*					{staticData.internalCostRates?.map((internalCostRate) => (*/}
					{/*						<MenuItem*/}
					{/*							key={internalCostRate.id}*/}
					{/*							value={internalCostRate.id}*/}
					{/*						>*/}
					{/*							{internalCostRate.Description}*/}
					{/*						</MenuItem>*/}
					{/*					))}*/}
					{/*				</TextField>*/}
					{/*			</FormControl>*/}
					{/*		</Grid>*/}
					{/*		<Grid>*/}
					{/*			<FormControl*/}
					{/*				{...formControlDefaults}*/}
					{/*			>*/}
					{/*				<FormLabel>{t('Rate')}</FormLabel>*/}
					{/*				<TextField*/}
					{/*					{...textFieldSelectDefaults}*/}
					{/*					onChange={formik.handleChange}*/}
					{/*					onBlur={formik.handleBlur}*/}
					{/*					value={formik.values.CustCostRateId}*/}
					{/*					name={'CustCostRateId'}*/}
					{/*				>*/}
					{/*					<MenuItem key="null" value="0">*/}
					{/*						{t('Select an option')}*/}
					{/*					</MenuItem>*/}
					{/*					{staticData.costRates?.map((costRate) => (*/}
					{/*						<MenuItem*/}
					{/*							key={costRate.id}*/}
					{/*							value={costRate.id}*/}
					{/*						>*/}
					{/*							{costRate.Description}*/}
					{/*						</MenuItem>*/}
					{/*					))}*/}
					{/*				</TextField>*/}
					{/*			</FormControl>*/}
					{/*		</Grid>*/}
					{/*	</Grid>*/}
					{/*	<Grid size={{ xs: 12, sm: 6, md: 4 }}>*/}
					{/*		<FormBoxTitle title={t('Skills')}/>*/}
					{/*		*/}
					{/*		<Grid>*/}
					{/*			<FormControl*/}
					{/*				{...formControlDefaults}*/}
					{/*			>*/}
					{/*				<FormLabel>{t('Skills')}</FormLabel>*/}
					{/*				<CustomAutocomplete*/}
					{/*					identifier="id"*/}
					{/*					value={formik.values.userSkills}*/}
					{/*					onChange={(evt, item) => {*/}
					{/*						formik.setFieldValue('userSkills', item)*/}
					{/*					}}*/}
					{/*					options={skills}*/}
					{/*					labelKey={'Description'}*/}
					{/*					onInputChange={(event) => handleSearch(event, 'userSkills')}*/}
					{/*					otherprops={{*/}
					{/*						multiple: true,*/}
					{/*						disableCloseOnSelect: true*/}
					{/*					}}*/}
					{/*				/>*/}
					{/*			</FormControl>*/}
					{/*		</Grid>*/}
					{/*	</Grid>*/}
					{/*	<Grid size={{ xs: 12, sm: 6, md: 4 }}>*/}
					{/*		<FormBoxTitle title={t('Portals')}/>*/}
					{/*		*/}
					{/*		<Grid>*/}
					{/*			<FormControl*/}
					{/*				{...formControlDefaults}*/}
					{/*			>*/}
					{/*				<FormLabel>{t('Portals')}</FormLabel>*/}
					{/*				<CustomAutocomplete*/}
					{/*					identifier="id"*/}
					{/*					value={formik.values.userPortals}*/}
					{/*					onChange={(evt, item) => {*/}
					{/*						formik.setFieldValue('userPortals', item)*/}
					{/*					}}*/}
					{/*					options={staticData.portals}*/}
					{/*					labelKey={'Description'}*/}
					{/*					otherprops={{*/}
					{/*						multiple: true,*/}
					{/*						disableCloseOnSelect: true*/}
					{/*					}}*/}
					{/*				/>*/}
					{/*			</FormControl>*/}
					{/*		</Grid>*/}
					{/*	</Grid>*/}
					{/*</Grid>*/}
				</PageContentWrapper>
				{/*<Grid>*/}
				{/*	{id &&*/}
				{/*		<TabContext value={tabValue}>*/}
				{/*			<Box>*/}
				{/*				<TabList onChange={handleTabChange} aria-label="tabs">*/}
				{/*					<Tab label={t('Absence')} value="1"/>*/}
				{/*					<Tab label={t('Replacers')} value="2"/>*/}
				{/*				</TabList>*/}
				{/*			</Box>*/}
				{/*			<TabPanel value="1">*/}
				{/*				<Absence id={id}/>*/}
				{/*			</TabPanel>*/}
				{/*			<TabPanel value="2">*/}
				{/*				<Replacers id={id}/>*/}
				{/*			</TabPanel>*/}
				{/*		</TabContext>*/}
				{/*	}*/}
				{/*</Grid>*/}
			</form>
			<Modal show={isUploaderVisible}>
				<Upload
					handleUploadModal={handleUploadModal}
					handleImageFormState={handleImageFormState}
					urlParameter="user"
				/>
			</Modal>
		</FormikProvider>
	)
}

export default Form