// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AlertTitle(theme) {
	return {
		MuiAlertTitle: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
