export const formControlDefaults = {
    sx: { width: '100%' },
    variant: 'standard'
}

export const textFieldDefaults = {
    fullWidth: true,
    variant: 'standard',
    size: 'small',
}

export const textFieldSelectDefaults = {
    ...textFieldDefaults,
    select: true
}