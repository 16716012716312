//@flow
import React from 'react';
import {Route, Switch} from "react-router";
import { ROUTE_PAGE_LOGIN } from "./routes";
// Έχει σημασία η σειρά δήλωσης.
const ROUTES = [
	ROUTE_PAGE_LOGIN
]

const UnauthenticatedRouter = () => {
	return (
		<Switch>
			{ROUTES.map(route => {
				const {key, path, exact, component} = route;
				return (
					<Route
						key={key}
						path={path}
						exact={exact}
						component={component}
					/>
				);
			})}
		</Switch>
	);
};

export default UnauthenticatedRouter;