import { createContext } from 'react'
import useLocalStorage from '../hooks/theme/useLocalStorage'

// project import
import config from '../themes/config'

// initial state
const initialState = {
    ...config,
    onChangeContainer: () => {},
    onChangeLocalization: () => {},
    onChangeMode: () => {},
    onChangePresetColor: () => {},
    onChangeDirection: () => {},
    onChangeMiniDrawer: () => {},
    onChangeMenuOrientation: () => {},
    onChangeFontFamily: () => {}
}

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState)

function ConfigProvider ({ children }) {
    const [config, setConfig] = useLocalStorage('react-react-ts-config', initialState)

    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container
        })
    }

    const onChangeMode = (mode) => {
        setConfig({
            ...config,
            mode
        })
    }

    const onChangePresetColor = (theme) => {
        setConfig({
            ...config,
            presetColor: theme
        })
    }

    const onChangeDirection = (direction) => {
        setConfig({
            ...config,
            themeDirection: direction
        })
    }

    const onChangeMiniDrawer = (miniDrawer) => {
        setConfig({
            ...config,
            miniDrawer
        })
    }

    const onChangeMenuOrientation = (layout) => {
        setConfig({
            ...config,
            menuOrientation: layout
        })
    }

    const onChangeFontFamily = (fontFamily) => {
        setConfig({
            ...config,
            fontFamily
        })
    }

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeContainer,
                onChangeMode,
                onChangePresetColor,
                onChangeDirection,
                onChangeMiniDrawer,
                onChangeMenuOrientation,
                onChangeFontFamily
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
}

export { ConfigProvider, ConfigContext }
