
// ==============================|| OVERRIDES - TAB ||============================== //

export default function MenuItem (theme) {
	return {
		MuiMenuItem: {
			styleOverrides: {
				root: {
					padding: '4px 8px',
					fontSize: '0.8rem',
					whiteSpace: 'nowrap'
				}
			}
		},
		MuiAccordionSummary: {
			styleOverrides: {
				expandIconWrapper: {
					justifyContent: 'flex-end', // Custom style moving it left
				},
			},
		},
	}
}
