import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { DataGridPremium, GridActionsCellItem } from "@mui/x-data-grid-premium";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import Delete from "../../../components/app/delete/delete";
import Spinner from "../../../components/custom/general/spinner/Spinner";
import ListWithSelectionHighlight
	from "../../../components/mui/app/listWithSelectionHighlight/listWithSelectionHighlight";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import ContentLoader from "../../../components/mui/general/contentLoader/contentLoader";
import { CustomPagination } from "../../../components/mui/grids/dataGridCustom/customPagination";
import DataGridWrapper from "../../../components/mui/grids/dataGridWrapper/dataGridWrapper";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { muiGridLocales } from "../../../helpers/mui/muiLocales";
import { access_rights, access_user } from "../../../helpers/rights";
import { useMuiGridServerSide } from "../../../hooks/muiGrid/useMuiGridServerSide";
import useUserData from "../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { RIGHTS } from "../locales/namespaces";
import RightCreate from "./RightCreate";
import Modal from "../../../components/mui/general/modal/modal";

const RightsGrid = (props) => {
	const { t } = useTranslation(RIGHTS)
	const [openRightForm, setOpenRightForm] = useState(false);
	const [rightId, setRightId] = useState(false);
	const [rightData, setRightData] = useState();
	const [loadingRight, setLoadingRight] = useState(false);
	const [isDeletionVisible, setIsDeletionVisible] = useState(false);
	const [modules, setModules] = useState([]);
	const [selectedModule, setSelectedModule] = useState(0);
	const { token } = useUserData();
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url: 'parameters/rights/create/index/' + selectedModule,
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	useEffect(() => {
		document.title = t('Rights Creation')
	}, [])
	useEffect(() => {
		//modules
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/parameters/rights/create/getFilteredModules`
		}).then(response => {
			setModules(response.data)
			setSelectedModule(response.data.length > 0 ? response.data[0].id : null)
			refreshGrid()
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [t]);
	
	useEffect(() => {
		if (rightId) {
			axios({
				method: 'post',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
				url: `${API}/en/parameters/rights/edit/` + rightId
			}).then(response => {
				setRightData(response.data[0])
				setLoadingRight(false)
			}).catch((error) => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
				setLoadingRight(false)
			})
		}
		
	}, [rightId, t]);
	const handleEdit = (id) => {
		setRightId(id);
		setLoadingRight(true);
		setOpenRightForm(true);
	}
	
	const handleDeletionModal = (visible, rightId) => {
		setIsDeletionVisible(visible)
		setRightId(rightId)
	}
	
	const columns = useMemo(() => {
		return [
			{
				headerName: t('Actions'),
				field: 'actions',
				type: 'actions',
				minWidth: 90, flex: 1,
				renderCell: (params) => {
					return [
						<GridActionsCellItem
							onClick={() => handleEdit(params.row.id)}
							icon={<EditIcon/>}
							label={t('Edit')}
							color={'info'}
							key={`edit-${params.id}`}  // Unique key for the edit action
						/>,
						<GridActionsCellItem
							onClick={() => handleDeletionModal(true, params.row.id)}
							icon={<DeleteIcon/>}
							label={t('Delete')}
							color={'error'}
							key={`delete-${params.id}`}  // Unique key for the delete action
						/>
					]
				}
			},
			{
				field: 'Description',
				headerName: t('Description'),
				minWidth: 180, flex: 1,
			},
			{
				field: 'Edition',
				headerName: t('Edition'),
				minWidth: 180, flex: 1,
			},
			{
				field: 'Module',
				headerName: t('Module'),
				minWidth: 180, flex: 1,
			},
			{
				field: 'Attribute',
				headerName: t('Attribute'),
				minWidth: 180, flex: 1,
			},
			{
				field: 'isAction',
				headerName: t('Action'),
				minWidth: 50, flex: 1,
				type: 'boolean',
				valueGetter: (value, row, column, apiRef) => value === '1'
			}
		]
	}, [t])
	
	const RightToolbar = () => {
		return (
			<>
				<Button
					disableElevation
					onClick={() => setOpenRightForm(true)}
					startIcon={<AddCircleOutlineIcon/>}
					sx={{ alignSelf: 'flex-start' }}
				>
					{t('Add')}
				</Button>
			</>
		)
	}
	
	const handleClose = () => {
		setOpenRightForm(false)
		setRightId(null)
		setRightData(null)
		setIsDeletionVisible(false)
		refreshGrid()
	}
	return data !== null ? (
			<Box className="right_grid">
				<PageContentWrapper>
					<PageTitle title={t('Rights Creation')}/>
					<Grid container spacing={2}>
						<Grid size={{ xs: 2, md: 2 }}>
							{modules &&
								<ListWithSelectionHighlight
									highLight={false}
									data={modules}
									selected={selectedModule}
									onListItemClick={(selection) => {
										console.log(selection)
										setSelectedModule(selection)
										refreshGrid()
									}}
								/>
							}
						</Grid>
						<Grid size={{ xs: 10, md: 10 }}>
							<DataGridWrapper sx={{ height: 700 }}>
								<DataGridPremium
									columns={columns}
									rows={data}
									pagination
									paginationModel={{ page, pageSize }}
									paginationMode='server'
									onPaginationModelChange={onPaginationModelChange}
									rowCount={Number(total)}
									pageSizeOptions={[5, 10, 15, 20, 50, 100]}
									sortingMode="server"
									onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
									filterMode='server'
									onFilterModelChange={onFilterModelChange}
									loading={isLoading}
									localeText={muiGridLocales('el')}
									initialState={{ density: 'compact' }}
									slots={{
										toolbar: RightToolbar,
										pagination: CustomPagination,
									}}
								/>
							</DataGridWrapper>
						</Grid>
					</Grid>
				
				</PageContentWrapper>
				
				<Modal
					minWidth={'xs'}
					sx={{
						'& .MuiDialog-paper': {
							width: '560px'
						}
					}}
					
					show={openRightForm}
					onBackgroundClick={handleClose}
				>
					{!loadingRight ? (
						<RightCreate
							rightId={rightId}
							rightData={rightData}
							moduleId={selectedModule}
							onClose={handleClose}
						/>
					) : (
						<ContentLoader loading={loadingRight}/>
					)}
				</Modal>
				<Modal show={isDeletionVisible}>
					<Delete
						// setRefreshGrid={true}
						objectId={rightId}
						objectLabel={t('Right')}
						objectLabelValue={rightData?.Description}
						deletionUrl={`${API}/en/parameters/rights/create/remove`}
						// redirectUrl={`/${LANG}/parameters/rights/create`}
						warningMessage={t('All data related to this right will be deleted!')}
						successMessage={t('Right deleted successfully!')}
						failureMessage={t('Unable to delete right!')}
						handleModal={handleDeletionModal}
						onClose={handleClose}
					/>
				</Modal>
			</Box>
		) :
		<Spinner show={true} message="Loading"/>
};

// export default RightsGrid
export default withPermissionHOC(RightsGrid, [access_rights])