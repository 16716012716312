//@flow
import * as React from 'react';
import {Backdrop, Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import {blue} from "@mui/material/colors";
import NotFound from "../../../../pages/notFound/notFound";

type Props = {
	loading: boolean,
	notFoundWhenNotLoading?: boolean,
	notFoundComponent?: React.Node,
	useCircularLoader?: boolean,
	message?: string,
	withBackDrop?: boolean
}

const Loader = ({useCircularLoader, message, color}) => {
	return (
		<Box sx={{width: "100%", py: 2}}>
			{useCircularLoader ? (
				<Box sx={{
					width: "100%",
					display: 'flex',
					minHeight: 100,
					justifyContent: "center",
					alignItems: "center",
					flexDirection: 'column'
				}}>
					{message && (
						<Typography
							variant="button"
							color={color || blue[200]}
						>
							{message}
						</Typography>
					)}
					<CircularProgress
						sx={{
							color: color || blue[200]
						}}
					/>
				</Box>
			) : (
				<React.Fragment>
					<Skeleton/>
					<Skeleton animation="wave"/>
					<Skeleton animation={false}/>
				</React.Fragment>
			)}
		</Box>
	)
}

const ContentLoader = (props: Props) => {
	const {loading, notFoundWhenNotLoading, notFoundComponent, useCircularLoader, message, withBackDrop, color} = props
	if (!loading && !notFoundWhenNotLoading) return null
	if (!loading && notFoundWhenNotLoading) return notFoundComponent ?? <NotFound/>
	
	return withBackDrop ? (
		<Backdrop
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={loading}
		>
			<Loader
				useCircularLoader={useCircularLoader}
				message={message}
				color={color}
			/>
		</Backdrop>
	) : (
		<Loader
			useCircularLoader={useCircularLoader}
			message={message}
			color={color}
		/>
	)
}

export default ContentLoader