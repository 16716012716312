import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.FAQS]: {
		el: {
			'FAQs' : 'Συχνές ερωτήσεις',
		},
		en: {
		
		}
	},
}

export default locales