//@flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import './spinner.css'

type Props = {
  show: boolean,
  inline?: boolean,
  message?: React.Node,
}

const Wrapper = (props: Props) => {
  const visibilityClass = (props.show) ?
    'spinner_modal spinner_display-block' : 'spinner_modal spinner_display-none'
  return props.inline ?
    <div className={'spinner--inline'}>{props.children}</div> :
    <div className={visibilityClass}>
      <section className="spinner_modal-main">{props.children}</section>
    </div>
}

const Spinner = (props: Props) => {
  const { show, inline, message } = props

  const { t } = useTranslation('uiComponents')

  if (!show) return null
  return (
    <Wrapper show={show} inline={inline}>
      <div className="loader_message">{t(message)}</div>
      <div className="loader"/>
    </Wrapper>
  )
}

export default Spinner
