import axios from 'axios'
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";

const useUser = ({ id }) => {
	const { t } = useTranslation('userProperties')
	const { token } = useUserData()
	const [loading, setLoading] = useState(true)
	const [user, setUser] = useState({})
	const [staticData, setStaticData] = useState({
		Langs: [{ Code: 'EN', isDefault: '1', id: '-1' }],
		costRates: [],
		countries: [],
		cultures: [],
		currencies: [],
		internalCostRates: [],
		languages: [],
		mailServers: [],
		portals: [],
		skills: []
	})
	
	useEffect(() => {
		if (!id) {
			setLoading(true)
			axios({
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
				url: `${API}/en/user/create/data`
			}).then(res => {
				const data = res.data
				setStaticData(data)
				setUser({
					...data,
					
				})
				setLoading(false)
			}).catch(error => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
				setLoading(false)
			})
		} else {
			setLoading(true)
			axios({
				method: 'post',
				data: { UserId: id },
				url: `${API}/en/user/edit/data`,
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			}).then(res => {
				let data = res.data
				const user = res.data.user
				setUser({
					...user,
					isActive: user.isActive === '1',
					isInternal: user.isInternal === '1',
					isActor: user.isActor === '1',
					isAppUser: user.isAppUser === '1',
					hasMFA: user.hasMFA === '1',
					username: user.username,
					LoginName: user.LoginName,
					email: user.email,
					emailServerId: user.emailServerId,
					CultureId: user.CultureId,
					CurrencyId: user.CurrencyId,
					LangId: user.LangId,
					CountryId: user.CountryId,
					InternalCostRateId: user.InternalCostRateId,
					CustCostRateId: user.CustCostRateId,
					AbsenceDtFrom: user.AbsenceDtFrom,
					AbsenceDtTo: user.AbsenceDtTo,
					AbsenceMessage: user.AbsenceMessage,
					//UserImage: user.UserImage,
					userSkills: data.userSkills,
					userPortals: data.userPortals
				})
				setStaticData(data)
				setLoading(false)
				
			}).catch((error) => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
				setLoading(false)
			})
		}
	}, [])
	
	return {
		loading,
		user,
		staticData
	}
	
}
export default useUser;