//@flow
import React from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	FormControl
} from "@mui/material"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { formControlDefaults } from "../../../../helpers/theme/defaultProps"
import _ from "lodash";
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from "@mui/material/InputAdornment";

type Props = {
	identifier: string,
	value: any, // Must be initialized as empty array when property 'multiple' is used.
	onChange: Function, // Event handler for onChange autocomplete event. Use to set value state or any custom logic
	options: any[], // List of options
	labelKey: string, // The key used to display the option label
	loading?: boolean, // Display loading indicator for server side data search
	onBlur?: Function, // Function to handle onBlur event
	onInputChange?: Function, // Event handler for onChange event of autocomplete input component. Use for server side data search or other custom logic.
	multipleSearchList?: boolean, // Flag to enable multiple search results, when list is displayed as table with multiple columns
	labelTextField?: string, // Label for the text field
	sizeTextField?: 'small' | 'medium' | 'large', // Size of the text field
	headers?: string[], // Headers when list is displayed as table with multiple columns
	rowkeys?: string[], // Keys used to access values in the table rows,  when list is displayed as table with multiple columns
	disabled?: boolean
}

const ListBoxComponent = React.forwardRef(
	function ListBoxComponent(props, ref) {
		const { headers, children, ...rest } = props
		return (
			<div
				ref={ref}
			>
				<TableContainer
					{...rest}
					component={Paper}
					sx={{
						position: 'absolute',
						zIndex: 100,
						maxHeight: '450px',
						//width: 'max-content'
					}}
				>
					<Table size="small">
						<TableHead>
							<TableRow>
								{headers && headers.map((header, index) => (
									<TableCell key={index} style={{ fontWeight: 'bold' }}>
										{header}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{children}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		)
	})

const TableListComponent = ({ optionProps, option, rowkeys }) => {
	return (
		<TableRow
			{...optionProps}
			sx={{ display: "table-row!important", '&:last-child td, &:last-child th': { border: 0 } }}
			key={option.id}
		>
			{rowkeys.map((key, index) => (
				<TableCell key={index} component="th" scope="row">
					{option[key]}
				</TableCell>
			))}
		</TableRow>
	)
}

const filter = createFilterOptions()

const CustomAutocomplete = (props: Props) => {
	const {
		loading = false,
		identifier,
		value,
		onChange,
		options,
		labelKey,
		onBlur,
		onInputChange = () => {
		},
		multipleSearchList = false,
		labelTextField,
		sizeTextField = 'medium',
		headers = [],
		rowkeys = [],
		disabled = false,
		otherprops = {} //More autocomplete props https://mui.com/material-ui/api/autocomplete/
	} = props
	
	const debouncedOnChange = _.debounce(onInputChange, 500)
	
	const renderOption = (optionProps, option, state) => {
		const { key, ...tableRowProps } = optionProps
		if (multipleSearchList) {
			return (
				<TableListComponent key={key} optionProps={tableRowProps} option={option} rowkeys={rowkeys}/>
			)
		}
		if (otherprops.multiple) {
			return (
				<TableRow {...tableRowProps} key={key} sx={{ display: "table-row!important", border: 'none' }}>
					<TableCell sx={{ border: 'none' }}>
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
							checkedIcon={<CheckBoxIcon fontSize="small"/>}
							style={{ marginRight: 8 }}
							checked={state.selected}
						/>
						{option[labelKey]}
					</TableCell>
				</TableRow>
			)
		}
		return (
			<TableRow
				{...tableRowProps}
				key={key}
				aria-selected={false}
				sx={{ display: "table-row!important", border: 'none' }}
			>
				<TableCell sx={{ border: 'none' }}>{option[labelKey]}</TableCell>
			</TableRow>
		)
	}
	
	return (
		<FormControl {...formControlDefaults}>
			<Autocomplete
				id={identifier}
				value={value}
				onChange={onChange}
				options={options}
				onBlur={onBlur}
				headers={headers}
				rowkeys={rowkeys}
				disabled={disabled}
				isOptionEqualToValue={(opt, val) => {
					return (opt[identifier] === val[identifier] || !val)
				}}
				getOptionLabel={(option) => {
					if (typeof option === 'string') {
						return option
					}
					if (option.inputValue) {
						return option.inputValue
					}
					return option[labelKey] || ''
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params)
					const { inputValue } = params
					const isExisting = options.some((option) => inputValue === option[labelKey])
					if (inputValue !== '' && !isExisting && !multipleSearchList && otherprops.freeSolo) {
						filtered.push({
							inputValue,
							[labelKey]: `Add "${inputValue}"`,
						})
					}
					return filtered
				}}
				ListboxComponent={ListBoxComponent}
				ListboxProps={{
					headers: headers
				}}
				renderOption={renderOption}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							onChange={debouncedOnChange}
							size={sizeTextField}
							label={labelTextField}
							variant="standard"
							slotProps={{
								input: {
									...params.InputProps,
									autoComplete: 'off',
									form: {
										autoComplete: 'off',
									},
								},
								endAdornment: (
									<InputAdornment position={'end'}>
										{loading ? <CircularProgress size={16}/> : null}
										{params.InputProps.endAdornment}
									</InputAdornment>
								),
							}}
						/>
					)
				}}
				{...otherprops}
			/>
		</FormControl>
	)
}

export default CustomAutocomplete
