//@flow
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import React, { useEffect } from 'react'
import SkeletonLoaderWrapper from "../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { muiDatePickersLocales } from "../../helpers/mui/muiLocales";

import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";

import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";

import dayjs from "dayjs";
import 'dayjs/locale/el'

const Main = () => {
	const { isLoggedIn, language, loading, loadingITYE } = useUserData()
	dayjs.locale(language);
	const { getUserPermissions, getUserDetails, loginToITYEApi } = useUserAction()
	
	useEffect(() => {
		if (isLoggedIn) {
			getUserPermissions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn])
	
	useEffect(() => {
		if (isLoggedIn) {
			// getUserDetails() //TODO::Uncomment when API is ready
		}
	}, [getUserDetails, isLoggedIn])
	
	useEffect(() => {
		if(isLoggedIn) {
			// TODO::Κανονικά τα credentials δεν θα πρέπει να τα φυλάμε στην javascript. Πρέπει να έρχονται από τον server.
			loginToITYEApi({
				username: 'ergologic',
				password: 'NKsTYCdcxq'
			})
		}
	}, [isLoggedIn, loginToITYEApi])
	
	if (!isLoggedIn) return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			localeText={muiDatePickersLocales(language)}
		>
			<UnauthenticatedRouter/>
		</LocalizationProvider>
	)
	
	return (
		<SkeletonLoaderWrapper loading={loading && loadingITYE}>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				localeText={muiDatePickersLocales(language)}
			>
				<Content/>
			</LocalizationProvider>
		</SkeletonLoaderWrapper>
	)
}

export default Main