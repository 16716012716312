import { NavigateBefore } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import axios from 'axios'
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Details.css'
import { Link, useParams } from 'react-router-dom'
import Delete from "../../../../../components/app/delete/delete";
import PageMenu from "../../../../../components/custom/app/pageMenu/pageMenu";
import ContentLoader from "../../../../../components/mui/general/contentLoader/contentLoader";
import Modal from '../../../../../components/mui/general/modal/modal.js'
import { API } from "../../../../../helpers/constants";
import { handleAxiosErrors } from "../../../../../helpers/errorHandling";
import { access_user } from "../../../../../helpers/rights";
import useUserData from "../../../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../../../permission/components/permissionHOC/permissionHOC";
import { USERS } from "../../../locales/namespaces";
import DetailsTables from '../../DetailsTables/DetailsTables.js'


function Details() {
	let { id } = useParams()
	const { t } = useTranslation(USERS)
	const {token} = useUserData()
	const [user, setUser] = useState({})
	const [aapCurrencies, setAapCurrencies] = useState([])
	const [aapLanguages, setAapLanguages] = useState([])
	const [aapCountries, setAapCountries] = useState([])
	const [userSkills, setUserSkills] = useState([])
	const [topSkills, setTopSkills] = useState([])
	const [userRoles, setUserRoles] = useState([])
	const [topRoles, setTopRoles] = useState([])
	const [userPortals, setUserPortals] = useState([])
	const [portals, setPortals] = useState([])
	
	const [loading, setLoading] = useState(true)
	const [isDeletionVisible, setIsDeletionVisible] = useState(false)
	
	useEffect(() => {
		document.title = t('User Details')
		setLoading(true)
		axios({
			method: 'post',
			data: { UserId: id },
			url: `${API}/en/user/details/data`,
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			setUser(response.data.user)
			setAapCurrencies(response.data.aapCurrencies)
			setAapLanguages(response.data.aapLanguages)
			setAapCountries(response.data.aapCountries)
			setUserSkills(response.data.userSkills)
			setTopSkills(response.data.topSkills)
			setUserRoles(response.data.userRoles)
			setTopRoles(response.data.topRoles)
			setUserPortals(response.data.userPortals)
			setPortals(response.data.portals)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [id, t])
	
	const handleDeletionModal = (visible) => {
		setIsDeletionVisible(visible)
	}
	
	const handleSearch = (evt, name) => {
		let url
		let setter
		switch (name) {
			case 'skills':
				url = `${API}/en/user/skills`
				setter = setTopSkills
				break
			case 'roles':
				url = `${API}/en/user/roles`
				setter = setTopRoles
				break
		}
		
		axios({
			method: 'post',
			data: { 'query': evt.target.value },
			url: url,
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			if (response.data.data.length > 0) {
				setter(response.data.data)
			}
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	return (
		<Fragment>
			{Object.keys(user).length > 0 && (
				<Box className={'users_details'} sx={{marginTop:'20px'}}>
					{/*TODO::Προσθήκη code='κωδικός μενού' όταν φτιαχτεί δυναμικό μενού χρηστών*/}
					{/*<SidePanelLinks/>*/}
					<Box
						display={'flex'}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
						gap={1}
					>
						{/*<PermissionHOC requiredPermissions={['view_all_users']}>*/}
						<PageMenu>
							<Button
								component={Link}
								to={`/users`}
								startIcon={<NavigateBefore/>}
							>
								{t('Return')}
							</Button>
							{/*</PermissionHOC>*/}
							<Button
								component={Link}
								to={`/user/edit/${id}`}
							>
								{t('Edit')}
							</Button>
						</PageMenu>
						<Button
							size={'small'}
							variant={'contained'}
							color={'error'}
							onClick={() => handleDeletionModal(true)}
						>
							{t('Delete')}
						</Button>
					</Box>
					<Box>
						<DetailsTables
							user={user}
							aapCurrencies={aapCurrencies}
							aapLanguages={aapLanguages}
							aapCountries={aapCountries}
							userSkills={userSkills}
							topSkills={topSkills}
							userRoles={userRoles}
							topRoles={topRoles}
							userPortals={userPortals}
							portals={portals}
							handleSearch={handleSearch}
						/>
					</Box>
					<Modal
						show={isDeletionVisible}
					>
						<Delete
							objectId={id}
							objectLabel={t('Username')}
							objectLabelValue={user.username}
							deletionUrl={`${API}/en/user/remove`}
							redirectUrl={`/users`}
							warningMessage={t('All data related to this user will be deleted!')}
							successMessage={t('User deleted successfully!')}
							failureMessage={t('Unable to delete user!')}
							handleModal={handleDeletionModal}
							onClose={() => setIsDeletionVisible(false)}
						/>
					</Modal>
				</Box>
			)}
			<ContentLoader loading={loading} withBackDrop useCircularLoader message={t('Please wait...')}/>
		</Fragment>
	)
}

// export default Details
export default withPermissionHOC(Details, [access_user])