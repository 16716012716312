// ==============================|| OVERRIDES - PICKERS YEAR ||============================== //

export default function PickersYear (theme) {
    return {
        MuiPickersYear: {
            styleOverrides: {
                // root: {}
                // yearButton: {}
            }
        }
    }
}
