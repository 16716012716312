import LoadingButton from "@mui/lab/LoadingButton";
import {
	Checkbox,
	DialogActions,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	MenuItem,
	TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import { ErrorMessage, FormikProvider } from "formik";
import React from 'react';
import { useTranslation } from "react-i18next";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import ModalHeader from "../../../components/mui/general/modal/partials/modalHeader";
import { formControlDefaults, textFieldDefaults, textFieldSelectDefaults } from "../../../helpers/forms/defaultProps";

import useRightForm from "../hooks/useRightForm";
import { RIGHTS } from "../locales/namespaces";

const RightCreate = (props) => {
	const {
		onClose, rightId, rightData, moduleId
	} = props
	const { t } = useTranslation(RIGHTS)
	
	const { formik, loadingSubmit, modules, editions } = useRightForm({ rightId, rightData, onClose, moduleId })
	
	return (
		<FormikProvider value={formik}>
			<Box
				component={'form'}
				autoComplete="off"
				className="rightCreateForm"
				onSubmit={formik.handleSubmit}
			>
				<PageContentWrapper>
					<ModalHeader
						title={t('Rights Creation')}
						withDivider
						onClose={onClose}
					/>
					<DialogContent>
						<Box>
							<Grid container spacing={2}>
								<Grid size={{ xs: 12, sm: 12, md: 12 }}>
									<FormControl
										{...formControlDefaults}
										error={
											formik.touched['Description'] &&
											Boolean(formik.errors['Description'])
										}
									>
										<FormLabel required>{t('Description')}</FormLabel>
										<TextField
											{...textFieldDefaults}
											name={'Description'}
											value={formik.values.Description}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											inputProps={{
												maxLength: 250,
											}}
										/>
										<FormHelperText>
											<ErrorMessage name={'Description'}/>
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid size={{ xs: 12, sm: 12, md: 6 }}>
									<FormControl
										{...formControlDefaults}
										error={
											formik.touched['ModId'] &&
											Boolean(formik.errors['ModId'])
										}
									>
										<FormLabel required>{t('Module')}</FormLabel>
										<TextField
											{...textFieldSelectDefaults}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.ModId}
											name={'ModId'}
										>
											{modules?.map(item => (
												<MenuItem
													key={item.id}
													value={item.id}
												>{item.Description}</MenuItem>
											))}
										</TextField>
										<FormHelperText>
											<ErrorMessage name={'ModId'}/>
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid size={{ xs: 12, sm: 12, md: 6 }}>
									<FormControl
										{...formControlDefaults}
										error={
											formik.touched['EdtnId'] &&
											Boolean(formik.errors['EdtnId'])
										}
									>
										<FormLabel required>{t('Edition')}</FormLabel>
										<TextField
											{...textFieldSelectDefaults}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.EdtnId}
											name={'EdtnId'}
										>
											{editions?.map(item => (
												<MenuItem
													key={item.id}
													value={item.id}
												>{item.Description}</MenuItem>
											))}
										</TextField>
										<FormHelperText>
											<ErrorMessage name={'EdtnId'}/>
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid size={{ md: 9 }}>
									<FormControl
										{...formControlDefaults}
										error={
											formik.touched['Attribute'] &&
											Boolean(formik.errors['Attribute'])
										}
									>
										<FormLabel>{t('Attribute')}</FormLabel>
										<TextField
											{...textFieldDefaults}
											name={'Attribute'}
											value={formik.values.Attribute}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											slotProps={{
												input: {
													maxLength: 250,
												}
											}}
											disabled={true}
										/>
										<FormHelperText>
											<ErrorMessage name={'Attribute'}/>
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid size={{ sm: 3 }}>
									<FormControl
										{...formControlDefaults}
									>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik.values.isAction}
													onChange={formik.handleChange}
													name="isAction"
													onBlur={formik.handleBlur}
												/>
											}
											label={<FormLabel>{t('Action')}</FormLabel>}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							loading={loadingSubmit}
							type={'submit'}
							variant="contained"
							color={'success'}
							sx={{
								marginLeft: 'auto'
							}}
						>
							{t('Save')}
						</LoadingButton>
					</DialogActions>
				</PageContentWrapper>
			</Box>
		</FormikProvider>
	)
};

export default RightCreate