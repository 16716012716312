// ==============================|| OVERRIDES - LIST ||============================== //

export default function List (theme) {
	return {
		MuiList: {
			styleOverrides: {
				root: {
					padding: '2px'
					// '&.Mui-selected': {
					//     color: theme.palette.primary.main,
					//     '& .MuiListItemIcon-root': {
					//         color: theme.palette.primary.main
					//     }
					// }
				}
			}
		}
	}
}