import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions, DialogTitle, Stack, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import axios from 'axios'
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useUserData from "../../../hooks/user/useUserData";
import { USERS } from "../../../modules/users/locales/namespaces";

function Delete(props) {
	const { t } = useTranslation(USERS)
	const {token} = useUserData()
	const {
		objectId,
		objectLabel,
		objectLabelValue,
		deletionUrl,
		redirectUrl,
		warningMessage,
		successMessage,
		failureMessage,
		handleModal = () => {
		},
		afterDeletion,
		onClose = () => {
		},
		otherProperties = {}
	} = props
	const [loading, setLoading] = useState(false)
	
	const history = useHistory()

	const deleteConfirmed = () => {
		setLoading(true)
		axios({
			method: 'post',
			data: {
				id: objectId,
				...otherProperties
			},
			url: deletionUrl,
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			setLoading(false)
			if (response.data.message === 'errors') {
				enqueueSnackbar(<div>{failureMessage}<br/><br/>{response.data.errors}</div>, {
					variant: 'error',
					autoHideDuration: 1000,
				});
				
			} else {
				enqueueSnackbar(successMessage, {
					variant: 'success',
					autoHideDuration: 1000,
				});
				
				if (typeof afterDeletion !== 'undefined') {
					afterDeletion()
				} else {
					onClose()
					if (redirectUrl) history.push(redirectUrl)
				}
			}
			
			handleModal(false)
		}).catch(error => {
			setLoading(false)
			
			enqueueSnackbar(<div>
				{failureMessage}<br/><br/>
				{t(error.response.data.message)}<br/>
				<Button
					onClick={() =>
						enqueueSnackbar(error.response.data.errors,  {
							variant: 'success',
							autoHideDuration: 1000,
						})}
				>
					{t('Details')}
				</Button>
			</div>, {
				variant: 'error',
				autoHideDuration: 1000,
			});
		})
	}
	
	return (
		<>
			<DialogTitle>
				{warningMessage}
			</DialogTitle>
			<DialogContent dividers>
				<Stack
					direction={'row'}
					spacing={1}
					p={1}
				>
					<Typography
						variant={'subtitle2'}
						color={'error'}
						component={'div'}
					>
						{objectLabel}:
					</Typography>
					<Typography
						variant={'body2'}
						component={'div'}
					>
						{objectLabelValue}
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose()
						handleModal(false)
					}}
					sx={{
						marginRight: 'auto'
					}}
				>
					{t('Cancel')}
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					color={'error'}
					onClick={deleteConfirmed}
				>
					{t('Confirm')}
				</LoadingButton>
			</DialogActions>
		</>
	)
}

export default Delete