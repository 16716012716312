import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { GridRowModes } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import { useTranslation } from 'react-i18next'
import { handleAxiosErrors } from "../../../../../helpers/errorHandling";

export const useMUIDataGridCrudOperations = ({
												 initialRows,
												 newRowModel,
												 store,
												 update,
												 remove
											 }) => {
	const [rows, setRows] = useState(initialRows);
	const [rowModesModel, setRowModesModel] = useState({});
	const [deleteDialogOpen, setDeleteDialogOpen] = useState({
		open: false,
		id: null
	})
	const { t } = useTranslation('customerProperties')
	
	useEffect(() => {
		setRows(initialRows)
	}, [initialRows])
	
	const handleDeleteDialogOpen = () => {
		setDeleteDialogOpen({
			open: false,
			id: null
		})
	}
	
	const handleRows = (newRows) => {
		setRows(newRows)
	}
	
	const handleRowModesModel = (newRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	
	const handleEditRowStart = (params, event) => {
		event.defaultMuiPrevented = true;
	}
	
	const handleEditRowStop = (params, event) => {
		event.defaultMuiPrevented = true;
	}
	
	const handleAddNewRow = () => {
		const id = randomId();
		setRows([{ id, ...newRowModel, isNew: true }, ...rows]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit },
		}));
	}
	
	const handleEditRow = (id) => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	}
	
	const handleCancelEditRow = (id) => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});
		
		const editedRow = rows.find((row) => row.id === id);
		if (editedRow.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	}
	
	const handleSaveRow = (id) => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	}
	
	const handleUpdateRow = (newRow, oldRow) => {
		const newRowAdded = oldRow.isNew
		if (newRowAdded) {
			if(_.isEqual(newRow, oldRow)) {
				setRows(prev => prev.filter((row) => row.id !== newRow.id))
				enqueueSnackbar(t(`Row cannot be empty`), {
					variant: 'error',
					autoHideDuration: 1000,
				});
			} else {
				store(newRow)
			}
		} else {
			if(_.every(_.omit(newRow, 'id'), _.isEmpty)) {
				enqueueSnackbar(t(`Row cannot be empty`), {
					variant: 'error',
					autoHideDuration: 1000,
				});
				return oldRow
			} else {
				update(newRow, oldRow)
			}
		}
		return newRow
	}
	
	const handleDeleteRow = (id) => {
		setDeleteDialogOpen({
			open: true,
			id: id
		})
	}
	
	const handleDeleteConfirmation = (id) => {
		remove(id)
	}
	
	const handleUpdateRowError = useCallback((error) => {
		setRowModesModel(rowModesModel.map(row => ({
			...row,
			mode: GridRowModes.View,
			ignoreModifications: true
		})))
		enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
			variant: 'warning',
			autoHideDuration: 1000,
		}))
	}, [t, rowModesModel])
	
	return {
		rows,
		rowModesModel,
		deleteDialogOpen,
		handleDeleteDialogOpen,
		handleRows,
		handleRowModesModel,
		handleEditRowStart,
		handleEditRowStop,
		handleEditRow,
		handleCancelEditRow,
		handleAddNewRow,
		handleSaveRow,
		handleDeleteRow,
		handleDeleteConfirmation,
		handleUpdateRow,
		handleUpdateRowError
	}
}