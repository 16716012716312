import Button from "@mui/material/Button";
import * as React from 'react';
import { useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import {
	access_announcements,
	access_news,
	access_rights,
	access_roles,
	access_user
} from "../../../../helpers/rights";
import { ROUTE_ANNOUNCEMENT_INDEX, ROUTE_ANNOUNCEMENT_NEWS } from "../../../../modules/announcements/routes/routes";
import { ROUTE_PAGE_CONTRACTOR_ROUTES_INDEX } from "../../../../modules/contractors/routes/routes";
import { ROUTE_PAGE_RIGHTS_INDEX } from "../../../../modules/rights/routes/routes";
import { ROUTE_PAGE_USERS_INDEX } from "../../../../modules/users/routes/routes";
import { ROUTE_PAGE_SECURITY_ROLES_INDEX } from "../../../../modules/usersSecurityRoles/routes/routes";
import Logo from "../../../custom/general/logo/logo";
import { getRouteUrl } from "../../../../helpers/routing/getRouteUrl";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "../../../../locales/components/namespaces";
import {
	ROUTE_PAGE_HOME
} from "../../../../routers/routes";
import LanguageSelect from "../../languageSelect/languageSelect";
import AccountMenu from "../../accountMenu/accountMenu";
import AppDrawer from "../appDrawer/appDrawer";
import MainMenu from "../mainMenu/mainMenu";

export const menuStyle = {
	color: '#224B69',
	fontWeight: 'bold',
	'&:hover': {
		bgcolor: 'secondary.lighter',
		color: 'primary.darker'
	},
}

export const menuIconStyle = {
	color: '#224B69',
	'&:hover': {
		backgroundColor: '#224B69',
		color: '#FFFFFF',
	},
}

const Header = (props) => {
	const { t } = useTranslation(NAVIGATION)
	const [mobileOpen, setMobileOpen] = React.useState(false);
	
	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};
	const navItems = useMemo(() => [
		{
			id: 1,
			url: getRouteUrl(ROUTE_PAGE_HOME),
			title: t("Home"),
			permissions: []
		},
		{
			id: 2,
			url: getRouteUrl(ROUTE_PAGE_USERS_INDEX),
			title: t("Users"),
			permissions: [access_user]
		},
		{
			id: 3,
			url: getRouteUrl(ROUTE_PAGE_RIGHTS_INDEX),
			title: t("Rights"),
			permissions: [access_rights]
		},
		{
			id: 4,
			url: getRouteUrl(ROUTE_PAGE_SECURITY_ROLES_INDEX),
			title: t("Roles"),
			permissions: [access_roles]
		},
		{
			id: 5,
			url: getRouteUrl(ROUTE_ANNOUNCEMENT_INDEX),
			title: t("Announcements"),
			permissions: [access_announcements]
		}
		
	], [t])
	
	return (
		<Box>
			<AppBar
				component="nav"
				elevation={0}
				sx={{ bgcolor: '#FFFFFF', minHeight: 73, justifyContent: 'center' }}
			>
				<Toolbar
					variant={'dense'}
					sx={{ justifyContent: 'space-between' }}
				>
					
					<Box>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{mr: 'auto', display: {md: 'none'}}}
						>
							<MenuIcon color={'primary'}/>
						</IconButton>
						<Box
							sx={{
								display: {xs: 'none', sm: 'none', md: 'block'},
								marginRight: 1
							}}
						>
							<Logo
								auth
								style={{
									maxWidth: '130px',
									minHeight: '50px'
								}}
							/>
						</Box>
					</Box>
					<Box>
						<MainMenu navItems={navItems}/>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<LanguageSelect/>
						<AccountMenu/>
					</Box>
				</Toolbar>
			</AppBar>
			<Toolbar/>
			<AppDrawer
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
				navItems={navItems}
			/>
		</Box>
	);
}

export default Header;
