import { routeCreator } from "../../../helpers/routing/routeCreator";
import Details from "../pages/details/details";
import Index from '../pages/index'

export const ROUTE_PAGE_CONTRACTOR_ROUTES_INDEX = routeCreator(Index, '/routes', 'Routes', 'page.contractors', {exact: true})
export const ROUTE_PAGE_CONTRACTOR_ROUTES_DETAILS = routeCreator(Details, '/routeDetails', 'Routes', 'page.contractors', {exact: true})

const router = {
    ROUTE_PAGE_CONTRACTOR_ROUTES_INDEX,
    ROUTE_PAGE_CONTRACTOR_ROUTES_DETAILS

}

export default router