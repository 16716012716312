import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/redux/requestCreator'
import {API, PortalToken} from "../helpers/constants";

const checkAPIConnectivity = requestCreator({
	nonApiUrl: true,
	url: API + '/portcheck.txt?t=' + (new Date()).getTime(),
	method: 'GET',
	onSuccess: Actions.checkAPIConnectivitySucceeded,
	onFailure: Actions.checkAPIConnectivityFailed
})

// User --------------------------------------------------------
const userLogout = requestCreator({
	url: '/logout',
	method: 'GET',
	onSuccess: Actions.userLogoutSucceeded,
	onFailure: Actions.userLogoutFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		// 'TokenGuid': PortalToken
	}
})

const userLogin = requestCreator({
	url: '/login',
	method: 'POST',
	onSuccess: Actions.userLoginSucceeded,
	onFailure: Actions.userLoginFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		// 'TokenGuid': PortalToken
	}
})

const requestResetPassword = requestCreator({
	url: '/request/new-password',
	method: 'GET',
	onSuccess: Actions.requestResetPasswordSucceeded,
	onFailure: Actions.requestResetPasswordFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		// 'TokenGuid': PortalToken
	}
})

const resetPassword = requestCreator({
	url: '/store/new/password',
	method: 'POST',
	onSuccess: Actions.resetPasswordSucceeded,
	onFailure: Actions.resetPasswordFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		// 'TokenGuid': PortalToken
	}
})

const getUserDetails = requestCreator({
	url: '/portal/user/details',
	method: 'GET',
	onSuccess: Actions.getUserDetailsSucceeded,
	onFailure: Actions.getUserDetailsFailed,
})

const loginToITYEApi = requestCreator({
	nonApiUrl: true,
	url: 'https://api-devtransport-pde.cti.gr/api/openapi/login',
	method: 'POST',
	onSuccess: Actions.loginToITYEApiSucceeded,
	onFailure: Actions.loginToITYEApiFailed,
})

const requestHandler = {
	[ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
	// User -----------------------------------------------
	[ActionTypes.USER_LOGOUT]: userLogout,
	[ActionTypes.USER_LOGIN]: userLogin,
	[ActionTypes.REQUEST_RESET_PASSWORD]: requestResetPassword,
	[ActionTypes.RESET_PASSWORD]: resetPassword,
	[ActionTypes.GET_USER_DETAILS]: getUserDetails,
	[ActionTypes.LOGIN_TO_ITYE_API]: loginToITYEApi,
}

export default requestHandler
