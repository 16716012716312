
import { routeCreator } from "../../../helpers/routing/routeCreator";
import RolesDetails from "../components/RolesDetails";
import UsersSecurityRoles from "../components/UsersSecurityRoles";



export const ROUTE_PAGE_SECURITY_ROLES_INDEX = routeCreator(UsersSecurityRoles, '/security_roles', 'SecurityRoles', 'page.roles', {exact: true})
export const ROUTE_PAGE_SECURITY_ROLES_DETAILS = routeCreator(RolesDetails, '/roles/details/:id', 'SecurityRoles', 'page.roles', {exact: true})


const router = {
    ROUTE_PAGE_SECURITY_ROLES_INDEX,
    ROUTE_PAGE_SECURITY_ROLES_DETAILS
    
}

export default router