// @flow
import { ButtonGroup } from '@mui/material'
import React from 'react'

type Props = {};

const PageMenu = (props: Props) => {
    return (
        <ButtonGroup
            variant="contained"
            size={'small'}
            aria-label="Basic button group"
        >
            {props.children}
        </ButtonGroup>
    )
}

export default PageMenu