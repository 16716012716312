import {actionCreator} from "../../helpers/redux/actionCreator";
import * as ActionTypes from "./types";

export const setUserLanguage = actionCreator(ActionTypes.SET_USER_LANGUAGE)
export const setUserMessage = actionCreator(ActionTypes.SET_USER_MESSAGE)
export const setResetPassword = actionCreator(ActionTypes.SET_RESET_PASSWORD)

export const checkAPIConnectivity = actionCreator(ActionTypes.CHECK_API_CONNECTIVITY)
export const checkAPIConnectivitySucceeded = actionCreator(ActionTypes.CHECK_API_CONNECTIVITY_SUCCEEDED)
export const checkAPIConnectivityFailed = actionCreator(ActionTypes.CHECK_API_CONNECTIVITY_FAILED)

export const userLogout = actionCreator(ActionTypes.USER_LOGOUT)
export const userLogoutSucceeded = actionCreator(ActionTypes.USER_LOGOUT_SUCCEEDED)
export const userLogoutFailed = actionCreator(ActionTypes.USER_LOGOUT_FAILED)

export const userLogin = actionCreator(ActionTypes.USER_LOGIN)
export const userLoginSucceeded = actionCreator(ActionTypes.USER_LOGIN_SUCCEEDED)
export const userLoginFailed = actionCreator(ActionTypes.USER_LOGIN_FAILED)

export const requestResetPassword = actionCreator(ActionTypes.REQUEST_RESET_PASSWORD)
export const requestResetPasswordSucceeded = actionCreator(ActionTypes.REQUEST_RESET_PASSWORD_SUCCEEDED)
export const requestResetPasswordFailed = actionCreator(ActionTypes.REQUEST_RESET_PASSWORD_FAILED)

export const resetPassword = actionCreator(ActionTypes.RESET_PASSWORD)
export const resetPasswordSucceeded = actionCreator(ActionTypes.RESET_PASSWORD_SUCCEEDED)
export const resetPasswordFailed = actionCreator(ActionTypes.RESET_PASSWORD_FAILED)

export const getUserDetails = actionCreator(ActionTypes.GET_USER_DETAILS);
export const getUserDetailsSucceeded = actionCreator(ActionTypes.GET_USER_DETAILS_SUCCEEDED);
export const getUserDetailsFailed = actionCreator(ActionTypes.GET_USER_DETAILS_FAILED);

export const loginToITYEApi = actionCreator(ActionTypes.LOGIN_TO_ITYE_API)
export const loginToITYEApiSucceeded = actionCreator(ActionTypes.LOGIN_TO_ITYE_API_SUCCEEDED)
export const loginToITYEApiFailed = actionCreator(ActionTypes.LOGIN_TO_ITYE_API_FAILED)