// @flow
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import React from 'react';
import { useTranslation } from "react-i18next";
import { normalizeGreek } from "../../../../../helpers/calculations/normalizeGreek";
import { EDITABLE_MUI_GRID } from "../../../../../locales/components/namespaces";

type Props = {
    addNewRow: Function
};

const EditGridToolBar = (props: Props) => {
    const {handleAddNewRow, disableAddingNewRow} = props
    const {t} = useTranslation(EDITABLE_MUI_GRID)
    return (
        <GridToolbarContainer>
            <Button
                disabled={disableAddingNewRow}
                color="primary"
                startIcon={<AddIcon/>}
                onClick={handleAddNewRow}
            >
                {normalizeGreek(t('Add'))}
            </Button>
        </GridToolbarContainer>
    );
};

export default EditGridToolBar