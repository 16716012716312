// ==============================|| OVERRIDES - DATE CALENDAR||============================== //
export default function DateCalendar(theme) {
	return {
		MuiDateCalendar: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
