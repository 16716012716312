// ==============================|| OVERRIDES - TABLE FOOTER ||============================== //

export default function TableFooter(theme) {
	return {
		MuiTableFooter: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
