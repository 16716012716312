export const access_user = 'a484b20b-34ba-4a6d-b451-446b89c155d4'
export const access_rights = '6da858c1-2310-47cb-b6d5-35c2b896b685'
export const access_roles = 'fa758dd4-eee7-4f01-a7b4-950f180c2a40'
export const access_announcements = '44ed415b-765d-4f55-9224-5cf73d2d980c'
export const access_news = 'ce68d22d-60ac-4722-a210-b1204b4bb128'
export const view_parent_dashboard = 'ffcf5e72-adf3-44f9-8d62-17dfd7efd98f'
export const view_contractor_dashboard = '435d1e24-d53b-485e-8007-b841ed7982a8'
export const view_admin_dashboard = '27c683dd-ab0d-474a-933a-5535e0eacf0d'
export const view_faqs = 'cb2f116e-a961-45da-ae3d-d95ff5d32bfc'

