import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.SECURITYROLES]: {
		el: {
			'Actions':'Ενέργειες',
			'Add' : 'Προσθήκη',
			'Description' : 'Περιγραφή',
			'Active' : 'Ενεργός',
			'Security Roles' : 'Ρόλοι ασφαλείας',
			'Required' : 'Απαιτείται',
			'Add Role Rights' : 'Προσθήκη Δικαιωμάτων',
			'Right' : 'Δικαίωμα',
			'View' : 'Εμφάνιση',
			'Edit' : 'Επεξεργασία',
			'Right updated successfully!' : 'Το δικαίωμα ενημερώθηκε με επιτυχία',
			'Failed to update right!' : 'Αποτυχία ενημέρωσης δικαιώματος',
			'Security Role created successfully!' : 'Ο ρόλος ασφαλείας δημιουργήθηκε με επιτυχία!',
			'Security Role deleted successfully!' : 'Ο ρόλος ασφαλείας διαγράφηκε με επιτυχία',
			'Security Role updated successfully!' : 'Ο ρόλος ασφαλείας ενημερώθηκε με επιτυχία',
			'All data related to this security role will be deleted!' : 'Όλα τα δεδομένα που σχετίζονται με αυτό το ρόλο ασφαλείας θα διαγραφούν!',
			'Unable to delete security role!' : 'Αδυναμία διαγραφής ρόλου ασφαλείας!',
			'An error occurred!' : 'Παρουσιάστηκε σφάλμα!',
			'Roles and Users' : 'Ρόλοι και Χρήστες',
			'Add Rights in ' : 'Προσθήκη Δικαιώματος σε '
			
		},
		en: {
		
		}
	},
}

export default locales