import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import useUserData from "../../../hooks/user/useUserData";
import { ANNOUNCEMENTS } from "../locales/namespaces";
import { ROUTE_ANNOUNCEMENT_INDEX, ROUTE_ANNOUNCEMENT_NEWS } from "../routes/routes";


const useAnnouncementForm = ({id, announcement, staticData}) => {
	const {t} = useTranslation(ANNOUNCEMENTS)
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const {token} = useUserData()
	const history = useHistory()
	
	
	const onSubmit = (values) => {
		console.log('values',values)
		setLoadingSubmit(true)
		axios({
			method: 'post',
			data: {
				DtFrom: dayjs(values.DtFrom).format('YYYY-MM-DD'),
				SendEmail: values.SendEmail,
				Title: values.Title,
				body: values.body,
				expirationDate: dayjs(values.expirationDate).format('YYYY-MM-DD'),
				isImportant: values.isImportant,
				typeId: values.typeId,

			},
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: !id ? `${API}/en/messages/store` : `${API}/en/messages/update/${id}`
		}).then(response => {
			setLoadingSubmit(false)
			enqueueSnackbar(t(`Announcement ${!id ? 'created' : 'updated'} successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			history.push(getRouteUrl(ROUTE_ANNOUNCEMENT_INDEX))
		
		}).catch((error) => {
			setLoadingSubmit(false)
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const validationSchema = yup.object().shape({
		typeId: yup.string().required(t('Required')),
		Title: yup.string().required(t('Required')),
		
	})
	
	const formik = useFormik({
		initialValues: {
			isImportant: id ? announcement?.isImportant === '1' : true,
			SendEmail: id ? announcement?.SendEmail : false,
			DtFrom: id ? dayjs(announcement?.DtFrom): dayjs(),
			expirationDate: id? dayjs(announcement?.expirationDate) : dayjs(),
			typeId: id && announcement?.typeId ?  announcement?.typeId : '',
			Title : id && announcement?.Title ? announcement.Title : '' ,
			body : id && announcement?.body ? announcement.body : ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		// enableReinitialize: true
	})
	
	
	return {
		formik,
		loadingSubmit
	}
};

export default useAnnouncementForm