import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import { ANNOUNCEMENTS } from "../../../modules/announcements/locales/namespaces";

const useAnnouncementNumber = (props) => {
	const [count, setCount] = useState(0)
	const { t } = useTranslation(ANNOUNCEMENTS)
	const {token} = useUserData()
	
	const getNotificationCount = useCallback(() => {
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/messages/data?count=1`,
		}).then(response => {
			if(response?.data?.data?.length > 0){
				setCount(response.data.data[0]?.count)
			}
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [t, token])
	
	useEffect(() => {
		getNotificationCount()
		// Set up an interval to fetch notifications every 1 minute (adjust as needed)
		const intervalId = setInterval(() => {
			getNotificationCount()
		}, 600000); // 10 minute in milliseconds
		// Cleanup the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, [getNotificationCount, t, token]);
	return {
		count
	}
};

export default useAnnouncementNumber