import { NavigateBefore } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers-pro";
import axios from "axios";
import dayjs from "dayjs";
import { ErrorMessage, Field, FormikProvider } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import Editor from "../../../components/custom/form/editor/editor";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import SkeletonLoaderWrapper from "../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { textFieldDefaults, textFieldSelectDefaults } from "../../../helpers/forms/defaultProps";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import { formControlDefaults } from "../../../helpers/theme/defaultProps";
import useUserData from "../../../hooks/user/useUserData";
import useAnnouncementForm from "../hooks/useAnnouncementForm";
import { ANNOUNCEMENTS } from "../locales/namespaces";
import { ROUTE_ANNOUNCEMENT_NEWS } from "../routes/routes";

const Form = (props) => {
	const { id, announcement, staticData } = props;
	// debugger
	
	const { t } = useTranslation(ANNOUNCEMENTS)
	const { formik, loadingSubmit } = useAnnouncementForm({ id, announcement, staticData })
	
	const handleDate = (newValue, name) => {
		formik.setFieldValue(name, newValue !== 'Invalid date' ? newValue : null)
	}
	
	return (
		
		<FormikProvider value={formik}>
			<form
				onSubmit={formik.handleSubmit}
				autoComplete="off"
				id="userForm"
				encType="multipart/form-data"
				style={{ marginTop: '10px' }}
			>
				<Box display={'flex'}
					 flexWrap={'wrap'}
					 justifyContent={'space-between'}
					 gap={1}>
					<PageMenu>
						<Button
							component={Link}
							startIcon={<NavigateBefore/>}
							to={`/announcements`}
						>
							{t('Return')}
						</Button>
					</PageMenu>
				</Box>
				<PageContentWrapper>
					<PageTitle
						withDivider
						title={!id ? t('Announcement Create') : t('Announcement Edit')}
					/>
					<Grid
						container
						spacing={2}
						sx={{ marginLeft: '0px' }}
					>
						<Grid size={2}>
							<FormControl
								{...formControlDefaults}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values.isImportant}
											onChange={formik.handleChange}
											name="isImportant"
											onBlur={formik.handleBlur}
										/>
									}
									label={<FormLabel>{t('Important')}</FormLabel>}
								/>
							</FormControl>
						</Grid>
						<Grid size={2}>
							<FormControl
								{...formControlDefaults}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values.SendEmail}
											onChange={formik.handleChange}
											name="SendEmail"
											onBlur={formik.handleBlur}
										/>
									}
									label={<FormLabel>{t('Send e-mail')}</FormLabel>}
								/>
							</FormControl>
						</Grid>
						<Grid size={8}>
						
						</Grid>
						<Grid size={3}>
							<FormControl
								{...formControlDefaults}
							>
								<FormLabel>{t('From Date')}</FormLabel>
								<DatePicker
									renderInput={(params) =>
										<TextField
											{...params}
											placeholder={t('From Date')}
											variant={'standard'}
										/>}
									slotProps={{
										field: {
											clearable: true,
										},
										textField: { variant: 'standard', placeholder: t('From Date') }
									}}
									name="DtFrom"
									views={['year', 'month', 'day']}
									format={'DD/MM/YYYY'}
									// value={formik.values.DtFrom}
									// value={dayjs(formik.values.DtFrom, 'DD/MM/YYYY')}
									value={formik.values.DtFrom}
									onChange={(newValue) => handleDate(newValue, 'DtFrom')}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									<ErrorMessage name={'DtFrom'}/>
								</FormHelperText>
							</FormControl>
						</Grid>
						
						<Grid size={3}>
							<FormControl
								{...formControlDefaults}
							>
								<FormLabel>{t('expirationDate')}</FormLabel>
								<DatePicker
									renderInput={(params) =>
										<TextField
											{...params}
											placeholder={t('expirationDate')}
											variant={'standard'}
										/>}
									slotProps={{
										field: {
											clearable: true,
										},
										textField: { variant: 'standard', placeholder: t('expirationDate') }
									}}
									name="expirationDate"
									views={['year', 'month', 'day']}
									format={'DD/MM/YYYY'}
									value={formik.values.expirationDate}
									onChange={(newValue) => handleDate(newValue, 'expirationDate')}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									<ErrorMessage name={'expirationDate'}/>
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid size={6}>
						
						</Grid>
						<Grid size={6}>
							<FormControl
								{...formControlDefaults}
							>
								<FormLabel required>{t('Type')}</FormLabel>
								<TextField
									{...textFieldSelectDefaults}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.typeId}
									name={'typeId'}
								>
									<MenuItem
										value={''}
									/>
									{staticData?.map((type) => (
										<MenuItem
											key={type.id}
											value={type.id}
										>
											{type.Description}
										</MenuItem>
									))}
								</TextField>
								<FormHelperText>
									<ErrorMessage name={'typeId'}/>
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid size={6}>
						</Grid>
						<Grid size={6}>
							<FormControl
								{...formControlDefaults}
							>
								<FormLabel required>{t('Title')}</FormLabel>
								<TextField
									{...textFieldDefaults}
									name={'Title'}
									value={formik.values.Title}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									slotProps={{
										input: {
											maxLength: 250,
										}
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'Title'}/>
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid size={12}>
							<Field
								id={'body'}
								name={'body'}
								component={Editor}
								value={formik.values.body}
							/>
						</Grid>
						<Grid size={12} sx={{ textAlign: 'right' }}>
							<LoadingButton
								//loading={loadingSubmit}
								type={'Submit'}
								// onClick={formik.submitForm}
								variant="contained"
								startIcon={<SaveIcon/>}
							>
								{t('Save')}
							</LoadingButton>
						</Grid>
					</Grid>
				</PageContentWrapper>
			</form>
		</FormikProvider>
	)
		;
};

export default Form