import { routeCreator } from "../../../helpers/routing/routeCreator";
import AnnouncementsDetails from "../pages/announcementsDetails";
import announcementsIndex from "../pages/announcementsIndex";
import Create from "../pages/Create/Create";
import NewsDetails from "../pages/newsDetails";
import News from "../pages/news";




export const ROUTE_ANNOUNCEMENT_CREATE = routeCreator(Create, '/announcement/create', 'Announcements', 'page.announcements', {exact: true})
export const ROUTE_ANNOUNCEMENT_EDIT = routeCreator(Create, '/announcement/edit/:id', 'Announcements', 'page.announcements', {exact: true})
export const ROUTE_ANNOUNCEMENT_NEWS = routeCreator(News, '/news', 'Announcements', 'page.announcements', {exact: true})
export const ROUTE_ANNOUNCEMENT_INDEX = routeCreator(announcementsIndex, '/announcements', 'Announcements', 'page.announcements', {exact: true})

export const ROUTE_NEWS_DETAILS = routeCreator(NewsDetails, '/new/details/:id', 'Announcements', 'page.announcements', {exact: true})
export const ROUTE_ANNOUNCEMENTS_DETAILS = routeCreator(AnnouncementsDetails, '/announcements/details/:id', 'Announcements', 'page.announcements', {exact: true})

const router = {
    ROUTE_ANNOUNCEMENT_CREATE,
    ROUTE_ANNOUNCEMENT_NEWS,
    ROUTE_ANNOUNCEMENT_EDIT,
    ROUTE_ANNOUNCEMENT_INDEX,
    ROUTE_NEWS_DETAILS,
    ROUTE_ANNOUNCEMENTS_DETAILS
}

export default router