// ==============================|| OVERRIDES - DIALOG TITLE ||============================== //
export default function DialogTitle(theme) {
	return {
		MuiDialogTitle: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
