//@flow
import { FormControl } from "@mui/material";
import React, { useState } from 'react'
import 'tinymce/tinymce'
import { Editor } from '@tinymce/tinymce-react'
import 'tinymce/plugins/code'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/table'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/print'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import './el'
import './editor.css'

// Content styles, including inline UI like fake cursors
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css'
// import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'

type Props = {
  label?: string,
  field: {
    name: string,
  },
  form: {
    // touched: boolean,
    // errors: Object,
    handleChange?: Function,
    handleBlur?: Function,
    initialValues: Object
  }
};

const imageUploadHandler = (blobInfo, success, failure, progress) => {
  var blobToBase64 = function (blob, callback) {
    var reader = new FileReader()
    reader.onload = function () {
      var dataUrl = reader.result
      var base64 = dataUrl.split(',')[1]
      callback(base64)
    }
    reader.readAsDataURL(blob)
  }
  blobToBase64(blobInfo.blob(), (data) => success('data:image/png;base64, ' + data))
}

const WysiwygEditor = (props: Props) => {
  const {
    label,
    field,
    form: {
      touched, errors,
      handleChange, handleBlur, initialValues
    },
  } = props
  const [elValue, setElValue] = useState('')
  return (
    <FormControl
      style={{ height: '100%' }}
      isTouched={touched && touched[field.name]}
      error={errors && errors[field.name]}
      label={label}
    >
      <Editor
        {...field}
        init={{
          skin: false,
          content_css: false,
          // content_style: [contentCss, contentUiCss].join('\n'),
          height: '100%',
          menubar: false,
          plugins: ['advlist lists table link autolink fullscreen image imagetools paste print quickbars code'],
          language: 'el',
          file_picker_types: 'file image media',
          quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
          paste_as_text: true,
          toolbar:
            'formatselect| bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |  link image table | paste print code fullscreen',
          images_upload_handler: imageUploadHandler,
        }}
        onInit={() => {
          setElValue(initialValues[field.name])
        }}
        initialValue={initialValues[field.name]}
        onEditorChange={(value) => {
          setElValue(value)
        }}
        textareaName={field.name}
        onBlur={(e) => {
          if (handleChange) {
            handleChange({ target: { name: field.name, value: elValue } })
          }
          if (handleBlur) {
            handleBlur(e)
          }
        }}
        value={elValue}
      />
      {/*<span>{touched[field.name] && errors[field.name]}</span>*/}
    </FormControl>
  )
}

export default WysiwygEditor