// ==============================|| OVERRIDES - TABLE CONTAINER ||============================== //

export default function TableContainer(theme) {
	return {
		MuiTableContainer: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
