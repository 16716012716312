// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme) {
	return {
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: '0.8rem',
					'&.Mui-selected': {
						color: theme.palette.secondary.light,
					},
				}
			}
		}
	}
}
