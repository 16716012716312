// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell (theme) {
	return {
		MuiTableCell: {
			styleOverrides: {
				root: {
					paddingLeft: 12,
					paddingRight: 12,
					paddingTop: 1,
					paddingBottom: 1,
					borderColor: theme.palette.primary[100]
				}
			}
		}
	}
}
