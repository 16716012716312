// @flow
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Logo from "../../components/custom/general/logo/logo";
import { PAGE_LOGIN } from "../../locales/pages/namespaces";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Form from "../../components/app/forms/login/form";
import LanguageSelect from "../../components/app/languageSelect/languageSelect";
import ForgotPassword from "../../components/app/dialogs/forgotPassword/forgotPassword";

import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";

import useLogin from "../../hooks/login/useLogin";
import './login.css'

const Login = () => {
	const { t } = useTranslation(PAGE_LOGIN)
	const { setUserMessage, checkAPIConnectivity } = useUserAction()
	const { loading, message, isLoggedIn } = useUserData()
	const { formik } = useLogin()
	const [openForgotPass, setOpenForgotPass] = useState(false)
	
	useEffect(() => {
		// if (!isLoggedIn) checkAPIConnectivity()
	}, [ isLoggedIn])
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
				autoHideDuration: null
				}
			)
			setUserMessage(null)
		}
	}, [message, setUserMessage, t])
	
	return (
		<Box
			className={"login"}
			bgcolor={'background.default'}
		>
			<Box
				className={'login-box'}
				bgcolor={'#f0eee1'}
			>
				<Box p={1}>
					<LanguageSelect/>
				</Box>
				<Logo
					style={{
						paddingTop: '1rem',
						maxHeight: '140px',
						width: 'auto'
					}}
				/>
				<Box>
					<Box pb={2}>
						<Typography
							variant="h1"
							component={'div'}
							color={"secondary.main"}
							sx={{ letterSpacing: 1.6, textAlign: 'center', textShadow: '0px 6px 4px rgba(0,0,0,0.2)' }}
						>
							{t("Student Portal")}
						</Typography>
					</Box>
					<Form
						formik={formik}
						loading={loading}
					/>
				</Box>
				<Box>
					<Typography
						component={Button}
						onClick={() => setOpenForgotPass(true)}
						variant="body1"
						color={'secondary'}
					>
						{t("Forgot password? Click here.")}
					</Typography>
				</Box>
			</Box>
			<ForgotPassword
				open={openForgotPass}
				handleDialog={(newValue) => setOpenForgotPass(newValue)}
			/>
		</Box>
	)
}

export default Login