// ==============================|| OVERRIDES - ACCORDION DETAILS ||============================== //

export default function AccordionDetails(theme) {
	return {
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1)
				}
			}
		}
	}
}
