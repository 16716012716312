import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import { ANNOUNCEMENTS } from "../locales/namespaces";

const useAnnouncement = ({ id }) => {
	const { t } = useTranslation(ANNOUNCEMENTS)
	const [staticData, setStaticData] = useState();
	const { token } = useUserData()
	const [loading, setLoading] = useState(true)
	const [announcement, setAnnouncement] = useState()
	
	useEffect(() => {
		if (id) {
			setLoading(true);
			axios({
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
				url: `${API}/en/messages/details/` + id
			}).then(response => {
				setAnnouncement(response.data.data.message)
				setLoading(false)
			}).catch((error) => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
				setLoading(false)
			})
		}
		setLoading(true);
		axios({
			method: 'get',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/messages/createData`
		}).then(response => {
			setStaticData(response.data.Types)
			setLoading(false);
			
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [id, t, token]);
	
	return {
		loading,
		staticData,
		announcement
	}
};

export default useAnnouncement