// ==============================|| OVERRIDES - TOOLBAR ||============================== //

export default function Toolbar (theme) {
	return {
		MuiToolbar: {
			styleOverrides: {
				root: {
					width: '100%',
					justifyContent: 'space-between'
				},
				selectLabel: {
				},
				displayedRows: {
				},
			},
		}
	}
}
