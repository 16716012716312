// ==============================|| OVERRIDES - NATIVE SELECT ||============================== //

export default function NativeSelect (theme) {
    return {
        MuiNativeSelect: {
            styleOverrides: {
                root: {
                    // fontSize: 'revert'
                }
            }
        }
    }
}
