export default function FormControlLabel (theme) {
	return {
		MuiFormControlLabel: {
			defaultProps: {
				disableTypography: true
			},
			styleOverrides: {
				root: {
					margin: 0
				}
			}
		}
	}
}