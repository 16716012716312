import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { useMuiGridServerSide } from "../../../hooks/muiGrid/useMuiGridServerSide";
import { SECURITYROLES } from "../locales/namespaces";

const useUsersSecurityRoles = (props) => {
	const { t } = useTranslation(SECURITYROLES);
	const [load, setLoad] = useState(false)
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url: 'parameters/security/roles/index',
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	const store = (newRow) => {
		axios.post(`${API}/en/parameters/security/roles/store`, { newRow }).then(res => {
			enqueueSnackbar(t(`Security Role created successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const remove = (row) => {
		console.log(row)
		axios.post(`${API}/en/parameters/security/roles/remove`, { RowId: row }).then(res => {
			enqueueSnackbar(t(`Security Role deleted successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const update = (row) => {
		axios.post(`${API}/en/parameters/security/roles/update`, { RowId: row }).then(res => {
			enqueueSnackbar(t(`Security Role updated successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	return {
		data,
		isLoading,
		refreshGrid,
		load,
		store,
		remove,
		update,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
	}
};

export default useUsersSecurityRoles