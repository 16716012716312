import { actionCreator } from "../../helpers/redux/actionCreator";
import * as ActionTypes from '../types'

export const getGridState = actionCreator(ActionTypes.GET_GRID_STATE)
export const getGridStateSucceeded = actionCreator(ActionTypes.GET_GRID_STATE_SUCCEEDED)
export const getGridStateFailed = actionCreator(ActionTypes.GET_GRID_STATE_FAILED)

export const saveGridState = actionCreator(ActionTypes.SAVE_GRID_STATE)
export const saveGridStateSucceeded = actionCreator(ActionTypes.SAVE_GRID_STATE_SUCCEEDED)
export const saveGridStateFailed = actionCreator(ActionTypes.SAVE_GRID_STATE_FAILED)
export const setGridStateMessage = actionCreator(ActionTypes.SET_GRID_STATE_MESSAGE)
export const deleteGridState = actionCreator(ActionTypes.DELETE_GRID_STATE)
export const deleteGridStateSucceeded = actionCreator(ActionTypes.DELETE_GRID_STATE_SUCCEEDED)
export const deleteGridStateFailed = actionCreator(ActionTypes.DELETE_GRID_STATE_FAILED)
export const setActiveGridState = actionCreator(ActionTypes.SET_ACTIVE_GRID_STATE)
export const setActiveGridStateSucceeded = actionCreator(ActionTypes.SET_ACTIVE_GRID_STATE_SUCCEEDED)
export const setActiveGridStateFailed = actionCreator(ActionTypes.SET_ACTIVE_GRID_STATE_FAILED)
export const setNewGridStateLabel = actionCreator(ActionTypes.SET_NEW_GRID_STATE_LABEL)
export const toggleGridPopper = actionCreator(ActionTypes.TOGGLE_GRID_POPPER)