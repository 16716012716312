// ==============================|| OVERRIDES - TABLE BODY ||============================== //

export default function TableBody(theme) {
	return {
		MuiTableBody: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
