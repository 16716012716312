// ==============================|| OVERRIDES - PAGINATION ||============================== //

export default function Pagination (theme) {
    return {
        MuiPagination: {
            defaultProps: {
                // shape: 'rounded'
            }
        }
    }
}
