// ==============================|| OVERRIDES - TEXT FIELD ||============================== //

export default function TextField(theme) {
	return {
		MuiInputBase: {
			styleOverrides: {
				root: {
					input: {
						fontSize: '0.9rem',
						backgroundColor: 'transparent',
						borderRadius: 0,
						color: '#000000',
						fontWeight: 'normal',
						lineHeight: '1.25rem',
						textAlign: 'left',
						margin: 0,
						padding: 0
					}
				}
			}
		}
	}
}