export const GET_GRID_STATE = 'GET_GRID_STATE'
export const GET_GRID_STATE_SUCCEEDED = 'GET_GRID_STATE_SUCCEEDED'
export const GET_GRID_STATE_FAILED = 'GET_GRID_STATE_FAILED'
export const SAVE_GRID_STATE = 'SAVE_GRID_STATE'
export const SAVE_GRID_STATE_SUCCEEDED = 'SAVE_GRID_STATE_SUCCEEDED'
export const SAVE_GRID_STATE_FAILED = 'SAVE_GRID_STATE_FAILED'
export const SET_GRID_STATE_MESSAGE = 'SET_GRID_STATE_MESSAGE'
export const DELETE_GRID_STATE = 'DELETE_GRID_STATE'
export const DELETE_GRID_STATE_SUCCEEDED = 'DELETE_GRID_STATE_SUCCEEDED'
export const DELETE_GRID_STATE_FAILED = 'DELETE_GRID_STATE_FAILED'
export const SET_ACTIVE_GRID_STATE = 'SET_ACTIVE_GRID_STATE'
export const SET_ACTIVE_GRID_STATE_SUCCEEDED = 'SET_ACTIVE_GRID_STATE_SUCCEEDED'
export const SET_ACTIVE_GRID_STATE_FAILED = 'SET_ACTIVE_GRID_STATE_FAILED'
export const SET_NEW_GRID_STATE_LABEL = 'SET_NEW_GRID_STATE_LABEL'
export const TOGGLE_GRID_POPPER = 'TOGGLE_GRID_POPPER'