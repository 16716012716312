// @flow
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import Grid from "@mui/material/Grid2";

type Props = {
	label: string,
	sx?: Object,
	highLightValue?: boolean
};

const QuickViewInfoWrap = (props: Props) => {
	const { label, sx = {}, highLightValue = false } = props
	const theme = useTheme()
	const borderColor = theme.palette.primary[100]
	return (
		<Box>
			<Grid
				container
				rowSpacing={1}
				mt={0.5}
				columnGap={1}
			>
				
				<Grid size={{ xs: 12, sm: 6, md: 5, lg: 4 }}
					  sx={{ maxWidth: '200px', wordWrap: 'break-word', minWidth: '80px' }}>
					<Typography
						variant={'subtitle2'}
						component={'div'}
						sx={{ fontWeight: 'bold' }}
					>
						{label}
					</Typography>
				</Grid>
				
				<Grid size={{ xs: 12, sm: 6, md: 7, lg: 8 }} sx={{alignSelf:'end'}}>
					<Typography
						variant={'body2'}
						component={'div'}
						sx={{
							...(highLightValue && { borderBottom: `1px solid ${borderColor}` }),
							...sx
						}}
					>
						{props.children}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}

export default QuickViewInfoWrap