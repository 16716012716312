// material-ui
import { alpha } from '@mui/material/styles';

// project import
import getColors from '../../helpers/theme/getColors'
import getShadow from '../../helpers/theme/getShadow'

// ==============================|| BUTTON - COLORS ||============================== //

function getColorStyle({ variant, color, theme }) {
	const colors = getColors(theme, color);
	const { lighter, main, dark, darker, contrastText } = colors;
	const buttonShadow = `${color}Button`;
	const shadows = getShadow(theme, buttonShadow);
	
	const commonShadow = {
		'&::after': {
			boxShadow: `0 0 5px 5px ${alpha(main, 0.9)}`
		},
		'&:active::after': {
			boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`
		}
	};
	
	switch (variant) {
		case 'contained':
			return {
				color: theme.palette.buttonColors.containedColor,
				background: theme.palette.buttonColors.containedBackground,
				'&:hover': {
					backgroundColor: theme.palette.buttonColors.containedHoverBackground,
					color: theme.palette.buttonColors.containedHoverColor,
					boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20) inset'
				},
				...commonShadow
			};
		case 'shadow':
			return {
				color: contrastText,
				backgroundColor: main,
				boxShadow: shadows,
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: dark
				},
				...commonShadow
			};
		case 'outlined':
			return {
				color: main,
				backgroundColor: '#fff',
				border: '1px solid',
				'&:hover': {
					color: dark,
					backgroundColor: '#fff',
					border: '2px solid'
					
				},
				...commonShadow
			};
		case 'dashed':
			return {
				color: main,
				borderColor: main,
				backgroundColor: lighter,
				'&:hover': {
					color: dark,
					borderColor: dark
				},
				...commonShadow
			};
		case 'link':
			return {
				color: theme.palette.linkStyles.color,
				padding: '2px 8px',
				'&:hover': {
					color: theme.palette.linkStyles.hoverColor,
					backgroundColor: 'transparent',
					textDecoration: 'underline'
				},
				borderRadius: 0,
				justifyContent: 'start',
				...commonShadow
			};
		case 'text':
		default:
			return {
				color: theme.palette.text.secondary,
				padding: '2px 8px',
				'&:hover': {
					color: darker,
					backgroundColor: lighter,
					
				},
			};
	}
}

// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
	const primaryDashed = getColorStyle({ variant: 'dashed', color: 'primary', theme });
	const primaryShadow = getColorStyle({ variant: 'shadow', color: 'primary', theme });
	const primaryLink = getColorStyle({ variant: 'link', color: 'primary', theme });
	
	const disabledStyle = {
		'&.Mui-disabled': {
			backgroundColor: theme.palette.grey[200],
			color: theme.palette.grey[800]
		}
	};
	
	return {
		MuiButton: {
			defaultProps: {
				// disableElevation: true
			},
			styleOverrides: {
				root: {
					fontWeight: 400,
					fontSize: '0.8rem'
				},
				contained: {
					...disabledStyle
				},
				outlined: {
					'&.Mui-disabled': {
						color: theme.palette.grey[800],
						border: '1px solid',
						opacity: 0.6,
						background: '#FFF'
					}
				},
				text: {
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none'
					},
					'&.Mui-disabled': {
						color: theme.palette.grey[800],
						opacity: 0.6
					}
				},
				// custom variant 'dashed'
				dashed: {
					border: '1px dashed',
					...primaryDashed,
					'&.MuiButton-dashedPrimary': getColorStyle({ variant: 'dashed', color: 'primary', theme }),
					'&.MuiButton-dashedSecondary': getColorStyle({ variant: 'dashed', color: 'secondary', theme }),
					'&.MuiButton-dashedError': getColorStyle({ variant: 'dashed', color: 'error', theme }),
					'&.MuiButton-dashedSuccess': getColorStyle({ variant: 'dashed', color: 'success', theme }),
					'&.MuiButton-dashedInfo': getColorStyle({ variant: 'dashed', color: 'info', theme }),
					'&.MuiButton-dashedWarning': getColorStyle({ variant: 'dashed', color: 'warning', theme }),
					'&.Mui-disabled': {
						color: `${theme.palette.grey[300]} !important`,
						borderColor: `${theme.palette.grey[400]} !important`,
						backgroundColor: `${theme.palette.grey[200]} !important`
					}
				},
				// custom variant 'shadow'
				shadow: {
					...primaryShadow,
					'&.MuiButton-shadowPrimary': getColorStyle({ variant: 'shadow', color: 'primary', theme }),
					'&.MuiButton-shadowSecondary': getColorStyle({ variant: 'shadow', color: 'secondary', theme }),
					'&.MuiButton-shadowError': getColorStyle({ variant: 'shadow', color: 'error', theme }),
					'&.MuiButton-shadowSuccess': getColorStyle({ variant: 'shadow', color: 'success', theme }),
					'&.MuiButton-shadowInfo': getColorStyle({ variant: 'shadow', color: 'info', theme }),
					'&.MuiButton-shadowWarning': getColorStyle({ variant: 'shadow', color: 'warning', theme }),
					'&.Mui-disabled': {
						color: `${theme.palette.grey[300]} !important`,
						borderColor: `${theme.palette.grey[400]} !important`,
						backgroundColor: `${theme.palette.grey[200]} !important`
					}
				},
				// custom variant 'link'
				link: {
					...primaryLink
				},
				containedPrimary: getColorStyle({ variant: 'contained', color: theme.palette.secondary[100], theme }),
				textPrimary: getColorStyle({ variant: 'text', color: 'primary', theme }),
			}
		}
	};
}
