import { NavigateBefore, Warning } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Divider, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import SkeletonLoaderWrapper from "../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { access_announcements, access_news } from "../../../helpers/rights";
import useUserData from "../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { ANNOUNCEMENTS } from "../locales/namespaces";

const NewsDetails = (props) => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [announcement, setAnnouncement] = useState();
	const { t } = useTranslation(ANNOUNCEMENTS)
	const { token } = useUserData()
	
	useEffect(() => {
		if (id) {
			setLoading(true);
			axios({
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
				url: `${API}/en/messages/details/` + id
			}).then(response => {
				setAnnouncement(response.data.data.message)
				console.log(response.data.data.message)
				setLoading(false)
			}).catch((error) => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
				setLoading(false)
			})
		}
	}, []);
	return (
		<SkeletonLoaderWrapper loading={loading}>
			<Box className={'news_details'} sx={{ marginTop: '10px' }}>
				<PageMenu>
					<Button
						component={Link}
						to={'/news'}
						startIcon={<NavigateBefore/>}
					>
						{t('Return')}
					</Button>
				</PageMenu>
				<PageContentWrapper>
					<ListItemText key={id}
								  primary={
									  <>
										  <Typography variant="h6" component={'div'} style={{ color: '#103f91' }}>
											  {announcement?.isImportant === '1' ?
												  <Warning color={"primary"}/> : ''}
											  {announcement?.Title}
										  </Typography>
										  <Typography sx={{ fontSize: '14px' }} style={{ color: '#103f91' }}>
											  {announcement?.DtFrom ? dayjs(announcement?.DtFrom).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')}
										  </Typography>
									  
									  </>
									  
								  }
								  secondary={
									  <>
										  {announcement?.body ?
											  
											  // <Box dangerouslySetInnerHTML={{ __html: item?.body }}/> : ''
											  <Box dangerouslySetInnerHTML={{ __html: announcement?.body }}/> : ''
										  }
									  </>
								  }
					
					>
					</ListItemText>
				</PageContentWrapper>
			</Box>
		</SkeletonLoaderWrapper>
	);
};

// export default NewsDetails
export default withPermissionHOC(NewsDetails, [access_news])