// @flow
import React from 'react';
import Images from "../../../../assets";
import {getRouteUrl} from "../../../../helpers/routing/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useHistory} from "react-router-dom";

type Props = {
	auth?: boolean,
	style?: Object
};

const Logo = (props: Props) => {
	const {
		auth = false,
		style = {}
	} = props
	const history = useHistory()
	return (
		<img
			onClick={() => {
				if (auth) history.push(getRouteUrl(ROUTE_PAGE_HOME))
			}}
			src={Images.logo}
			alt="region logo"
			style={{
				...(auth && {cursor: "pointer"}),
				...style
			}}
		/>
	);
};

export default Logo