import { Edit, Warning } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Divider, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { SanitizeHtmlHelper as DOMPurify } from "@syncfusion/ej2-base";
import axios from "axios";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import BoolImage from "../../../components/custom/general/boolImage/boolImage";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import QuickViewInfoWrap from "../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import ContentLoader from "../../../components/mui/general/contentLoader/contentLoader";
import MainCard from "../../../components/mui/general/mainCard/mainCard";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { access_announcements, access_news } from "../../../helpers/rights";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import useUserData from "../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { ROUTE_PAGE_USERS_DETAILS } from "../../users/routes/routes";
import { ANNOUNCEMENTS } from "../locales/namespaces";
import { Link, useHistory } from 'react-router-dom'
import { ROUTE_ANNOUNCEMENT_CREATE, ROUTE_ANNOUNCEMENT_EDIT, ROUTE_NEWS_DETAILS } from "../routes/routes";

const News = (props) => {
	const { t } = useTranslation(ANNOUNCEMENTS)
	const { token } = useUserData()
	const [loading, setLoading] = useState(true)
	const history = useHistory()
	const [announcementData, setAnnouncementData] = useState([]);
	
	useEffect(() => {
		axios({
			method: 'post',
			// data: { typeId: 1 },
			url: `${API}/en/messages/data`,
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			setAnnouncementData(response.data.data);
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, []);
	
	function truncateText(text, length = 2100 - 3) {
		// return text.length > length ? text.slice(0, length)  : text;
		return text.slice(0, length) + '...';
	}
	
	return (
		<Fragment>
			{loading === false ?
				<Box className={'announcements_index'}
					 sx={{ marginTop: '10px' }}>
					<PageContentWrapper>
						<PageTitle
							title={t('Announcements')}/>
						{announcementData?.length > 0 ?
							<Box className="cardContents">
								<List>
									{announcementData?.map((item) => (
										<ListItemText key={item.id}
													  primary={
														  <>
															  <Box sx={{ display: 'flex' }}>
																  {item?.isImportant === '1' ?
																	  <Warning style={{
																		  color: '#ffa500',
																		  marginRight: '15px'
																	  }}/> : ''}
																  <Typography variant="h6" component={'div'}
																			  style={{ color: '#103f91' }}
																			  onClick={() => history.push(getRouteUrl(ROUTE_NEWS_DETAILS, { id: item.id }))}>
																	  
																	  {item?.Title}
																  </Typography>
															  </Box>
															  <Typography sx={{ fontSize: '14px' }}
																		  style={{
																			  color: '#103f91',
																			  marginLeft: item?.isImportant === '1' ? '35px' : '0px'
																		  }}
															  >
																  {item.DtFrom ? dayjs(item?.DtFrom).format('DD/MM/YYYY') : dayjs()}
															  </Typography>
														  
														  </>
														  
													  }
													  secondary={
														  <>
															  {item.body ?
																  
																  // <Box dangerouslySetInnerHTML={{ __html: item?.body }}/> : ''
																  <Box
																	  dangerouslySetInnerHTML={{ __html: truncateText(item?.body) }}/> : ''
															  }
															  <Box sx={{justifySelf: 'right'}}>
																  <Button style={{ color: '#103f91' }} onClick={() =>
																	  history.push(getRouteUrl(ROUTE_NEWS_DETAILS, { id: item.id }))}>
																	  {t('Show more...')}
																  </Button>
															  </Box>
															  <Divider/>
														  </>
													  }
										
										>
										</ListItemText>
									))}
								</List>
							</Box>
							:
							<Box>
								<Typography sx={{
									fontSize: '20px',
									textAlign: 'center',
									backgroundColor: '#b1e0e5'
								}}>{t('There are not announcements!')}</Typography>
							</Box>
						}
					
					</PageContentWrapper>
				</Box>
				:
				<ContentLoader loading={loading} withBackDrop useCircularLoader message={t('Please wait...')}/>
			}
		</Fragment>
	)
		;
};

// export default News
export default withPermissionHOC(News, [access_news])