import { DataGridPremium, GridRowModes } from '@mui/x-data-grid-premium'
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { muiGridLocales } from "../../../../helpers/mui/muiLocales";
import useUserData from "../../../../hooks/user/useUserData";
import { EDITABLE_MUI_GRID } from "../../../../locales/components/namespaces"
import { CustomPagination } from '../dataGridCustom/customPagination'
import { useMUIDataGridCrudOperations } from "./hooks/useMUIDataGridCrudOperations";
import EditGridToolBar from "./partials/editGridToolBar";
import GridRowEditToolBar from "./partials/gridRowEditToolBar";
import GridRowToolBar from "./partials/gridRowToolBar";
import RowConfirmationDialog from "./partials/rowConfirmationDialog";

const EditableMUIDataGrid = (props) => {
    const {
        initialState,
        loading,
        columns,
        data,
        newRowModel,
        store,
        update,
        remove,
        hideEditButton = false,
        dataGridProOtherProps
    } = props
    const {t} = useTranslation(EDITABLE_MUI_GRID)
    const {language} = useUserData()

    const {
        rows,
        rowModesModel,
        handleRows,
        handleRowModesModel,
        handleEditRowStart,
        handleEditRowStop,
        handleEditRow,
        handleCancelEditRow,
        handleAddNewRow,
        handleSaveRow,
        handleDeleteRow,
        handleDeleteConfirmation,
        handleUpdateRow,
        handleUpdateRowError,
        deleteDialogOpen,
        handleDeleteDialogOpen,
    } = useMUIDataGridCrudOperations({
        initialRows: data,
        newRowModel,
        store,
        update,
        remove
    })

    const gridColumns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: t('Actions'),
            width: 100,
            cellClassName: 'actions',
            getActions: (params) => {
                const id = params.id
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridRowEditToolBar
                            id={id}
                            handleSaveRow={handleSaveRow}
                            handleCancelEditRow={handleCancelEditRow}
                        />
                    ]
                }
                return [
                    <GridRowToolBar
                        id={id}
                        hideEditButton={hideEditButton}
                        handleEditRow={handleEditRow}
                        handleDeleteRow={handleDeleteRow}
                    />
                ]
            },
        },
        ...columns,
    ]

    // noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const disableAddingNewRow = useMemo(() => (Boolean(rows.find(f => f.isNew === true)) || (Object.values(rowModesModel).length > 0 && Boolean(Object.values(rowModesModel).find(f => f.mode === GridRowModes.Edit)))), [data, rows, rowModesModel])

    return (
        <Fragment>
            <RowConfirmationDialog
                deleteDialogOpen={deleteDialogOpen}
                handleDeleteDialogOpen={handleDeleteDialogOpen}
                handleDeleteConfirmation={handleDeleteConfirmation}
            />
            <DataGridPremium
                rows={rows}
                columns={gridColumns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModel}
                onRowEditStart={handleEditRowStart}
                onRowEditStop={handleEditRowStop}
                processRowUpdate={handleUpdateRow}
                onProcessRowUpdateError={handleUpdateRowError}
                slots={{
                    toolbar: EditGridToolBar,
                    pagination: CustomPagination,
                }}
                slotProps={{
                    toolbar: {handleAddNewRow, disableAddingNewRow},
                }}
                localeText={muiGridLocales(language)}
                loading={loading}
                initialState={{...initialState, density: 'compact'}}
                {...dataGridProOtherProps}
            />
        </Fragment>
    );
}

export default EditableMUIDataGrid