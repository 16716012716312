import axios from 'axios'
import { useFormik } from 'formik'
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { getRouteUrl } from '../../../helpers/routing/getRouteUrl'
import useUserData from "../../../hooks/user/useUserData";
import { USERS } from "../locales/namespaces";
import { ROUTE_PAGE_USERS_DETAILS } from '../routes/routes'

const useUserForm = ({ id, user, staticData }) => {

    const history = useHistory()
    const { t } = useTranslation(USERS)
    const [showPassword, setShowPassword] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const {token} = useUserData()
    const onSubmit = (values) => {
        setLoadingSubmit(true)
        axios({
            method: 'post',
            data: {
                UserId: id,
                isActive: values.isActive,
                // isInternal: values.isInternal,
                // isActor: values.isActor,
                // isAppUser: values.isAppUser,
                // hasMFA: values.hasMFA,
                username: values.username,
                LoginName: values.LoginName,
                password: values.password,
                passwordConfirmation: values.password,
                email: values.email,
                // oldEmailPassword: values.emailPassword,
                // emailPassword: values.emailPassword,
                // emailPasswordConfirmation: values.emailPassword,
                // emailServerId: values.emailServerId === 0 ? '' : values.emailServerId,
                // CultureId: values.CultureId,
                // CurrencyId: values.CurrencyId,
                LangId: values.LangId,
                // CountryId: values.CountryId,
                // InternalCostRateId: values.InternalCostRateId === 0 ? '' :values.InternalCostRateId,
                // CustCostRateId: values.CustCostRateId === 0 ? '' : values.CustCostRateId,
                // AbsenceDtFrom: values.AbsenceDtFrom,
                // AbsenceDtTo: values.AbsenceDtTo,
                // AbsenceMessage: values.AbsenceMessage,
                // UserImage: values.UserImage,
                // skills: values.userSkills,
                // portals: values.userPortals
            },
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            url: !id ? `${API}/en/user/store` : `${API}/en/user/update`
        }).then(response => {
            setLoadingSubmit(false)
            enqueueSnackbar(t(`User ${!id ? 'created' : 'updated'} successfully!`), {
                variant: 'success',
                autoHideDuration: 1000,
            });
            history.push(getRouteUrl(ROUTE_PAGE_USERS_DETAILS, { id: !id ? response.data.userid : id }))

        }).catch((error) => {
            setLoadingSubmit(false)
            enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
                variant: 'warning',
                autoHideDuration: 1000,
            }))
        })
    }

    const validationSchema = yup.object().shape({
        LoginName: yup.string().required(t('yup:Required')),
        username: yup.string().required(t('yup:Required')),
        password: !id ? yup.string()
            .required(t('yup:Required'))
            .matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{6,20}$)/,
                t('yup:Password must be between 6-20 characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')) : yup.string()
            .nullable()
            .matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{6,20}$)/,
                t('yup:Password must be between 6-20 characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
        passwordConfirmation: yup.string().when('password', {
            is: (password) => Boolean(password),
            then: (schema) => schema.required(t('yup:Required')).oneOf([yup.ref('password'), null], t('yup:Passwords must match')),
            otherwise: (schema) => schema.notRequired()
        }),
        // CultureId: yup.string().required(t('yup:Required')),
        // CurrencyId: yup.string().required(t('yup:Required')),
        LangId: yup.string().required(t('yup:Required')),
        // CountryId: yup.string().required(t('yup:Required')),
        email: yup.string().email(t('yup:Enter a valid email')),
        // emailPassword: !id ? yup.string()
        //     .matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{6,20}$)/,
        //         t('yup:Password must be between 6-20 characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')) : yup.string()
        //     .nullable()
        //     .matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{6,20}$)/,
        //         t('yup:Password must be between 6-20 characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
        // emailPasswordConfirmation: yup.string().when('emailPassword', {
        //     is: (emailPassword) => Boolean(emailPassword),
        //     then: (schema) => schema.required(t('yup:Required')).oneOf([yup.ref('emailPassword'), null], t('yup:Passwords must match')),
        //     otherwise: (schema) => schema.notRequired()
        // })
    })

    const formik = useFormik({
        initialValues: {
            isActive: id ? user?.isActive : true,
            // isInternal: id ? user?.isInternal : true,
            // isActor: id ? user?.isActor : false,
            // isAppUser: id ? user?.isAppUser : true,
            // hasMFA: id ? user?.hasMFA : true,
            username: user?.username || '',
            LoginName: user?.LoginName || '',
            password: '',
            passwordConfirmation: '',
            UserImage: user?.UserImage || '',
            email: user?.email || '',
            // emailPassword: '',
            // emailPasswordConfirmation: '',
            // emailServerId: user?.emailServerId || 0,
            // CultureId: user?.CultureId ? user?.CultureId : staticData.cultures.find(f => f.isDefault === '1') !== undefined && staticData.cultures.find(f => f.isDefault === '1').id,
            // CurrencyId: user?.CurrencyId ? user?.CurrencyId : staticData.currencies.find(f => f.isDefault === '1') !== undefined && staticData.currencies.find(f => f.isDefault === '1').id,
            LangId: user?.LangId ? user?.LangId : staticData.languages.find(f => f.isDefault === '1') !== undefined && staticData.languages.find(f => f.isDefault === '1')?.id,
            // CountryId: user?.CountryId ? user?.CountryId : staticData.countries.find(f => f.isDefault === '1') !== undefined && staticData.countries.find(f => f.isDefault === '1').id,
            // InternalCostRateId: user?.InternalCostRateId || 0,
            // CustCostRateId: user?.CustCostRateId || 0,
            // userSkills: user?.userSkills ? user.userSkills : [],
            // userPortals: user?.userPortals ? user.userPortals : [],
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return {
        formik,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
        loadingSubmit
    }

}
export default useUserForm