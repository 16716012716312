import type {UnmappedUser, User} from "./types";

export const mapUser: User = (datum: UnmappedUser) => {
	if (!datum) return
	return {
		id: datum.UserId,
		loginName: {
			el: datum.LoginName,
			en: datum.LoginName,
		},
		username: datum.username
	}
}

