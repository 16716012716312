import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/configContext";
import store, { persistor } from './store/store'
import { PersistGate } from "redux-persist/integration/react";

import { LicenseInfo } from '@mui/x-license';
import { MUI_LICENSE_KEY } from "./helpers/constants";

import App from './App';
import './index.css';

import './locales/i18n'

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

createRoot(document.getElementById('root')).render(
	// <StrictMode>
	<ConfigProvider>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App/>
			</PersistGate>
		</Provider>
	</ConfigProvider>
	// </StrictMode>,
)