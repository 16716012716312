import type { User } from "./types";

export const parseUserMessage: User = (datum) => {
	if (!datum) return;
	return {
		text: datum.get('text'),
		variant: datum.get('variant')
	}
}

export const parseUser: User = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		loginName: datum.getIn(['loginName', language]),
		username: datum.get('username')
	}
}

