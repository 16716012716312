import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ListWithSelectionHighlight
	from "../../../components/mui/app/listWithSelectionHighlight/listWithSelectionHighlight";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import ContentLoader from "../../../components/mui/general/contentLoader/contentLoader";
import Modal from "../../../components/mui/general/modal/modal";
import SkeletonLoaderWrapper from "../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { CustomPagination } from "../../../components/mui/grids/dataGridCustom/customPagination";
import EditableMUIDataGrid from "../../../components/mui/grids/editableMUIDataGrid/editableMUIDataGrid";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { access_roles, access_user } from "../../../helpers/rights";
import { useMuiGridServerSide } from "../../../hooks/muiGrid/useMuiGridServerSide";
import useUserData from "../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { SECURITYROLES } from "../locales/namespaces";
import RolesAndUsersOfRight from "./RolesAndUsersOfRight";

const RolesDetails = (props) => {
	const { t } = useTranslation(SECURITYROLES)
	const [modules, setModules] = useState([]);
	const [selectedModule, setSelectedModule] = useState(0);
	const [roleDescription, setRoleDescription] = useState();
	const { id } = useParams()
	const [rightId, setRightId] = useState(null);
	const [rightDescription, setRightDescription] = useState(null);
	const [showRolesUsers, setShowRolesUsers] = useState(false);
	const [loading, setLoading] = useState(true)
	const [loadingDescription, setLoadingDescription] = useState(true)
	const { token } = useUserData()
	
	useEffect(() => {
		//modules
		setLoading(true)
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/parameters/rights/create/getFilteredModules`
		}).then(response => {
			setLoading(false)
			setModules(response.data)
			setSelectedModule(response.data.length > 0 ? response.data[0].id : null)
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [t]);
	
	useEffect(() => {
		//roleDescription
		setLoadingDescription(true)
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/parameters/security/roles/getRoleDescription/` + id
		}).then(response => {
			setLoadingDescription(false)
			setRoleDescription(response.data)
		}).catch((error) => {
			setLoadingDescription(false)
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [id, t]);
	
	//datagrid
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid,
	} = useMuiGridServerSide({
		url: 'parameters/security/roles/connectedrights/' + selectedModule + '/' + id,
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	const columns = useMemo(() => ([
			
			{
				field: 'RightDescription',
				headerName:
					t('Right'),
				width:
					150, maxWidth:
					300, flex:
					1,
				editable:
					false,
			}
			,
			{
				field: 'ViewAccess',
				headerName:
					t('View'),
				width:
					90, maxWidth:
					90, flex:
					1,
				type:
					'boolean',
				editable:
					true,
				valueGetter:
					(value, row, column, apiRef) => value === '1',
			}
			,
			{
				field: 'EditAccess',
				headerName:
					t('Edit'),
				width:
					90, maxWidth:
					90, flex:
					1,
				type:
					'boolean',
				editable:
					true,
				valueGetter:
					(value, row, column, apiRef) => value === '1'
			},
			{
				// headerName: t('Connect Rights'),
				headerName: '',
				field: 'id',
				minWidth: 90, flex: 1,
				renderCell: ({ row }) => (
					<Button
						variant={'outlined'}
						color={'secondary'}
						onClick={() => {
							console.log(row);
							setRightId(row.id);
							setRightDescription(row.RightDescription);
							setShowRolesUsers(true)
						}}
						sx={{padding:'0px'}}
					>
						<Box pl={0.2} pr={0.2}>
							{t('Roles and Users')}
						</Box>
					</Button>
				)
			}
		
		]),
		[t]
	)
	
	const update = (value, oldRow) => {
		console.log(value, oldRow)
		axios.post(`${API}/en/parameters/security/roles/connectedrights/update/` + selectedModule + '/' + id, { value }).then(response => {
			enqueueSnackbar(t(`Right updated successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			// setUpdatePortalRights(!updatePortalRights)
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update right!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
			// setUpdatePortalRights(!updatePortalRights)
			refreshGrid()
		})
	}
	
	const handleUsersAndRolesClose = () => {
		setShowRolesUsers(false);
		setRightId(null);
		setRightDescription(null);
	}
	return (
		<Box className="right_grid">
			<PageContentWrapper>
				<SkeletonLoaderWrapper loading={loadingDescription}>
					{roleDescription && (
						<PageTitle title={t('Add Rights in ') + roleDescription}/>)}
				</SkeletonLoaderWrapper>
				<Grid container spacing={2}>
					<Grid size={{ xs: 2, md: 2 }}>
						{modules &&
							<ListWithSelectionHighlight
								highLight={false}
								data={modules}
								selected={selectedModule}
								onListItemClick={(selection) => {
									console.log(selection)
									setSelectedModule(selection)
									refreshGrid()
								}}
							/>
						}
					</Grid>
					<Grid size={{ xs: 10, md: 10 }}>
						<EditableMUIDataGrid
							data={data}
							columns={columns}
							loading={isLoading}
							hideDeleteButton={true}
							update={update}
							dataGridProOtherProps={{
								pagination: true,
								paginationModel: { page, pageSize },
								paginationMode: "server",
								onPaginationModelChange: onPaginationModelChange,
								rowCount: Number(total),
								pageSizeOptions: [5, 10, 15, 20, 50, 100],
								sortingMode: "server",
								onSortModelChange: (sortModel) => onSortModelChange(sortModel),
								filterMode: "server",
								onFilterModelChange: onFilterModelChange,
								slots: {
									// toolbar: EditGridToolBar,
									pagination: CustomPagination,
								},
							}}
						/>
					</Grid>
				</Grid>
			</PageContentWrapper>
			<Modal
				show={showRolesUsers}
				onBackgroundClick={handleUsersAndRolesClose}
			>
				<RolesAndUsersOfRight
					rightId={rightId}
					rightDescription={rightDescription}
					onClose={handleUsersAndRolesClose}
				/>
			
			</Modal>
			<ContentLoader loading={loading && loadingDescription} useCircularLoader withBackDrop/>
		</Box>
	)
		;
};

// export default RolesDetails
export default withPermissionHOC(RolesDetails, [access_roles])