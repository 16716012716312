// @flow
import { Clear } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import React from 'react'

type Props = {
	isChecked: boolean,
	alt: string
};

const BoolImage = (props: Props) => {
	const { isChecked, alt } = props
	return (
		
		isChecked ?	<CheckIcon color={"primary"}/> :	<Clear color={"primary"}/>
		
		// <img
		//     className={'isBoolImage'}
		//     src={isChecked ? CORRECT_ICON : INCORRECT_ICON}
		//     alt={alt}
		// />
	)
}

export default BoolImage