// @flow
import { Save } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from "@mui/icons-material/Delete";
import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemText, TextField,
	Typography
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { textFieldDefaults } from "../../../../helpers/theme/defaultProps";
import CustomAutocomplete from "../../../mui/form/autocomplete/autocomplete";

type Props = {
	type: 'input' | 'select',
	identifier?: string,
	columnNameToDisplay: string,
	disabled?: boolean,
	isSimpleList?: boolean,
	handleSearch?: Function,
	handleDelete: Function,
	handleAdd?: Function,
	handleTableList?: Function,
}

const TableList = (props: Props) => {
	const { t } = useTranslation('uiComponents')
	const theme = useTheme()
	
	const {
		identifier,
		columnNameToDisplay,
		handleSearch,
		handleTableList,
		handleDelete,
		handleAdd,
		
		type,
		stateValue,
		selectIdentifier,
		selectData,
		selectName,
		isSimpleList,
		disabled
	} = props
	
	const [data, setData] = useState([])
	const [query, setQuery] = useState('')
	const [selection, setSelection] = useState({ [`${columnNameToDisplay}`]: '' })
	const [inputVisible, setInputVisible] = useState(false)
	
	useEffect(() => {
		setData(props.data)
	}, [props.data])
	
	useEffect(() => {
		const tblInput = document.getElementById('tbl-input-el')
		if (inputVisible && query.length === 1) {
			if (tblInput) {
				tblInput.addEventListener('keyup', evt => {
					if (evt.keyCode === 13) {
						evt.preventDefault()
						document.getElementById('tbl-ok-button').click()
					}
				})
			}
		}
	})
	
	const addToList = (evt, input) => {
		const currentList = [...data]
		let newItem = {}
		if (type === 'input' && input.trim() !== '') {
			newItem[`${columnNameToDisplay}`] = `${input}`
			currentList.push(newItem)
		}
		if (type === 'select' && input.hasOwnProperty(`${identifier}`)) {
			newItem = input
			const findItm = currentList.find(item => item[`${identifier}`] === newItem[`${identifier}`])
			if (!findItm) {
				currentList.push(newItem)
			} else {
				newItem = null
			}
		}
		setData(currentList)
		if (handleAdd) {
			handleAdd(evt, newItem, currentList, stateValue)
		} else {
			handleTableList(evt, type, currentList, stateValue)
		}
		setQuery('')
		setSelection('')
	}
	
	const deleteItem = (evt, itm) => {
		const updateList = data.filter(item => item[`${columnNameToDisplay}`] !== itm[`${columnNameToDisplay}`])
		setData(updateList)
		if (handleDelete) {
			handleDelete(evt, itm, updateList, stateValue)
		} else {
			handleTableList(evt, type, updateList, stateValue)
		}
	}
	
	const cancelAdd = () => {
		setQuery('')
		setInputVisible(false)
	}
	
	const handleInput = (evt) => {
		setQuery(evt.target.value)
	}
	
	const handleSelect = (evt, updatedData, stateVarName, item) => {
		if (stateVarName === 'selectOption') {
			addToList(evt, item.option)
		} else {
			deleteItem(evt, item.option)
		}
	}
	
	const setVisible = () => {
		setInputVisible(true)
	}
	
	const inputType = (type) => {
		let input
		switch (type) {
			case ('input'):
				input =
					<TextField
						{...textFieldDefaults}
						id="tbl-input-el"
						onChange={handleInput}
						value={query}
						maxLength="100"
						disabled={disabled}
					/>
				break
			case ('select'):
				input =
					<CustomAutocomplete
						identifier={selectIdentifier || "id"}
						value={data}
						onChange={handleSelect}
						options={selectData}
						labelKey={columnNameToDisplay}
						onInputChange={(event) => handleSearch(event, selectName)}
						otherprops={{
							multiple: true,
							disableClearable: true,
							disableCloseOnSelect: true
						}}
						disabled={disabled}
					/>
				break
			default:
				input = null
		}
		return input
	}
	
	const input = (type === 'input') ? query : selection
	
	return (
		<Box sx={{ border: '1px solid lightgray', width: '100%' }}>
			<Box
				sx={{
					padding: '8px',
					backgroundColor: 'rgba(240, 248, 255, 0.8)',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				{props.title ? <Typography sx={{ fontWeight: 500, color: '#333' }}>
					{props.title}
				</Typography> : null}
				{!isSimpleList && !inputVisible && (
					<Button
						disableElevation
						//variant="contained"
						size="small"
						onClick={setVisible}
						disabled={disabled}
						startIcon={<AddCircleOutlineIcon/>}
					>
						{t('Add')}
					</Button>
				)}
			</Box>
			<Box style={{ padding: '1rem' }}>
				{inputVisible && (
					<Box
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{inputType(type)}
						{type !== 'select' && (
							<IconButton
								id="tbl-ok-button"
								size={'small'}
								onClick={(evt) => addToList(evt, input)}
							>
								<CheckIcon/>
							</IconButton>
						)}
						<IconButton
							size={'small'}
							onClick={cancelAdd}
						>
							<Save/>
						</IconButton>
					</Box>
				)}
				{!inputVisible && (
					<List>
						{data.map(itm => (
							<ListItem
								key={itm[identifier]}
								sx={{
									p: 0,
									mb: 1,
									borderBottom: '1px solid',
									borderColor: theme.palette.pageTitle.dividerColor
								}}
								secondaryAction={(
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={(evt) => deleteItem(evt, itm)}
									>
										<DeleteIcon color={'error'}/>
									</IconButton>
								)}
							>
								<ListItemText primary={itm[`${columnNameToDisplay}`]}/>
							</ListItem>
						))}
					</List>
				)}
			</Box>
		</Box>
	)
}

export default TableList