import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Tabs from "@mui/material/Tabs";
import axios from 'axios'
import dayjs from "dayjs";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './DetailsTables.css'
import TableList from '../../../../components/custom/form/tableList/TableList.js';
import BoolImage from "../../../../components/custom/general/boolImage/boolImage";
import FormBoxTitle from "../../../../components/mui/app/formBoxTitle/formBoxTitle";
import PageContentWrapper from "../../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../../components/mui/app/pageTitle/pageTitle";
import QuickViewInfoWrap from "../../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import { API } from "../../../../helpers/constants";
import { handleAxiosErrors } from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import { USERS } from "../../locales/namespaces";
import Absence from '../absence/absence'
import PasswordRestorationGrid from '../PasswordRestorationGrid/PasswordRestorationGrid'
import PortalRolesGrid from "../PortalRoles/portalRolesGrid";
import PortalsGrid from '../PortalsGrid/PortalsGrid'
import RanksGrid from "../RanksGrid/RanksGrid";
import Replacers from '../replacers/replacers'
import RightsGrid from '../RightsGrid/RightsGrid'
import TeamsGrid from '../TeamsGrid/TeamsGrid'

const DetailsTables = (props) => {
	
	const { t } = useTranslation(USERS)
	const { id } = useParams()
	const {token} = useUserData()
	const {
		user,
		aapCurrencies,
		aapLanguages,
		aapCountries,
		userSkills,
		topSkills,
		userRoles,
		topRoles,
		userPortals,
		portals,
		handleSearch
	} = props
	
	const removeFromList = (evt, item, currentList, stateValue) => {
		let url
		let itemName
		switch (stateValue) {
			case 'skills':
				url = `${API}/en/user/skills/remove`
				itemName = 'Skill'
				break
			case 'roles':
				url = `${API}/en/user/roles/remove`
				itemName = 'Role'
				break
			case 'portals':
				url = `${API}/en/user/portals/remove`
				itemName = 'Portal'
				break
			default:
				break
		}
		axios({
			method: 'post',
			data: {
				'item': item,
				'UserId': user.id
			},
			url: url,
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then(response => {
			enqueueSnackbar(t(`${itemName} deleted successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const addToList = (evt, item, currentList, stateValue) => {
		let url
		let property
		let itemName
		switch (stateValue) {
			case 'skills':
				url = `${API}en/user/skills/add`
				property = 'Description'
				itemName = 'Skill'
				break
			case 'roles':
				url = `${API}/en/user/roles/add`
				property = 'Description'
				itemName = 'Role'
				break
			case 'portals':
				url = `${API}/en/user/portals/add`
				property = 'Description'
				itemName = 'Portal'
				break
			default:
				break
		}
		if (item != null && item.hasOwnProperty(`${property}`)) {
			axios({
				method: 'post',
				data: {
					'item': item,
					'UserId': user.id
				},
				url: url,
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			}).then(response => {
				enqueueSnackbar(t(`${itemName} added successfully!`), {
					variant: 'success',
					autoHideDuration: 1000,
				});
				
			}).catch(error => {
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
			})
		}
	}
	//tabs
	const [tabValue, setTabValue] = useState('1');
	const [tabletsValue, setTabletsValue] = useState('1');
	
	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};
	const handleTabletsChange = (event, newValue) => {
		setTabletsValue(newValue);
	};
	return (
		<Fragment>
			<Box className="userDetails">
				<PageContentWrapper>
					<PageTitle
						title={t('User Details')}
						withDivider
					/>
					<Grid
						container
						spacing={3}
					>
						
						<Grid size={{ xs: 12, sm: 12, md: 7 }}>
							<FormBoxTitle title={t('Basics')}/>
							<Box>
								<Grid
									container
									spacing={1}
								>
									
									{/*<Grid size={{ xs: 12, sm: 12, md: 2 }}>*/}
									{/*	<img id="userImage"*/}
									{/*		 src={user.UserImage ? `${API}/uploads/users/${user.UserImage}` : `${API}/images/Add_user_icon.png`}*/}
									{/*		 width="70" height="70" alt={'user picture'}/>*/}
									{/*</Grid>*/}
									
									<Grid size={{ xs: 12, sm: 12, md: 12 }}>
										<QuickViewInfoWrap label={t('Active')}>
											<BoolImage
												isChecked={Boolean(user.isActive)}
												alt={t('Active')}
											/>
										</QuickViewInfoWrap>
									</Grid>
									{/*<Grid*/}
									{/*	xs={12}*/}
									{/*	sm={3}*/}
									{/*	md={2}*/}
									{/*>*/}
									{/*	<QuickViewInfoWrap label={t('Internal')}>*/}
									{/*		<BoolImage*/}
									{/*			isChecked={Boolean(user.isInternal)}*/}
									{/*			alt={t('Internal')}*/}
									{/*		/>*/}
									{/*	</QuickViewInfoWrap>*/}
									{/*</Grid>*/}
									{/*<Grid size={{ xs: 12, sm: 3, md: 3 }}>*/}
									{/*	<QuickViewInfoWrap label={t('isAppUser')}>*/}
									{/*		<BoolImage*/}
									{/*			isChecked={Boolean(user.isAppUser)}*/}
									{/*			alt={t('AppUser')}*/}
									{/*		/>*/}
									{/*	</QuickViewInfoWrap>*/}
									{/*</Grid>*/}
									
									{/*<Grid size={{ xs: 12, sm: 3, md: 3 }}>*/}
									{/*	<QuickViewInfoWrap label={t('hasMFA')}>*/}
									{/*		<BoolImage*/}
									{/*			isChecked={Boolean(user.hasMFA)}*/}
									{/*			alt={t('hasMFA')}*/}
									{/*		/>*/}
									{/*	</QuickViewInfoWrap>*/}
									{/*</Grid>*/}
								</Grid>
								<QuickViewInfoWrap label={t('Name')} highLightValue>{user.LoginName}</QuickViewInfoWrap>
								<QuickViewInfoWrap label={t('Username')}
												   highLightValue>{user.LoginName}</QuickViewInfoWrap>
								<QuickViewInfoWrap label={t('E-mail')} highLightValue>{user.email}</QuickViewInfoWrap>
								<QuickViewInfoWrap label={t('Language')}
												   highLightValue>{aapLanguages.Description}</QuickViewInfoWrap>
								{/*<QuickViewInfoWrap label={t('Internal Cost')}*/}
								{/*				   highLightValue>{user.InternalCost}</QuickViewInfoWrap>*/}
								{/*<QuickViewInfoWrap label={t('Rate')} highLightValue>{user.CostRate}</QuickViewInfoWrap>*/}
								<QuickViewInfoWrap label={t('Last Login')}
												   highLightValue>{user.last_login ? dayjs(user.last_login).format("DD/MM/YYYY") : ''}</QuickViewInfoWrap>
							</Box>
						</Grid>
						
						<Grid size={{ xs: 12, sm: 12, md: 5 }}>
						{/*	<TabContext value={tabValue}>*/}
						{/*		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
						{/*			<TabList onChange={handleChange} aria-label="tabs">*/}
						{/*				<Tab label={t('Absence')} value="1"/>*/}
						{/*				<Tab label={t('Replacers')} value="2"/>*/}
						{/*			</TabList>*/}
						{/*		</Box>*/}
						{/*		<TabPanel value="1">*/}
						{/*			<Absence id={id}/>*/}
						{/*		</TabPanel>*/}
						{/*		<TabPanel value="2">*/}
						{/*			<Replacers id={id}/>*/}
						{/*		</TabPanel>*/}
						{/*	</TabContext>*/}
						{/*</Grid>*/}
						
						{/*<Grid size={{ xs: 6, sm: 6, md: 8 }}>*/}
							<FormBoxTitle title={t('Tabs')}/>
							
							<Grid size={{ xs: 12, sm: 12, md: 12 }}>
								<TabContext value={tabletsValue}>
									<Tabs value={tabletsValue} onChange={handleTabletsChange} variant="scrollable"
										  allowScrollButtonsMobile={true}>
										<Tab label={t('Roles')} value="1"/>
										{/*<Tab label={t('Rights')} value="2"/>*/}
										{/*<Tab label={t('Portals')} value="3"/>*/}
										{/*<Tab label={t('Portal Roles')} value="4"/>*/}
										{/*<Tab label={t('Portal Rights')} value="5"/>*/}
										<Tab label={t('PasswordRestoration')} value="6"/>
										{/*<Tab label={t('Ranks')} value="7"/>*/}
									</Tabs>
									<TabPanel value="1">
										<Box className="form-box_title ">{t('Roles')}</Box>
										<TableList
											type="select"
											title={t('Roles')}
											stateValue="roles"
											columnNameToDisplay="Description"
											identifier="id"
											data={userRoles}
											handleSearch={handleSearch}
											selectData={topRoles}
											handleAdd={addToList}
											handleDelete={removeFromList}
											selectName="roles"
										/>
									</TabPanel>
									{/*<TabPanel value="2">*/}
									{/*	<RightsGrid*/}
									{/*		user={user}*/}
									{/*		id={id}*/}
									{/*	/>*/}
									{/*</TabPanel>*/}
									{/*<TabPanel value="3">*/}
									{/*	<TableList*/}
									{/*		type="select"*/}
									{/*		title={t('Portals')}*/}
									{/*		stateValue="portals"*/}
									{/*		columnNameToDisplay="Description"*/}
									{/*		identifier="id"*/}
									{/*		data={userPortals}*/}
									{/*		selectData={portals}*/}
									{/*		handleAdd={addToList}*/}
									{/*		handleDelete={removeFromList}*/}
									{/*		selectName="portals"*/}
									{/*	/>*/}
									{/*</TabPanel>*/}
									{/*<TabPanel value="4">*/}
									{/*	<PortalRolesGrid*/}
									{/*		user={user}*/}
									{/*		id={id}*/}
									{/*	/>*/}
									{/*</TabPanel>*/}
									{/*<TabPanel value="5">*/}
									{/*	<PortalsGrid*/}
									{/*		id={id}*/}
									{/*		user={user}*/}
									{/*	/>*/}
									{/*</TabPanel>*/}
									<TabPanel value="6">
										<Box style={{ maxWidth: '600px' }}>
											<PasswordRestorationGrid
												id={id}
												user={user}
											/>
										</Box>
									</TabPanel>
									{/*<TabPanel value="7">*/}
									{/*	<RanksGrid UserId={id}/>*/}
									{/*</TabPanel>*/}
								</TabContext>
							</Grid>
						</Grid>
						
						{/*<Grid size={{ xs: 6, sm: 6, md: 4 }}>*/}
						{/*	<Grid>*/}
						{/*		<FormBoxTitle title={t('Teams')}/>*/}
						{/*		<Grid>*/}
						{/*			<TeamsGrid*/}
						{/*				id={id}*/}
						{/*				user={user}*/}
						{/*			/>*/}
						{/*		</Grid>*/}
						{/*	</Grid>*/}
						{/*	<Grid>*/}
						{/*		<FormBoxTitle title={t('Skills')}/>*/}
						{/*		<Grid>*/}
						{/*			<TableList*/}
						{/*				type="select"*/}
						{/*				title={t('Skills')}*/}
						{/*				stateValue="skills"*/}
						{/*				columnNameToDisplay="Description"*/}
						{/*				identifier="id"*/}
						{/*				data={userSkills}*/}
						{/*				handleSearch={handleSearch}*/}
						{/*				selectData={topSkills}*/}
						{/*				handleAdd={addToList}*/}
						{/*				handleDelete={removeFromList}*/}
						{/*				selectName="skills"*/}
						{/*			/>*/}
						{/*		*/}
						{/*		</Grid>*/}
						{/*	</Grid>*/}
						{/*	<Grid sx={{ paddingTop: '10px' }}>*/}
						{/*		<FormBoxTitle title={t('Localization')}/>*/}
						{/*		<Grid>*/}
						{/*			<QuickViewInfoWrap label={t('Country')}*/}
						{/*							   highLightValue>{aapCountries.Description}</QuickViewInfoWrap>*/}
						{/*			<QuickViewInfoWrap label={t('Language')}*/}
						{/*							   highLightValue>{aapLanguages.Description}</QuickViewInfoWrap>*/}
						{/*			<QuickViewInfoWrap label={t('Currency')}*/}
						{/*							   highLightValue>{aapCurrencies.Description}</QuickViewInfoWrap>*/}
						{/*			<QuickViewInfoWrap label={t('Origin')}*/}
						{/*							   highLightValue>{user.OriginDescription}</QuickViewInfoWrap>*/}
						{/*		</Grid>*/}
						{/*	</Grid>*/}
						{/*</Grid>*/}
					
					
					</Grid>
				</PageContentWrapper>
			</Box>
		</Fragment>
	)
}

export default DetailsTables