
// ==============================|| OVERRIDES - DIALOG ||============================== //

export default function Dialog (theme) {
	return {
		MuiDialog: {
			styleOverrides: {
				root: {
					margin: 'auto'
					// '& .MuiBackdrop-root': {
					//     backgroundColor: alpha('#000', 0.7)
					// }
				}
			}
		}
	}
}
